import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { StoreContext } from './store/StoreProvider';
import { InfoData } from "./InfoData";


export const Recovery = (props) => {

const {store} = useContext(StoreContext);
const {ruta, img, testMail} = store;
const [entrada, setEntrada] = useState(""); 
const [codigo, setCodigo] = useState(""); 
const [paso, setPaso] = useState(""); 
const [email, setEmail] = useState(""); 
const [celular, setCelular] = useState("");  
const [usuario, setUsuario] = useState("");   
const [password, setPassword] = useState("");
const [password2, setPassword2] = useState("");
const [pwdValido, setPwdValido] = useState(""); 
const [pwdtipo, setPwdtipo] = useState("password");  
const [pwdComparacion, setPwdComparacion] = useState("");  
const [estado, setEstado] = useState("");  
//const fotoF = rutaWeb+img.fotoF;
const fotoM = ruta+img.fotoM;


useEffect(() => {
document.getElementById('inemail').type="email";
document.getElementById('inpwd').type="password";
document.getElementById('inpwd2').type="password";
}, []);


const validarCelular = (valor) => {
	let validez = '';
	if(isNaN(valor)){validez = 'invalido';}else{
  if (valor.length >8 ||valor===''){
   validez = '';
  } else {
   validez = 'invalido';
  }
  }
  return validez;
}

const entradaChange = (e) => {
	setEntrada(e.target.value);
    }

	
const onCodigoChange = (e) => {
	setCodigo(e.target.value);
    }
	
	
const validarEmail = (valor) => {
	let validez = '';
  if (testMail.test(valor)||valor===''){
    validez = '';
  } else {
	validez = 'invalido';
  }
  return validez;
}
	
const disponibilidadUsuario = async (user) => {	
	let pasar ='';
	let resource = await InfoData('disponibilidadUsr', {usuario:user,celular:'',email:'', accion:'ask', codigo:codigo});	
	if(resource.data.email!==''){
	setEstado('enDB'); setEmail(resource.data.email); if(codigo!==''){setPaso(resource.data.Usu_ID); pasar = resource.data.validar;}
	}else{
	setEstado(''); pasar = 'no';
	}
	return pasar;
	}
	
const disponibilidadEmail = async (user) => {
	let pasar ='';	
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:'',email:user, accion:'ask', codigo:codigo});		
	if(resource.data.email!==''){
	setEstado('enDB'); setEmail(resource.data.email); if(codigo!==''){setPaso(resource.data.Usu_ID); pasar = resource.data.validar;}
	}else{
	setEstado(''); pasar = 'no';
	}
	return pasar;
	}
	
const disponibilidadCelular = async (user) => {	
	let pasar ='';
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:user,email:'', accion:'ask', codigo:codigo});		
	if(resource.data.email!==''){
	setEstado('enDB'); setEmail(resource.data.email); if(codigo!==''){setPaso(resource.data.Usu_ID); pasar = resource.data.validar;}
	}else{
	setEstado(''); pasar = 'no';
	}
	return pasar;
	}
	
	
	const entradaEstado = (e) => {
	reconocer(entrada); 
	}
	
	
	const codigoEstado = (e) => {
	document.getElementsByClassName("p-error p-d-block")[1].classList.remove('oculto');
	}
	
	const pwdEstado = (e) => {
	let validez= validarPwd(password); 
	if(password===''||validez==='invalido'){
		document.getElementById('inpwd').style.background='#fff2ef';
		} else {document.getElementById('inpwd').style.background='#ffffff'; }
	}	

const validarPwd = (valor) => {
	let validez = '';
	if (valor.length >=5 ||valor===''){
	setPwdValido(""); validez = '';
	} else {
	setPwdValido('invalido'); validez = 'invalido';
	}
	return validez;
}
	
const verPwd = (e) => {
	if(pwdtipo ==='password') {document.getElementById('inpwd').type="text"; document.getElementById('inpwd2').type="text"; setPwdtipo('text');} 
	else {document.getElementById('inpwd').type="password"; document.getElementById('inpwd2').type="password"; setPwdtipo('password'); }
    }

const pwdClass = classNames({
        'pi pi-eye': pwdtipo === 'password',
        'pi pi-eye-slash': pwdtipo === 'text',
     });	


	
const reconocer = (e) => {
	let mail=validarEmail(e); 
	let cell=validarCelular(e); 
	let tipo='';
	if (mail===''){setEmail(e);setUsuario('');setCelular(''); tipo=1;}
	else {
	if (cell===''){setCelular(e);setUsuario('');setEmail(''); tipo=2;}
	else {
	setUsuario(e);setCelular('');setEmail(''); tipo=3;
		}
	  }
	return tipo;
    }

	
	
const codificar = async (e) => {	
	document.getElementsByClassName("form-container")[1].classList.add('oculto');
	document.getElementsByClassName("form-container")[2].classList.remove('oculto');
}


const nuevoCod = async (e) => {
	let tipo = reconocer(entrada);
	switch(tipo){
	case 1:
	disponibilidadEmail(entrada);
	break;
	case 2:
	disponibilidadCelular(entrada);
	break;
	case 3:
	disponibilidadUsuario(entrada);
	break;	
    default: 
    console.log("No hay coincidencias");	
	}
}	
	
const clavear = async (e) => {	
	let tipo = reconocer(entrada);
	let door = '';
	switch(tipo){
	case 1:
	door = await disponibilidadEmail(entrada);
	break;
	case 2:
	door = await disponibilidadCelular(entrada);
	break;
	case 3:
	door = await disponibilidadUsuario(entrada);
	break;	
    default: 
    console.log("No hay coincidencias");	
	}
	if(door === codigo && door !==''){
	document.getElementsByClassName("form-container")[2].classList.add('oculto');
	document.getElementsByClassName("form-container")[3].classList.remove('oculto');	
	}else{document.getElementById('incod').style.background='#fff2ef'; document.getElementsByClassName("p-error p-d-block")[1].classList.remove('oculto');}
}	 
	
	
const cambiarpwd = async (e) => {	
	let user = {Usu_ID:paso, Nombres:'', ApellidoPaterno:'', ApellidoMaterno:''};
	let resource = await InfoData('user', {user:user,caso:'pwd',pwd:password})
 	console.log(resource.data);	
}	
	
	
	
const registrar = async (e) => {	
	let tipo = reconocer(entrada);
	let pasar ='';
	switch(tipo){
	case 1:
	pasar = await disponibilidadEmail(entrada);
	break;
	case 2:
	pasar = await disponibilidadCelular(entrada);
	break;
	case 3:
	pasar = await disponibilidadUsuario(entrada);
	break;	
    default: 
    console.log("No hay coincidencias");	
	}
	if (pasar==='' & entrada!==''){
	document.getElementsByClassName("form-container")[0].classList.add('oculto');
	document.getElementsByClassName("form-container")[1].classList.remove('oculto');	
	} else {document.getElementsByClassName("signin-aviso")[0].classList.remove('oculto');}
}


  return (
    <div className="p-fluid dashboard">
    
      <div className="marco reg">
          
        <div className="reg-content-wrapper single reg-box">
          <div className="join-form-container form-container">
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{borderBotton:'1px solid #bbb', padding:'0.4em', textAlign:'center'}}>Recupera tu cuenta</h1>
              <p className="signin-link">Ingresa tu nombre de usuario, tu número celular o tu correo electrónico para encontrar tu cuenta</p>
              <fieldset> 
                <legend>Únete a Servicontacto</legend> 
                <ul className="input-fields container-box"  style={{listStyle:'none',paddingInlineStart:'0px'}} >							
                  <li className="cell-input">
                    <div className="cell-body"> <label className="float-label" htmlFor="inemail">Nombre de usuario, correo electrónico o teléfono celular</label>
                    <InputText id="inemail" value={entrada} onChange={entradaChange} aria-describedby="inemail-help"  onBlur={() => entradaEstado()}/><small id="inemail-help" className="p-error p-d-block">{email? 'Correo electrónico: '+email:usuario?'Nombre de usuario: '+usuario:celular?'Teléfono celular: '+celular:''}</small></div>							</li>
                </ul>
                <p className="signin-aviso oculto"> {entrada?entrada+' no esta registrado en nuestra base de datos':'No has ingresado ningúna palabra'}</p>
                <Link to= '/usuario/login'>
                <Button className="logboton join-btn" label="Cancelar" style={{width:'48%', background:'#2196f3'}}/>
                </Link>
                <Button className="logboton join-btn" label="Buscar" onClick={() => registrar()} style={{width:'48%',float:'right'}}/>
                </fieldset>
                <p className="signin-link"> Al crear la cuenta de Servicontacto, estoy de acuerdo con las <Link to= "/legal/user-agreement">Condiciones de uso</Link>, la <Link to="/legal/privacy-policy">Política de privacidad</Link>  y la <Link to= "/legal/cookies-policy">Política de cookies</Link> de Servicontacto.</p>
            </div>
          </div>
          <div className="join-form-container form-container oculto" style={{width:'40em'}}>
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{borderBotton:'1px solid #bbb', padding:'0.4em', textAlign:'center'}}>Recupera tu contraseña</h1>
              <p className="signin-link">Tendrás hasta 15 minutos de plazo antes que el código venza</p>
              <fieldset> 
                <legend>Únete a Servicontacto</legend> 
                <div style={{width:'50%', float:'left'}}>Te enviaremos un código para restablecer tu contraseña al correo que registraste<br/> <input type="radio" id="html" style={{marginTop:'0.9em'}}/> <p style={{display:'unset'}}>Enviar código por correo</p> <p style={{marginLeft:'2em'}}>{email}</p>  </div>
                <div style={{width:'50%', float:'right', textAlign:'center'}}><img width='80px' src={fotoM} alt="foto" style={{borderRadius:'4em', visibility:estado?'visible':'hidden'}}/><br/>{entrada}<p>{estado?'Es un ':'No es '}usuario de Servicontacto</p></div>
                <Link to= '/usuario/login'>
                <Button className="logboton join-btn" label="¿No eres tu?" style={{width:'48%', float:'left', clear:'both', background:'#2196f3'}}/>
                </Link>
                <Button className="logboton join-btn" label="Continuar" onClick={() => codificar()} style={{width:'48%',float:'right'}}/>
                </fieldset>
            </div>
          </div>			
          <div className="join-form-container form-container oculto" style={{width:'40em'}}>
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{borderBotton:'1px solid #bbb', padding:'0.4em', textAlign:'center'}}>Ingresa el código enviado</h1>
              <p className="signin-link">Comprueba si recibiste un correo electrónico con tu código de 6 dígitos</p>
              <p style={{color:'#00adef',cursor:'pointer'}} onClick={() => nuevoCod()} >Enviar código nuevamente</p>
              <fieldset> 
                <legend>Únete a Servicontacto</legend> 
                <div style={{width:'48%', float:'left'}}>
                  <div className="cell-body"> <label className="float-label" htmlFor="incod">Ingresa el código</label>
                  <InputText id="incod" value={codigo} onChange={onCodigoChange} onBlur={() => codigoEstado()}/><small id="incode-help" className="p-error p-d-block oculto">{codigo.length===6?'Codigo errado, solo tienes 3 intentos':'El código debe tener 6 dígitos'}</small></div>
                </div>
                <div style={{width:'48%', float:'right'}}>Enviamos el código a:<p>{email}</p></div>
                <Link to= '/usuario/login'>
                <Button className="logboton join-btn" label="¿No eres tu?" style={{width:'48%', float:'left', clear:'both', background:'#2196f3'}}/>
                </Link>
                <Button className="logboton join-btn" label="Continuar" onClick={() => clavear()} style={{width:'48%',float:'right'}}/>
                </fieldset>
            </div>
          </div>					
          <div className="join-form-container form-container oculto">
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{borderBotton:'1px solid #bbb', padding:'0.4em', textAlign:'center'}}>Ingresa una nueva contraseña</h1>
              <p className="signin-link">Tu contraseña debe contener al menos 5 caracteres</p>
              <fieldset> 
			  <form>
                <legend>Únete a Servicontacto</legend> 
                <ul className="input-fields container-box">	
                  <li className="cell-input">
                    <div className="cell-body"><label className="float-label" htmlFor="inpwd">Contraseña *</label>
                    <span className="p-input-icon-right"><i className={pwdClass} style={{right: '0.5em',cursor:'pointer'}} onClick={() => verPwd()}/>
                    <InputText id="inpwd" value={password} onChange={(e) => setPassword(e.target.value)} aria-describedby="inpwd-help" onBlur={() => pwdEstado()}/></span><small id="inpwd-help" className="p-error p-d-block">{ pwdValido ?  'La contraseña debe tener al menos 5 digitos':''}</small></div>
                  </li>
                  <li className="cell-input" style={{'display': password ? 'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="inpwd2">Confirmación de contraseña</label>
                    <InputText id="inpwd2" value={password2} onChange={(e) => {setPassword2(e.target.value); setPwdComparacion('');}} aria-describedby="inpwd2-help" className={password2 !=='' ? 'p-d-block':'p-invalid p-d-block'}/><small id="inpwd2-help" className="p-error p-d-block">{ pwdComparacion === 'falla' ?  'La contraseña no coincide con la confirmación.':''}</small></div>
                  </li>						
                </ul>
                <Link to= '/usuario/login'>
                <Button className="logboton join-btn" label="¿No eres tu?" style={{width:'48%', float:'left', clear:'both', background:'#2196f3'}}/>
                
                <Button className="logboton join-btn" label="Guardar" onClick={() => cambiarpwd()} style={{width:'48%',float:'right'}}/>
                </Link>
				</form>
                </fieldset>
            </div>
          </div>				
        </div>	
    
      </div>    
    </div>    
    );
	
  }
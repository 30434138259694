import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { Button } from "primereact/button";
import { StoreContext } from "./store/StoreProvider";
//import { types } from "./store/StoreReducer.js";
import { LogForm } from "./Login.js";


export const Agenda = (props) => {
  const {store} = useContext(StoreContext);
  const { user, api } = store;
  const [visible, setVisible] = useState(false);
  //const [modo, setModo] = useState("nuevo");
  const [eventoTitulo, setEventoTitulo] = useState("");
  const [fecha, setFecha] = useState(new Date(Date.now() - 86400000));
  const [fecha1, setFecha1] = useState(new Date(Date.now() - 86400000));
  const [checked, setChecked] = useState(false);
  const [hasEnd, setHasEnd] = useState(false);
  const [modoBoton, setModoBoton] = useState("");
  const [Color, setColor] = useState("#fecc00");
  const [textColor, setTextColor] = useState("#006aa7");
  const [id, setId] = useState(""); // id de evento
  const [agenda, setAgenda] = useState([]);
  const [ventana, setVentana] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventoDescripcion, setEventoDescripcion] = useState(""); 
  const turnos =[];
  const duracion = 30;  // Duración del turno en una cita en minutos
  const desde = parseInt(props.contacto?.inicio)<parseInt(props.contacto?.inicio1)?parseInt(props.contacto?.inicio):parseInt(props.contacto?.inicio1);
  const hasta = parseInt(props.contacto?.fin)>parseInt(props.contacto?.fin1)?parseInt(props.contacto?.fin):parseInt(props.contacto?.fin1);
  const cantidad = (hasta - desde)/duracion; 	// Cantidad de citas en un día de atención.
  for(let i=0; i<cantidad; i++)
  {if(parseInt(props.contacto?.entretiempo)===0||(parseInt(props.contacto?.inicio)+i*duracion)<parseInt(props.contacto?.refrigerio)||(parseInt(props.contacto?.inicio)+i*duracion)>=(parseInt(props.contacto?.refrigerio)+parseInt(props.contacto?.entretiempo)))
  turnos.push({hora:new Date(new Date(new Date().setMinutes(desde+i*duracion)).setHours((desde+i*duracion)/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'/*, hour12: true*/}).toUpperCase()})
  }
/*  const turnos = [
    { hora: "07:00" },
    { hora: "07:30" },
    { hora: "08:00" },
    { hora: "08:30" },
    { hora: "09:00" },
    { hora: "09:30" },
    { hora: "10:00" },
    { hora: "10:30" },
    { hora: "11:00" },
    { hora: "11:30" },
    { hora: "12:00" },
    { hora: "12:30" },
    { hora: "13:00" },
    { hora: "13:30" },
    { hora: "14:00" },
    { hora: "14:30" },
    { hora: "15:00" },
    { hora: "15:30" },
    { hora: "16:00" },
    { hora: "16:30" },
    { hora: "17:00" },
    { hora: "17:30" },
    { hora: "18:00" },
    { hora: "18:30" },
    { hora: "19:00" },
    { hora: "19:30" },
    { hora: "20:00" },
  ];*/
  const hoy = new Date().valueOf();
  var diaMes = new Date(((new Date(new Date().toString("MM/01/yyyy")).getUTCDay() >= "4" ? 7 : 0) - new Date(new Date().toString("MM/01/yyyy")).getUTCDay()) * 86400000 + new Date(new Date().toString("MM/01/yyyy")).valueOf());
  const [priDiaMes, setPriDiaMes] = useState(new Date());
  var counter = 0;


  useEffect(() => {
  console.log('cambio de evento');
   // if(!user?.Usu_ID){document.getElementsByClassName("p-dialog-content")[0].style.padding='0'}
    setPriDiaMes(hoy);
    getEventos("leer", "user.Usu_ID"); 
  }, [!!user.Usu_ID]);   //eslint-disable-line react-hooks/exhaustive-deps

  /*
useEffect(() => {
setEventoTitulo(user.Nombres); 
}, [user?.Usu_ID!=null]);
*/

  const DiaHeader = (dia) => {
    let Dia = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia);
    let mes = Dia.getMonth() + 1;
    return (
      <span>
        {Dia.toLocaleDateString("es-PE", { weekday: "short", day: "numeric" }) +
          "/" +
          mes}
      </span>
    );
  };

  const DiaEstilo = (dia) => {
    let diaSem = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia).getDay();
    var Estilo = {
      paddingLeft: "0.5em",
      borderRight: diaSem === 0 ? "solid" : "none",
      borderRightColor: diaSem === 0 ? "#ccc" : "none",
      backgroundColor: diaSem === 0 || diaSem === 6 ? "#fcffd3" : "#f4f4f4",
      color: diaSem === 0 ? "red" : "#000",
    };
    return Estilo;
  };

  const DiaEstiloC = (dia) => {
    let diaSem = new Date(priDiaMes.valueOf() + 3600000 * 24 * dia).getDay();
    var Estilo = {
      padding: "0",
      color: "#888",
      fontSize: "12px",
      textAlign: "center",
    }; //{textAlign:'center'};
    if (diaSem === 0) {
      Estilo = {
        padding: "0",
        color: "#888",
        fontSize: "12px",
        textAlign: "center",
        borderRight: "solid",
        borderRightColor: "#ccc",
      };
    }
    return Estilo;
  };

  const MostrarDialogo = (fechaCita, modo) => {
    let fechaInicio = new Date(fechaCita);
    let fechaFin = new Date(fechaInicio.valueOf() + 1800000);

    if (fecha1.valueOf() < fecha.valueOf()) {
      /*$(".fin .p-inputtext").css("background-color","white");*/
    }
    let text =
      "Cita para " +
      props.contacto?.Nombre_Cat.toLowerCase() +
      " con " +
      props.contacto?.Nombre_Comercial;
    let textSocio = text + " en " + props.socio?.Nombre_Comercial;
    let socio = props.socio?.Contacto_ID || "";
    //setModo(modo);
    setModoBoton("nuevo");
    setVisible(true);
    setFecha(fechaInicio);
    setFecha1(fechaFin);
    setEventoTitulo(user.Nombres + " " + user.ApellidoPaterno);
    setEventoDescripcion(socio ? textSocio : text);
    setChecked(false);
    setHasEnd(true);
    setColor("");
    setTextColor("");
    setId("");
	//if(!user?.Usu_ID){estilo();}
  };
  
  
 // const estilo = () => { document.getElementsByClassName("p-dialog-content")[0].style.padding='0'; }

  const DiasTrabajo1 = (rowData, column) => {
    if (counter === 7) {
      counter = 0;
    }
    diaMes = priDiaMes;
    let retorno = "";
    retorno = DiasTrabajoCalculo(rowData, column);
    return (
      <div
        onClick={() =>
          retorno.clic === "1" ? MostrarDialogo(retorno.diaCal, "nuevo") : ""
        }
        style={retorno.estilo}
      >
        <span className='textMovil'>{retorno.diaEvento}</span>
      </div>
    );
  };

  const DiasTrabajo = (rowData, column) => {
    let retorno = "";
    retorno = DiasTrabajoCalculo(rowData, column);
    return (
      <div
        onClick={() =>
          retorno.clic === "1" ? MostrarDialogo(retorno.diaCal, "nuevo") : ""
        }
        style={retorno.estilo}
      >
        <span className='textMovil'>{retorno.diaEvento}</span>
      </div>
    );
  };

  const DiasTrabajoCalculo = (rowData, column) => {
    let retorno = [];
    let diaCal = "";
    let diaEvento = "";
    let diaVentana = "";
    let clic = "";
    let Dia = new Date(priDiaMes.valueOf() + 3600000 * 24 * counter);
    let hora = Number(rowData.hora.split(":")[0]);
    let minutos = Number(rowData.hora.split(":")[1]);
    diaCal = new Date(Dia.setHours(hora, minutos, 0, 0));
    for (let p in agenda[counter]) {
      if (
        new Date(agenda[counter][p].start).getDate() === Dia.getDate() &&
        new Date(agenda[counter][p].start).getHours() === hora &&
        new Date(agenda[counter][p].start).getMinutes() === minutos
      ) {
        diaEvento = "Ocupado";
      }
    }
    for (let q in ventana[counter]) {
      if (
        new Date(ventana[counter][q].start).getDate() === Dia.getDate() &&
        new Date(ventana[counter][q].start).getHours() <= hora &&
        hora < new Date(ventana[counter][q].end).getHours()
      ) {
        diaVentana = "abierto";
      }
    }
    counter = counter + 1;
    diaMes = diaMes + 86400000;
    let estilo = {
      paddingTop: "0.5em",
      height: "2.5em",
      color: Dia.getDay() === 0 ? "red" : "#000",
      backgroundColor:
        Dia.getDay() === new Date().getDate() ? "#fff8e1" : "#fff",
    };
    let estiloDescanso = {
      paddingTop: "0.5em",
      height: "2.5em",
      backgroundColor:
        Dia.getDay() === new Date().getDay() ? "#fff8e1" : "#f9f7f6",
    };
    let estiloEvento = {
      paddingTop: "0.2em",
      height: "2em",
      backgroundColor: "#ff9891",
      color: "#FFF",
      overflow: "hidden",
      whiteSpace: "nowrap",
      borderRadius: "10px",
      margin: window.innerWidth>'800'?"0px 10px":"0px 3px",
    };
    let estiloLibre = {
      paddingTop: "0.2em",
      height: "2em",
      backgroundColor: "#b6ed77",
      color: "#000",
      overflow: "hidden",
      whiteSpace: "nowrap",
      borderRadius: "10px",
      margin: window.innerWidth>'800'?"0px 10px":"0px 3px",
      cursor: "pointer",
    };
    if (diaEvento !== "") {
      estilo = estiloEvento;
      clic = "";
    } else {
      estilo = estiloLibre;
      clic = "1";
    }
    if (Dia.getDay() === 0 || diaVentana !== "abierto") {
      estilo = estiloDescanso;
      clic = "";
    }
    retorno = { diaCal, estilo, diaEvento, clic };
    return retorno;
  };

  const EventosAgenda = (Events, diaUso) => {
    let eventos = [];
    let eventos1 = [];
    let ventanas = [];
    let ventanas1 = [];
    for (let c = 0; c < 7; c++) {
      for (let p in Events) {
        //Si(eventos == dia columna (0->7) & eventos = mes columna) & duran menos de 60 minutos)
        if (
          new Date(Events[p].start).getDate() ===
            new Date(diaUso.valueOf() + 3600000 * 24 * c).getDate() &&
          new Date(Events[p].start).getMonth() ===
            new Date(diaUso.valueOf() + 3600000 * 24 * c).getMonth() &&
          (new Date(Events[p].end) - new Date(Events[p].start)) / 60000 < 60
        ) {
          eventos.push({ start: Events[p].start });
        }
        if (
          new Date(Events[p].start).getDate() ===
            new Date(diaUso.valueOf() + 3600000 * 24 * c).getDate() &&
          new Date(Events[p].start).getMonth() ===
            new Date(diaUso.valueOf() + 3600000 * 24 * c).getMonth() &&
          (new Date(Events[p].end) - new Date(Events[p].start)) / 60000 > 60
        ) {
          ventanas.push({ start: Events[p].start, end: Events[p].end });
        }
      }
      eventos1[c] = eventos;
      eventos = [];
      ventanas1[c] = ventanas;
      ventanas = [];
    }
    console.log(eventos1);
    console.log(ventanas1);
    setAgenda(eventos1);
    setVentana(ventanas1);
  };

  const getEventos = async (accion, evento) => {
    let resource = await serverEventos(accion, evento);
    console.log(resource.data);
    EventosAgenda(resource.data, priDiaMes);

    if (accion === "leer") {
      setEvents(resource.data);
      //	calendarioGlobal.addEventSource(resource.data);
    }

    if (accion !== "leer") {
      setEvents(resource.data);
      //	calendarioGlobal.removeAllEvents();
      //	calendarioGlobal.addEventSource(resource.data);
    }
  };

  const serverEventos = async (accion,evento) => { var config = { headers: { 'content-type': 'multipart/form-data' }  };

  const formData = new FormData();
  formData.append('operacion', 15);
  formData.append('Contacto_ID', props.contacto?.Contacto_ID); 
  formData.append('Socio_ID', props.socio?.Contacto_ID || '0');   
  formData.append('accion', accion);
  if(evento!==undefined){
  let st=evento.start; 
  let mesSt=st?.getMonth()+1;
  let end=evento.end;	
  let mesEnd=end?.getMonth()+1;
  formData.append('id', evento.id);
  formData.append('Usu_ID', user.Usu_ID);
  //	formData.append('Contacto_ID', 0);
  formData.append('title', evento.title);
  formData.append('descripcion', evento.descripcion);
  formData.append('start', st?.getFullYear()+'-'+mesSt+'-'+st?.getDate()+' '+st?.getHours()+':'+st?.getMinutes()+':'+st?.getSeconds());
  formData.append('end', end?.getFullYear()+'-'+mesEnd+'-'+end?.getDate()+' '+end?.getHours()+':'+end?.getMinutes()+':'+end?.getSeconds());
  formData.append('allDay', evento.allDay);
  formData.append('hasEnd', evento.hasEnd);
  formData.append('color', evento.Color);
  formData.append('textColor', evento.textColor);	}
  
  return await axios.post(api, formData, config);
} 


  const onChecked = (e) => {
    setChecked(e.checked);
//	console.log(user.Nombres + " " + user.ApellidoPaterno);
    setEventoTitulo(user.Nombres + " " + user.ApellidoPaterno);	
    if (!e.checked) {
    }
  };

  const ActualizaEventos = (mando) => {
    if (modoBoton === "edita") {
      mando = "edita";
    }
    let Events = events;
    var eventoNuevo = {
      id: id,
      title: eventoTitulo,
      descripcion: eventoDescripcion,
      start: fecha,
      end: fecha1,
      allDay: false,
      hasEnd: hasEnd,
      Color: Color,
      textColor: textColor,
    };
    if (mando === "agrega") {
      getEventos("agregar", eventoNuevo);
    }
    if (mando === "borra") {
      if (window.confirm("¿Está seguro que desea borrar el evento?")) {
        getEventos("borrar", eventoNuevo);
      } else {
        mando = "error";
      }
    }
    if (mando === "edita") {
      getEventos("editar",  eventoNuevo);
    }
    console.log(Events);
    console.log(eventoNuevo);
    if (mando !== "error") {
      setVisible(false);
      setEvents(Events);
      setModoBoton("nuevo");
    } else {
      setModoBoton("nuevo");
    }
  };

  /*$("p-datatable-row").css("background-color","#fdfdfd");
$("body #combo .p-inputtext").css("padding","0.2em");
$("body #fechador .p-inputtext").css({"visibility":"hidden","width":"1em"});
$("#fechador .p-datepicker").css({"min-width":"33em","margin-left":il});*/
/*const es = {
  firstDayOfWeek: 1,
  dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
  monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]};*/
  let headerGroup = <ColumnGroup>
  <Row>
    <Column header="Horas" rowSpan={2} />
    <Column header={<span style={{alignItems: 'center', display: 'flex',justifyContent: 'space-between'}}> <div><Button className="botonAgenda" icon="pi pi-fw pi-caret-left" onClick={(e) => {setPriDiaMes(priDiaMes-86400000*7);EventosAgenda(events,priDiaMes - 86400000*7);}} style={{marginRight: '0.2em'}}/><Button className="botonAgenda" icon="pi pi-fw pi-caret-right" onClick={(e) => {setPriDiaMes(priDiaMes+86400000*7); EventosAgenda(events,priDiaMes + 86400000*7);}} style={{marginRight: '0.2em'}}/><Button className="botonAgenda" label="Hoy" onClick={(e) => {setPriDiaMes(hoy); EventosAgenda(events,hoy);}} style={{/*position:'absolute',*/width:'4em'}}/> </div><div> {new Date(priDiaMes.valueOf()+3600000*24*1).toLocaleDateString("es-PE",{month: "long", year: "numeric"})}</div><div><Calendar id='fechador'  value={priDiaMes} onChange={(e) => setPriDiaMes(e.value.valueOf())} /*locale={es}*/ showIcon={true} /*style={{marginRight: '4.5em'}}*/ /></div></span>}  colSpan={7} />
  </Row>
  <Row>
    <Column header={ DiaHeader(0)} style={ DiaEstilo(0)} />
    <Column header={ DiaHeader(1)} style={ DiaEstilo(1)} />
    <Column header={ DiaHeader(2)} style={ DiaEstilo(2)} />
    <Column header={ DiaHeader(3)} style={ DiaEstilo(3)} />
    <Column header={ DiaHeader(4)} style={ DiaEstilo(4)} />
    <Column header={ DiaHeader(5)} style={ DiaEstilo(5)} />
    <Column header={ DiaHeader(6)} style={ DiaEstilo(6)} />
  </Row>
</ColumnGroup>;
const footer1 =  <div>
<Button label="Cancelar" icon="pi pi-times" className="p-button-secondary  dialogoBoton" onClick={() => setVisible(false)} />
<Button label="Guardar" icon="pi pi-check" className="p-button-raised  dialogoBoton" onClick={e =>  ActualizaEventos('agrega')} disabled={!checked}/>
</div>; 
return (
  <div className='acerca' style={{border:'0'}}>
  <h2 className='movil500mas' style={{margin:'-1.0em 0 1em'}}>{'Reservar una cita en ' + props.contacto?.Nombre_Cat +' con '+ props.contacto?.Nombre_Comercial}</h2>
    <div id="calendario" style={{width:'100%',minHeight: '500px'}}>
      <div className="p-grid" /*style={{marginTop: '15px', marginLeft: '1px', marginRight: '1px'}}*/>
        <DataTable className="Roster" value={turnos} editable={true} headerColumnGroup={headerGroup} >
          <Column field="hora" header="Hora" style={{width:'15%',textAlign:'center'}} />
          <Column body={ DiasTrabajo1} style={ DiaEstiloC(0)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(1)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(2)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(3)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(4)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(5)} />
          <Column body={ DiasTrabajo} style={ DiaEstiloC(6)} />
        </DataTable>  
      </div>   
    </div>
    <Dialog header={user?.Usu_ID!=null?"Resumen de tu reserva":""} visible={visible} style={{width: user?.Usu_ID?'480px':'auto', background:'#ffffff', borderRadius:'0.3rem' }} footer={user?.Usu_ID?footer1:''} onHide={() => setVisible(false)}>
      {user?.Usu_ID!=null?<div className="p-grid dialogoFormulario">
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Contacto</label> <span >{props.socio?.Contacto_ID?props.socio.Nombre_Comercial+' ('+props.contacto?.Nombre_Cat+')':props.contacto?.Nombre_Comercial+' ('+props.contacto?.Nombre_Cat+')'}</span>  </span></div> 
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Cliente</label> <span >{user.Nombres}</span>   </span></div> 
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Fecha del evento</label> <span>   {fecha.toLocaleDateString("es-PE", {weekday: "long",  month: "long", day: "numeric", hour: "numeric", minute: "numeric"})+ ' horas'}  </span>    </span></div>
        <div className="p-col-12 p-md-12" > <span> <label className="topLabel">Descripción</label>  <span style={{textAlign: 'justify'}}>{eventoDescripcion}</span> </span></div>	
        <div className="p-col-12 p-md-12" > <Checkbox checked={checked} onChange={onChecked} /><div style={{marginTop: '-1.5em', fontSize: '12px', color: '#898989',marginLeft: '2.3em'}}>Acepto los <a href='index.html'>terminos y condiciones</a> para realizar la reserva de la cita</div></div>
      </div>:<LogForm ajuste='1'/>}
    </Dialog>			
  </div>
  );
}  
import {StoreContext} from './../store/StoreProvider';
import {Route, Redirect} from 'react-router-dom';
import React, { useContext} from 'react';


export default function PublicRoute({component: Component, ...rest}) {
const {store} = useContext(StoreContext);
const {user} = store;


return (
	<Route {...rest}><> {!user.Usu_ID ? <Component/>:<Redirect to="/"></Redirect>} </></Route>
	); 
  
  
  }
import classNames from 'classnames';
import {Route, Switch, useLocation} from 'react-router-dom';
import {Home} from './Home';
import {StoreContext} from './store/StoreProvider';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {Listado} from './Listado';
import {Contacto} from './Contacto';
import {Billing} from './Billing';
import {Perfil} from './Perfil';
import {Contactos} from './Contactos';
import {Puntuacion} from './Puntuacion';
import {Feedback} from './Feedback';
import {Categoria} from './Categoria';
import {Ventana} from './Ventana';
import {Pagina} from './Pagina';
import {Registro} from './Registro';
import {Login} from './Login';
import {Notificacion} from './Notificacion';
import {Recovery} from './Recovery';
import {Site} from "./Site";
import {InfoData} from "./InfoData";
import { Supermenu } from './Supermenu';
import { Agreement } from './legal/Agreement';
import { About } from './legal/About';

import {types}  from './store/StoreReducer';
import PrivRoute from './routers/PrivRoute';
import PublicRoute from './routers/PublicRoute';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import './layout/App.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './layout/layout.css';
import './layout/css_sc.css';
import './layout/flags.css';
import './layout/log.css';
import './layout/tema.css';
import './layout/font-awesome-4.3.0/css/font-awesome.css';
//import 'primereact/resources/primereact.min.css';



export const App = () => {

    const {store, dispatch} = useContext(StoreContext);
    const {user, alertas, home, zona} = store;
    const [subirC, setSubirC] = useState('hidden');  
	const location = useLocation();


    // Función para manejar el scroll
    const handleScroll = () => {
        if (window.scrollY > window.innerHeight - 100) {
            setSubirC("visible");
        } else {
            setSubirC("hidden");
        }
    };

    // Agregar el event listener al montar el componente
    React.useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); // Limpiar el event listener al desmontar
        };
    }, []);


    useEffect(() => {
	const visita = async (tip) => {
	tip = tip === 0 ? 1 : tip;    // tipo 1:home, tipo 2, web, tipo 3: cto
	console.log('visita');		
	let ubicacion = await InfoData(types.visita, tip);
	console.log(ubicacion.data[0]);	
	dispatch({ type: types.ubicacion, payload: ubicacion.data[0] });
	localStorage.setItem('ubicacion', JSON.stringify(ubicacion.data[0]));
	}	
	visita(tipo);
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);	

	// Esto sirve para llevar la cuenta de cuantas visitas tuvo el portal, un contacto o una web generada	  
	const contador = (texto) => {
	  let cadena = texto.slice(0,-1);
	  var indices = [];	  
		  for(var i = 0; i < cadena.length; i++) {
			if (cadena[i] === '/') indices.push(i);
		  }
	return indices.length;
	}
	const barras = contador(location.pathname); //home 0 barras, contacto: 3 barras, web: 1 barra
	//const tipo = barras === 1 ? (location.pathname.length > 1 ? 2 : 1) : barras === 0 ? 1 : (barras === 3 || barras === 4) ? 3 : barras; // tipo 1:home, tipo 2, web, tipo 3: cto
	const tipo = barras===1?(location.pathname.length>1?2:barras):barras===2?4:barras; // tipo 0:home, tipo 2, web, tipo 3: cto		
			
//	console.log(tipo);
//	console.log(barras);	
 
    const menuPublico = [{label: 'Iniciar sesión', to: '/usuario/login'},
                         {label: 'Registrate',  to: '/usuario/registro'}]; 

    var menuSesion = [{label: 'Perfil de usuario',  to: '/'+user?.Nombres?.split(' ').join('-')+'/perfil'},
					  {label: 'Contactos web', to: '/'+user?.Nombres?.split(' ').join('-')+'/contactos'},
					  {label: 'Notificaciones', to: '/'+user?.Nombres?.split(' ').join('-')+'/notificacion', badge: alertas?alertas.length:'' },
					  {label: 'Salir',  to: home, command:()=>{ dispatch({type:types.authLogout, payload: { logout: true }});} }]; 

    const modo = (menu) => {
       if(user.Modo==='1'){
        let salir = menu.pop(); 
            menu.push({label: 'Administrador', to: '/'+user?.Nombres?.split(' ').join('-')+'/ventana', command:()=>{ document.getElementsByClassName('p-link layout-menu-button')[0].classList.toggle('oculto');}}); 
            menu.push(salir);}
    return menu;
    }    


    const onToggleMenu = (event) => {
        event.preventDefault();
    }


    const onMenuItemClick = (event,item) => {
	document.getElementById('menuUser').classList.add('oculto')	
		if(event.item.label!=='Salir'){
			if(event.item.label!=='Páginas web'){
			document.getElementById('superMenu').style.display='none';			
			}
		}
    }


    const subirClassName = classNames("fas fa-angle-up  et_pb_scroll_top et-pb-icon", {
      'et-visible': subirC === 'visible',
      'et-hidden': subirC === 'hidden'
    });
	
	
    return (
        <div >
			<Route exact path="/:userweb" component={Pagina} />
			{(tipo!==2 )?
			<Fragment>
			<AppTopbar onToggleMenu={onToggleMenu} menuUsuario={user?.Usu_ID?modo(menuSesion):menuPublico} onMenuItemClick={onMenuItemClick} sidebarClassName={classNames('layout-sidebar', 'layout-sidebar-light')}/>
			<div className="layout-main" id="layoutMain" style={{display:zona.data?.Pais_ID?'block':'none'}}>
			<Supermenu/>
				<Switch>
				 <Route exact path="/:pais/:departamento/:distProv/:categoria/:SubCategoria/:hoja" component={Listado} />
				 <Route exact path="/contacto/:contacname/:userweb" component={Contacto} />
				 <Route exact path="/modelo/web" component={Site} />
				 <Route exact path="/feedback/:userweb" component={Feedback} />
				 <Route exact path="/legal/:document" component={Agreement} />
				 <Route exact path="/acerca/about" component={About} />
				 <PublicRoute exact path="/usuario/login" component={Login} />
				 <PublicRoute exact path="/usuario/registro" component={Registro} />
				 <PublicRoute exact path="/usuario/recovery" component={Recovery} />
				 <PrivRoute exact path="/:contacname/puntuacion" component={Puntuacion} />
				 <PrivRoute exact path="/:username/perfil" component={Perfil} />
				 <PrivRoute exact path="/:username/contactos" component={Contactos} />
				 <PrivRoute exact path="/:username/facturacion" component={Billing} />
				 <PrivRoute exact path="/:username/notificacion" component={Notificacion} />
				 <PrivRoute exact path="/:username/categoria" component={Categoria} />
				 <PrivRoute exact path="/:username/ventana" component={Ventana} />
				 <Route exact path="/" component={Home} />
				</Switch> 
			</div>
			</Fragment>:''}
			<span className={subirClassName} onClick={(e)=> {window.scrollTo({top: 0, behavior:'smooth'});}} style={{display:'inline'}}></span>
			<AppFooter />
        </div>
    );

}

export default App;
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import {Categoria} from './Categoria';
import { Dialog } from "primereact/dialog";
import { StoreContext } from "./store/StoreProvider";
import { Paginator } from 'primereact/paginator';
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { Row } from "primereact/row";

export const Ventana = (props) => {
  const [mostrar, setMostrar] = useState("");  
  const [mando, setMando] = useState({ select: "categoria" });


  useEffect(() => {
    editarForm(0);
     //eslint-disable-next-line
  }, []);


  const editarForm = (caso) => {
    switch (caso) {
      case 0:
        setMando({ ...mando, select: "categoria", caso:caso });
        break;
      case 1:
        setMando({ ...mando, select: "ranking", caso:caso });
        break;
      case 2:
        setMando({ ...mando, select: "busquedas", caso:caso });
        break;
      case 3:
        setMando({ ...mando, select: "visitas", caso:caso });
        break;
        default: 
        console.log("No hay coincidencias");
    }
  };



  //--------------------------------
  // Zona de renderización final


  
  const EdicionMenu=<ul>
  <li className={mando.caso===0?"menuContactos elegido":"menuContactos"} onClick={() => {editarForm(0)}} style={{padding:'1.2em .5em', borderTop:'solid 1px #cfd3e099'}}>Categorias</li>
  <li className={mando.caso===1?"menuContactos elegido":"menuContactos"} onClick={() => {editarForm(1)}} style={{padding:'1.2em .5em'}}>Ranking</li>
  <li className={mando.caso===2?"menuContactos elegido":"menuContactos"} onClick={() => {editarForm(2)}} style={{padding:'1.2em .5em'}}>Búsquedas</li>
  <li className={mando.caso===3?"menuContactos elegido":"menuContactos"} onClick={() => {editarForm(3)}} style={{padding:'1.2em .5em'}}>Visitas</li>  
  </ul> ;

  return (
  <div id="panelEdicion" className="p-grid marco">
    <Dialog visible={mostrar?true:false} style={{width: '50vw'}} modal onHide={() => setMostrar('')}>
    </Dialog>
	
    <div id="menuMovil" >
    </div> 
    <div className="p-col-1">
	{EdicionMenu}
	</div> 
	<div className="p-col-11">
		<div style={{width:'90%', margin:'auto'}}>
			{mando.select==='categoria'?<Categoria/>:
			 mando.select==='ranking'  ?<Ranking/>:
			 mando.select==='busquedas'?<Busquedas/>:
			 mando.select==='visitas'  ?<Visitas/>			 
			:''}
		</div> 
	</div> 
	
  </div>
  ); 

};





export const Ranking = (props) => {
const {store} = useContext(StoreContext);
const {api } = store;
const [ranking, setRanking] = useState([]);
const [basicFirst, setBasicFirst] = useState(0);
const [basicRows, setBasicRows] = useState(15);

useEffect(() => {
rankingContactos(basicFirst, basicRows);
//eslint-disable-next-line
}, []);

const rankingContactos = async (first, rows) => {
    let resource = await serverRankingContactos(first, rows);
	setRanking(resource.data);
	console.log(resource.data);
  }

  
const serverRankingContactos = async (first, rows) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 4.1);
  formData.append('skip', first);  
  formData.append('limit', rows);    
  return await axios.post(api, formData, config);
  } 

  

   const visitaWeb = (rowData, column) => {
    let retorno = "";
    retorno = rowData.visitaEx+rowData.visitaIn;
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };
  
  
   const visitaPerfil = (rowData, column) => {
    let retorno = "";
    retorno = rowData.visitaCtoEx+rowData.visitaCtoIn;
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };
  
    const estampaVW = (rowData, column) => {
    let retorno = "";
	retorno = new Date(rowData.estampaVW*1000).toLocaleDateString("es-PE",{ month: "numeric", day: "numeric",  hour:'numeric', minute:'numeric'})
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };
 
  
    const estampaVC = (rowData, column) => {
    let retorno = "";
	retorno = new Date(rowData.estampaVC*1000).toLocaleDateString("es-PE",{ month: "numeric", day: "numeric",  hour:'numeric', minute:'numeric'})
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };
  
    const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);	
		console.log(event.first);  //skip
		console.log(event.rows);   //limit	
		rankingContactos(event.first, event.rows);		
    }
  

  let headerGroup = <ColumnGroup>
	  <Row>
	  	<Column header="Item" rowSpan={2} style={{width:'5%'}} />
		<Column header="C_ID" rowSpan={2} style={{width:'5%'}} />
		<Column header="Contacto" rowSpan={2} style={{width:'30%'}}/>
		<Column header="Visitas web" colSpan={4} />
		<Column header="Visitas Internas" colSpan={4} />	
	  </Row>
	  <Row>
		<Column header="Estampa"  />
		<Column header="Total" />
		<Column header="externas" />
		<Column header="Internas" />
		<Column header="Visitado"  />		
		<Column header="Total" />
		<Column header="externas" />
		<Column header="Internas" />
	  </Row>
	</ColumnGroup>;
return (
	<div className='p-fluid panelContacto'>
	<h1 >Contactos más visitados en el sistema</h1>
		<DataTable className="Roster" value={ranking} headerColumnGroup={headerGroup}>
		  <Column field="item" header="Item" style={{width:'5%'}}/>
		  <Column field="Contacto_ID" header="ID" style={{width:'5%',textAlign:'center'}} />
		  <Column field="Nombre_Comercial" header="Contacto" style={{width:'40%'}}/>
		  <Column body={estampaVW} style={{padding:'5px', fontSize:'12px'}}/>
		  <Column body={visitaWeb} header="Visitas Web" />			  
		  <Column field="visitaEx" header="Visitas externas" />	
		  <Column field="visitaIn" header="Visitas Internas" />		
		  <Column body={estampaVC} style={{padding:'5px', fontSize:'12px'}}/>		  
		  <Column body={visitaPerfil} header="Visitas Perfil" />			  
		  <Column field="visitaCtoEx" header="Visitas externas" />	
		  <Column field="visitaCtoIn" header="Visitas Internas" />			  
		</DataTable>
		<Paginator first={basicFirst} rows={basicRows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onBasicPageChange}  ></Paginator>
	</div>
);

}



export const Busquedas = (props) => {
const { store } = useContext(StoreContext);
const {api } = store;
const [ranking, setRanking] = useState([]);
const [basicFirst, setBasicFirst] = useState(0);
const [basicRows, setBasicRows] = useState(15);

useEffect(() => {
rankingContactos(basicFirst, basicRows);
//eslint-disable-next-line
}, []);

const rankingContactos = async (first, rows) => {
    let resource = await serverRankingBusquedas(first, rows);
	setRanking(resource.data);
	console.log(resource.data);
  }

  
const serverRankingBusquedas = async (first, rows) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 4.2);
  formData.append('skip', first);  
  formData.append('limit', rows);    
  return await axios.post(api, formData, config);
  } 

  
   const estampa = (rowData, column) => {
    let retorno = "";
    retorno = rowData.visitaEx+rowData.visitaIn;
	retorno = new Date(rowData.estampa*1000).toLocaleDateString("es-PE",{ month: "numeric", day: "numeric", year:'numeric', hour:'numeric', minute:'numeric'})
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };

  
    const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);	
		console.log(event.first);  //skip
		console.log(event.rows);   //limit	
		rankingContactos(event.first, event.rows);		
    }
  

  let headerGroup = <ColumnGroup>
	  <Row>
		<Column header="ID" rowSpan={2} style={{width:'5%'}}/>
		<Column header="Categoría" rowSpan={2} style={{width:'20%'}}/>
		<Column header="Visitas" rowSpan={2} style={{width:'8%'}}/>
		<Column header="Estampa" rowSpan={2} style={{width:'15%'}}/>		
		<Column header="Ubicacion Nombre" colSpan={4} />	
	  </Row>
	  <Row>
		<Column header="Pais" />
		<Column header="Departamento" />
		<Column header="Provincia" />
		<Column header="Distrito" />
		</Row>
	</ColumnGroup>;
return (
	<div className='p-fluid panelContacto'>
	<h1>Categorías más buscadas y su ubicación en el sistema</h1>
		<DataTable className="Roster" value={ranking} headerColumnGroup={headerGroup}>
		  <Column field="Arbol_ID" header="ID" style={{width:'5%',textAlign:'center'}} />
		  <Column field="Nombre_Cat" header="Contacto" style={{width:'40%'}}/>
		  <Column field="visitas" style={{textAlign:'center'}}/>
		  <Column body={estampa} />			  	
		  <Column field="Nombre_Pais" />
		  <Column field="Nombre_Departamento" />	
		  <Column field="Nombre_Provincia" />	  
		  <Column field="Nombre_Distrito" />	  
		</DataTable>
		<Paginator first={basicFirst} rows={basicRows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onBasicPageChange}  ></Paginator>
	</div>
);

}



export const Visitas = (props) => {
const {store } = useContext(StoreContext);
const {api } = store;
const [visitas, setVisitas] = useState([]);


useEffect(() => {
verVisitas();
//eslint-disable-next-line
}, []);

const verVisitas = async () => {
    let resource = await serverVisitas();
	setVisitas(resource.data);
	console.log(resource.data);
  }

  
const serverVisitas = async () => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 5.6);
  return await axios.post(api, formData, config);
  } 

 /* 
   const estampa = (rowData, column) => {
    let retorno = "";
    retorno = rowData.visitaEx+rowData.visitaIn;
	retorno = new Date(rowData.estampa*1000).toLocaleDateString("es-PE",{ month: "numeric", day: "numeric", year:'numeric', hour:'numeric', minute:'numeric'})
    return (
        <span className='textMovil'>{retorno}</span>
    );
  };

  
    const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setBasicRows(event.rows);	
		console.log(event.first);  //skip
		console.log(event.rows);   //limit	
//		rankingContactos(event.first, event.rows);		
    }
  

  let headerGroup = <ColumnGroup>
	  <Row>
		<Column header="ID" rowSpan={2} style={{width:'5%'}}/>
		<Column header="Categoría" rowSpan={2} style={{width:'20%'}}/>
		<Column header="Visitas" rowSpan={2} style={{width:'8%'}}/>
		<Column header="Estampa" rowSpan={2} style={{width:'15%'}}/>		
		<Column header="Ubicacion Nombre" colSpan={4} />	
	  </Row>
	  <Row>
		<Column header="Pais" />
		<Column header="Departamento" />
		<Column header="Provincia" />
		<Column header="Distrito" />
		</Row>
	</ColumnGroup>;*/
return (
	<div className='p-fluid panelContacto' style={{marginTop:'2em'}}>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaSC || '')+' visitas a Servicontacto en tiempo de vida'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaSCD || '')+' visitas a Servicontacto el día de hoy'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaHome || '')+' visitas a Home'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaHomeD || '')+' visitas a Home el día de hoy'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaCtos || '')+' visitas a directas a contactos'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaCtosD || '')+' visitas a directas a contactos el día de hoy'}</h1>	
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaWebs || '')+' visitas a directas a webs dentro del sistema'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.visitaWebsD || '')+' visitas a directas a webs dentro del sistema el día de hoy'}</h1>		
	<h1 style={{marginTop:'1em'}}>{(visitas.contactos || '')+' contactos publicados en Servicontacto'}</h1>
	<h1 style={{marginTop:'1em'}}>{(visitas.usuarios || '')+' Usuarios registrados en el sistema'}</h1>
	</div>
);

}
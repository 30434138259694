import { useContext, useMemo } from 'react';
import { StoreContext } from '../store/StoreProvider';

export const useHorario = (contacto) => {
  const { store } = useContext(StoreContext);
  const { horarioConfig } = store;

  const FORMATO_HORA = '12h'; // Formato AM/PM
  const MOSTRAR_CERO_INICIAL = false; // Sin cero inicial

  const horario = contacto.horario;
  const inicio = parseInt(contacto.inicio, 10);
  const fin = parseInt(contacto.fin, 10);
  const inicio1 = parseInt(contacto.inicio1, 10);
  const fin1 = parseInt(contacto.fin1, 10);
  const refrigerio = parseInt(contacto.refrigerio, 10);
  const entretiempo = parseInt(contacto.entretiempo, 10);

  const horarioData = useMemo(() => {
    const minutesToTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      if (FORMATO_HORA === '24h') {
        const formattedHours = MOSTRAR_CERO_INICIAL ? hours.toString().padStart(2, '0') : hours.toString();
        return `${formattedHours}:${mins.toString().padStart(2, '0')}`;
      } else {
        const period = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
        const displayHours = MOSTRAR_CERO_INICIAL ? formattedHours.toString().padStart(2, '0') : formattedHours.toString();
        return `${displayHours}:${mins.toString().padStart(2, '0')} ${period}`;
      }
    };

    const config = horarioConfig.find(h => h.value === horario?.toString());
    if (!config) return { dias: "Horario no definido", periodos: [] };

    if (horario === "0") {
      return {
        dias: config.label,
        periodos: [{ dia: config.label1, horario: config.solo }],
      };
    }

    const startTime = minutesToTime(inicio);
    const endTime = minutesToTime(fin);
    const refrigerioStart = !isNaN(refrigerio) ? minutesToTime(refrigerio) : null;
    const refrigerioEnd = !isNaN(refrigerio) && !isNaN(entretiempo) ? minutesToTime(refrigerio + entretiempo) : null;
    const partialStart = !isNaN(inicio1) ? minutesToTime(inicio1) : null;
    const partialEnd = !isNaN(fin1) ? minutesToTime(fin1) : null;

    const hasRefrigerio =
      !isNaN(refrigerio) &&
      !isNaN(entretiempo) &&
      entretiempo > 0 &&
      refrigerio >= inicio &&
      (refrigerio + entretiempo) <= fin;

    const mainDaysPeriod = hasRefrigerio
      ? `${startTime} - ${refrigerioStart} y desde ${refrigerioEnd} - ${endTime}`
      : `${startTime} - ${endTime}`;

    const partialDayPeriod = config.solo && partialStart && partialEnd ? `${partialStart} - ${partialEnd}` : null;

    const periodos = [{ dia: config.label1, horario: mainDaysPeriod }];
    if (partialDayPeriod) {
      periodos.push({ dia: config.solo.charAt(0).toUpperCase() + config.solo.slice(1), horario: partialDayPeriod });
    }

    return {
      dias: config.label,
      periodos,
    };
  }, [
    horario,
    inicio,
    fin,
    inicio1,
    fin1,
    refrigerio,
    entretiempo,
    horarioConfig,
    MOSTRAR_CERO_INICIAL, // Incluida porque se usa en minutesToTime
  ]);

  return horarioData;
};
import React, { useContext, useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { AppSeeker } from './AppSeeker';
import { AppMenu } from './AppMenu';
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";


export const AppTopbar = (props) => {

    const { store, dispatch } = useContext(StoreContext);
	const {user, paises, ciudades, provincias, distritos, ubicacion, zona, ruta, img, alertas, home, logo, logom, control} = store;
	const { Pais_ID = '89',Departamento_ID = '',Provincia_ID = '',Distrito_ID = ''  } = ubicacion || {};
    const [selectedCountry, setSelectedCountry] = useState( Pais_ID );
    const [departamento, setDepartamento] = useState( Departamento_ID );
    const [provincia, setProvincia] = useState( Provincia_ID );
    const [distrito, setDistrito] = useState( Distrito_ID );
    const [ubicacionesf, setUbicacionesf] = useState([{ label: "", value: "" }]);
    const [ubicacionT, setUbicacionT] = useState([]);	
    const [menu, setMenu] = useState('');


    // Verificar la sesión en el servidor
    const checkSession = async () => {
        try {
            const response = await InfoData('session'); // Llama a portal.php con operacion=0
            console.log("Session response:", response);
            return response;
        } catch (error) {
            console.error("Error checking session:", error);
            return { status: 'not_logged_in' };
        }
    };

	
    // Manejar logout
    const handleLogout = async () => {
        try {
            console.log('Estamos en proceso de logout');	
            const response = await InfoData(types.authLogout); // Llama a login.php con operacion=2
            if (response.salida.message === "Sesión cerrada correctamente" || !!user.Usu_ID ) {
                [types.alertas, types.formulario, types.especialidad, types.contactosUser].forEach(type =>
                    dispatch({ type, payload: type === types.formulario ? { editar: '' } : [] })
                );
                dispatch({ type: types.authLogout, payload: { ID: user.Usu_ID, logout: false } });
                console.log('Usuario deslogueado');
            }
        } catch (error) {
            console.error("Error en logout:", error);
        } finally {
		
            console.log('Estamos deslogueados');
        }
    };

	
	const obtenerlugares = async (perfil) => {
		setSelectedCountry(perfil.Pais_ID);
		setDepartamento(perfil.Departamento_ID);
		setProvincia(perfil.Provincia_ID);
		setDistrito(perfil.Distrito_ID);	
		console.log('crea lista de ciudades');
		if ( ciudades[0]?.Pais_ID !== perfil.Pais_ID || ciudades.length===0){
			let db = await InfoData(types.ciudades, perfil.Pais_ID);
			dispatch({type:types.ciudades, payload: db.ciudades}); 
			localStorage.setItem('ciudades',JSON.stringify(db.ciudades));
		}			
		if(perfil.Pais_ID==='89'){
			console.log(provincias.find(item => item.value === perfil.Provincia_ID)?.label);
			let db1 = await InfoData(types.provincias, perfil.Departamento_ID);
			dispatch({type:types.provincias, payload: db1.provincias}); 
			localStorage.setItem('provincias',JSON.stringify(db1.provincias));
			Parametros(perfil.Departamento_ID, perfil.Provincia_ID, '', perfil.Pais_ID);
		}else{
			obtenerDistritos('',perfil.Departamento_ID);
			Parametros(perfil.Departamento_ID, '', perfil.Distrito_ID, perfil.Pais_ID);
		}
	}	
	

    // Recuperar sesión y manejar expiración
    useEffect(() => {
        console.log('efecto 1 - Sesión, Expiración y Logout');
        let timeoutId = null;

        const recoverSession = async () => {
            if (user.logout) {
                await handleLogout(); // Ejecutar logout si user.logout es true
                return; // Salir inmediatamente
            }
			if (user.login && !!user.Usu_ID) {     // Al hacer login carga de estados
					obtenerlugares(user);
					console.log('Se acaba de loguear alguien');
                return; // Salir inmediatamente
            }

            const sessionData = await checkSession();	//Resupera sesión al recargar página
            if (sessionData.status === 'logged_in') {
                const perfil = sessionData.user;
                if (!user.Usu_ID) { // Solo recuperar si no hay usuario en el estado
                    const ubicacion = {
                        Departamento: perfil.Departamento,
                        Departamento_ID: perfil.Departamento_ID || "",
                        Distrito: perfil.Distrito,
                        Distrito_ID: perfil.Distrito_ID || "",
                        Pais: perfil.Pais,
                        Pais_ID: perfil.Pais_ID || '',
                        Provincia: perfil.Provincia,
                        Provincia_ID: perfil.Provincia_ID || '',
                        label: `${perfil.Distrito}, ${perfil.Provincia} - ${perfil.Departamento} - ${perfil.Pais}`,
                        value: ""
                    };
                    dispatch({ type: types.ubicacion, payload: ubicacion });
                    dispatch({ type: types.authLogin, payload: { ...perfil, login: false, logout: false } });

                    localStorage.setItem('ubicacion', JSON.stringify(ubicacion));
                    localStorage.setItem('selectedCountry', perfil.Pais_ID);
					obtenerlugares(perfil);
                    console.log('Sesión recuperada para Usu_ID:', perfil.Usu_ID);
                }

                // Configurar temporizador para expiración
                const timeLeft = perfil.session_expires - Math.floor(Date.now() / 1000);
                console.log('Tiempo restante de sesión:', timeLeft);
                if (timeLeft > 0) {
                    timeoutId = setTimeout(() => {
                        console.log('Sesión expirada automáticamente');
                        handleLogout();
                    }, timeLeft * 1000);
                } else if (user.Usu_ID) {
                    console.log('Sesión ya expirada en el servidor');
                    handleLogout();
                }
            }
			else if (user.Usu_ID) {
                // Sesión no válida en el servidor, forzar logout
                handleLogout();
            } 
        };

        recoverSession();

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]); // Dependencias: user.Usu_ID y user.logout
	

	//generador de parametros de navegación dentro del router
  const Parametros = useCallback((Dpto, Prov, Dist, pais, distN) => {
    const newData = {
      Pais_ID: pais,
      Pais: paises?.find(item => item.value === pais)?.label || 'el país',
      Departamento_ID: Dpto || '',
      Departamento: ciudades?.find(item => item.value === Dpto)?.label || "",
      Provincia_ID: Prov || '',
      Provincia: provincias?.find(item => item.value === Prov)?.label || "",
      Distrito_ID: Dist || distN,
      Distrito: distritos?.find(item => item.value === Dist)?.label || "",
    };
console.log( Dpto, Prov, Dist, pais, newData);
    // Verificar si los datos ya están actualizados
    if (
      zona.data?.Pais_ID === newData.Pais_ID &&
      zona.data?.Departamento_ID === newData.Departamento_ID &&
      zona.data?.Provincia_ID === newData.Provincia_ID &&
      zona.data?.Distrito_ID === newData.Distrito_ID
    ) {
      return; // No actualizar si los datos son iguales
    }

    // Valores predeterminados para Departamento, Provincia y Zona
    const Pais = newData.Pais;
    const dpto = newData.Departamento ? newData.Departamento : `todo-${newData.Pais}`;
    const alterno = `Todo-${newData.Departamento}`;
    const prov = newData.Provincia ? newData.Provincia : newData.Departamento ? alterno : 'toda-la-región';
    const zonaValue = newData.Distrito ? `distrito-de-${newData.Distrito}` : newData.Provincia ? `provincia-de-${prov}` : prov;

    // Construir la URL con tres segmentos principales
    let parametro = `/${Pais}/${dpto}/${zonaValue}/`;
    parametro = parametro.split(' ').join('-');

    // Query string
    let query = Dist ? '&d=' + Dist : Prov ? '&p=' + Prov : Dpto ? '&D=' + Dpto : '&P=' + pais;

    // Actualizar el estado global
    dispatch({ type: types.zona, payload: { data: newData, parametro, query } });
  }, [ paises, ciudades, provincias, distritos, zona.data?.Pais_ID, zona.data?.Departamento_ID, zona.data?.Provincia_ID, zona.data?.Distrito_ID, dispatch ]);

  
async function obtenerUbicacion() {
  // Verificar si el navegador soporta geolocalización
  if (navigator.geolocation) {
    try {
      // Solicitar la ubicación
      const posicion = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true, // Intentar obtener la ubicación más precisa
          timeout: 5000, // Tiempo máximo (en ms) para obtener la ubicación
          maximumAge: 0, // No usar ubicaciones en caché
        });
      });

      // Extraer datos de la ubicación
      const latitud = posicion.coords.latitude;
      const longitud = posicion.coords.longitude;
      const precision = posicion.coords.accuracy; // precisión en metros

      console.log(`Latitud: ${latitud}`);
      console.log(`Longitud: ${longitud}`);
      console.log(`Precisión: ${precision} metros`);

      // Mostrar alerta con la ubicación
      alert(`Latitud: ${latitud}, Longitud: ${longitud}, Precisión: ${precision} metros`);

      // Llamar a la función InfoData (asumiendo que es una función asíncrona)
      const ubicacion = await InfoData('gps', { lat: latitud, lon: longitud });
      console.log(ubicacion.data[0]);

      // Dispatch y almacenamiento en localStorage
      dispatch({ type: types.ubicacion, payload: ubicacion.data[0] });
      localStorage.setItem('ubicacion', JSON.stringify(ubicacion.data[0]));
    } catch (error) {
      // Manejar errores
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.error("El usuario denegó el permiso para obtener su ubicación");
          break;
        case error.POSITION_UNAVAILABLE:
          console.error("La información de ubicación no está disponible");
          break;
        case error.TIMEOUT:
          console.error("Se agotó el tiempo para obtener la ubicación");
          break;
        case error.UNKNOWN_ERROR:
          console.error("Ocurrió un error desconocido");
          break;
        default:
          console.error("Ocurrió un error inesperado:", error.message);
      }
    }
  } else {
    console.error("Tu navegador no soporta geolocalización");
  }
}



  
	//obtiene un listado de departamentos sin provincias ni distritos
const obtenerciudades = useMemo(
  () => async (pais) => {
	let db = await InfoData(types.ciudades, pais);
	dispatch({type:types.ciudades, payload: db.ciudades}); 
	localStorage.setItem('ciudades',JSON.stringify(db.ciudades));
	console.log(db.ciudades);
	if(pais==='89'){}
	dispatch({type:types.provincias, payload:[]}); 
	localStorage.setItem('provincias',JSON.stringify([]));		
	dispatch({type:types.distritos, payload: []});  
	localStorage.setItem('distritos',JSON.stringify([]));
}, [dispatch]);


const obtenerPaises = useCallback(async () => {
	if (!paises.length) {
		const db1 = await InfoData(types.paises, '');
		dispatch({ type: types.paises, payload: db1.paises });
		localStorage.setItem('paises', JSON.stringify(db1.paises));
		console.log('únicamente se listará los paises si no hay caché');
		};
	
	if( Number(selectedCountry) !==  Number(ciudades[0]?.Pais_ID) ){
		console.log('Si hay pais hay ciudades');		
		localStorage.removeItem('ciudades');
		ubicacionNula();
		obtenerciudades(selectedCountry);
	};
}, [paises, ciudades, selectedCountry, dispatch, obtenerciudades]);


// Efecto 2 - Acción para listar los paises y las ciudades
useEffect(() => {
console.log('Efecto 2 - Listar países');  
if (!paises.length) {
  obtenerPaises();
		console.log('nunca deberia aparecer este mensaje');  }
		
if	(ubicacion.ViaIP) { 
	obtenerlugares(ubicacion);	// detecta la ubicacion de la ip	
	dispatch({type:types.ubicacion, payload:{...ubicacion , ViaIP:false}});	
	Parametros(Departamento_ID, Pais_ID==="89"?Provincia_ID:'', Pais_ID==="89"?'':Distrito_ID, Pais_ID);
	}
if	(!!ubicacion.Pais_ID){
	//ubicacion.Pais_ID === selectedCountry && Parametros(Departamento_ID, Pais_ID==="89"?Provincia_ID:'', Pais_ID==="89"?'':Distrito_ID, Pais_ID);
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, [obtenerPaises,paises.length, ubicacion]);

   
const expandir = (caso) => {
	if(caso===1){ dispatch({type:types.provincias, payload:[]}); setProvincia(''); setDistrito(''); obtenerDistritos('',departamento); }
	if(caso===2){ setProvincia(''); setDistrito(''); obtenerProvincias(departamento); Parametros(departamento,provincia,'',selectedCountry);}
    }
	
  
const box = (caso) => {
	switch(caso){
	case 0:
	dispatch({type:'control', payload:{...control , opciones:false, buscar:true}});
	setMenu('ok');
	break;
	case 1:
	dispatch({type:'control', payload:{...control, caja:false, opciones:true, buscar:false}});
	setMenu('');
	break;
	case 2:
	if(menu === ''){
	dispatch({type:'control', payload:{...control, caja:false, opciones:true, buscar:false}});
	}
	break; 
	default: 
	console.log("No hay coincidencias");
  }
}   


const onHome = () => {

	}

const onCountryChange = (e) => {
	setSelectedCountry(e.value);
	localStorage.setItem('selectedCountry',JSON.stringify(e.value));
	Parametros('','','',e.value);
	obtenerciudades(e.value);  
	ubicacionNula();
    }

	
const ubicacionNula = () => {
	setDepartamento('');
	setProvincia('');
	setDistrito('');  
}


const onCiudadChange = (e) => {
	setDepartamento(e.value);
	setProvincia('');
	setDistrito('');
	localStorage.setItem('ciudad',JSON.stringify(e.value));
	localStorage.removeItem('provincia');        
	localStorage.removeItem('distrito');          
	if(selectedCountry==='89'){
		obtenerProvincias(e.value);
		}else{
		dispatch({type:types.provincias, payload:[]});
		localStorage.removeItem('provincias');
		obtenerDistritos('',e.value);
		}
	Parametros(e.value,'','',selectedCountry);
	}


const onProvinciaChange = (e) => {
    setProvincia(e.value);
    obtenerDistritos(e.value,'');
    setDistrito('');
    localStorage.setItem('provincia',JSON.stringify(e.value));        
    localStorage.removeItem('distrito'); 
    Parametros(departamento,e.value,'',selectedCountry);
    }
  
  
const obtenerProvincias = async (dpto) => {
    let db = await InfoData(types.provincias, dpto);
    dispatch({type:types.provincias, payload: db.provincias}); 
    localStorage.setItem('provincias',JSON.stringify(db.provincias));
    dispatch({type:types.distritos, payload: []});  
    localStorage.removeItem('distritos');
}


const onDistritoChange = (e) => {
    setDistrito(e.value);
    localStorage.setItem('distrito',JSON.stringify(e.value));    
    Parametros(departamento,provincia,e.value,selectedCountry);
    }
  
  
const obtenerDistritos = async (prov,dpto) => {
    let db = await InfoData(types.distritos, {prov:prov,dpto:dpto});
    dispatch({type:types.distritos, payload: db.distritos});  
    localStorage.setItem('distritos',JSON.stringify(db.distritos));
}


const selectedCountryTemplate = (option, props) => {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <img alt={option.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`flag flag-${option.code.toLowerCase()}`} />
                <div style={{flex: 'none'}}><span className='escritorio'>{option.label}</span><span className='movil'>{option.code.toUpperCase()}</span></div>				
            </div>
        );
    }
    return ( <span>{props.placeholder}</span> );
  }


const countryOptionTemplate = (option) => {
    return (
        <div className="country-item">
            <img alt={option.label} src={ruta+img.flag} onError={(e) => e.target.src=''} className={`flag flag-${option.code?.toLowerCase()}`} />
            <div style={{flex: 'none'}}>{option.label}</div>
        </div>
    );
  }

  
const ubicacionChange = async (e) => {
	setUbicacionT(e.value);

	let country = e.value.Pais_ID;
	let depart = e.value.Departamento_ID; 
	let distrit = e.value.Distrito_ID;
	if(typeof distrit!=='undefined' && distrit!=='') { 
	setDistrito(distrit); }
	if(typeof depart!=='undefined'){
	setDepartamento(depart); }
	if(typeof country!=='undefined' ) {
	setSelectedCountry(country);
	dispatch({type:types.provincias, payload:[]});
	( ciudades[0]?.Pais_ID !== country ) && await obtenerciudades(country);
	dispatch({type:types.ubicacion, payload:{...e.value , ViaIP:false}});	
	(distritos.find(item => item.value === distrit)?.value !== distrit ) && await obtenerDistritos('',depart); 
	setUbicacionT('');
	box(1);
	console.log(depart,'',distrit,country, e.value.Distrito);	
	Parametros(depart,'',distrit,country, e.value.Distrito);

  }
} 


const filtroubicaciones = async (event) => {    
	var resource = [];
	var ubica = [];
	if(event.query.length >= 1){
	resource = await InfoData('ubicacion', {nombre:event.query.toLowerCase(), distrito_ID:''});   
	ubica = resource.data; }; 
    setTimeout(() => {
    var results = ubica.filter((ubicacion) => {
    return ubicacion.label.toLowerCase();
    });
    setUbicacionesf(results);
        }, 350);
    }

  
const regresar = () => {
	document.getElementById('lateral').style.width='15em'
	document.getElementById('cerrar').style.display='block';
	document.getElementById('regreso').style.display='none';
	document.getElementById('superMenu').style.position='fixed';	
  };    


  const paisesEs = paises.map((pais) => (pais.idioma==='es'&&(pais.code!=='gq'||pais.code!=='do'))?<li key={pais.value} style={{display:'flex', margin:'0.35em 0'}} onClick={()=>onCountryChange({value:pais.value})}> 
                <img alt={pais.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`pop flag flag-${pais.code.toLowerCase()}`} />
				<div style={{flex: 'none'}}><span>{pais.label}</span></div>
  </li>:'');
  const mensajes = alertas>0? <span className="layout-topbar-badge">{user.Nombres ? alertas:0}</span>:'';
  return (
    <Fragment>
      <div className="layout-topbar">
              {user.Modo==='1'?<button type="button" className="p-link layout-menu-button oculto" onClick={props.onToggleMenu} style={{'float':'left', padding:'0.8em'}}>
                  <span className="pi pi-bars"/>
              </button>:''}
          <div style={{'float':'left'}}>
             <Link to= {home}> 
             <img src={ruta+logo} className="escritorio" height='47' alt={"servicontacto.com"} onClick={e => onHome()}/>
             <img src={ruta+logom} className="movil" height='47' alt={"servicontacto.com"} onClick={e => onHome()}/>
             </Link>
          </div>
          <div className="layout-topbar-icons" style={{'float':'right'}}>
              <button id='boto' onClick={()=> document.getElementById('menuUser').classList.toggle('oculto')} >
                  <span className="username" style={{verticalAlign:'top', color:'#495057', fontSize:'15px'}}>{user.Nombres ? user.Nombres: 'Hola, Identifícate'}</span>
                  <span className="layout-topbar-icon pi pi-fw pi-angle-down" style={{fontSize:'1.3em',marginLeft:'0.5em'}}/>
                  {mensajes}
              </button>
			  <div id="menuUser" className="oculto"  onMouseLeave={()=> document.getElementById('menuUser').classList.add('oculto')}> 
				  <div className={props.sidebarClassName} style={{width:'11em', height:'auto', position:'unset' }} >
					  <AppMenu model={props.menuUsuario}  onMenuItemClick={props.onMenuItemClick}/>
				  </div>            
			  </div>
          </div>
          <div className="layout-topbar-pais escritorio" style={{'display': departamento ? 'inline-block' : 'none', margin:'0.7em 0px'}}>
             <Dropdown value={departamento} options={ciudades} onChange={onCiudadChange} placeholder="Departamento"/>
          </div>
          <div className="layout-topbar-pais" style={{margin:'0.7em 0px'}}>
             <Dropdown value={selectedCountry} options={paises} onChange={onCountryChange} placeholder="Pais"
              valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
          </div>
      </div>
	  <div id="regreso" className="movil">
		  <div className="btnAmarillo" onClick={(e) => regresar() }  style={{float:'left', width:'40%', padding:'1em'}}>
		  REGRESAR</div>
		  <div id="mensaje" style={{float:'right', padding:'1em 0', width:'60%', fontSize:'1.25em', color: '#006aa7' }}>
		  Mensaje</div>					  
	  </div>
	  {control.caja &&
      <div  className="buscarDistrito" style={{maxWidth:'320px', left: 'calc(50% - 160px)'}}> 
		  {control.opciones &&
		  <ul id='opcionesBusca' onMouseLeave={(e) => box(2)}>
			{(departamento!=='' && selectedCountry === '89') && <> 
			{provincias?.length !== 0 ?
			<li className="menuHome" onClick={(e) => expandir(1) } >{'Listar todos los distritos del departamento de '+zona.data?.Departamento}</li>
			:<li className="menuHome" onClick={(e) => expandir(2) } >{'Listar las provincias del departamento de '+zona.data?.Departamento}</li>}
			</>}
			<li className="menuHome movil" onClick={() => obtenerUbicacion()}>Usar la ubicación GPS de mi dispositivo</li>
			<li className="menuHome" onClick={(e) => box(0)}>Busqueda directa por nombre de distrito</li>
		  </ul>}
		  {control.buscar &&
          <div id='buscador' className="reg-content-wrapper" style={{padding:'2em', color:'#006aa7'}}>
          <i className='pi pi-times cerrar' onClick={() => box(1)}/><h1>Búsqueda de ubicación</h1><label className="float-label" htmlFor="inubicacion">Buscar distrito por nombre</label>
          <AutoComplete style={{width:'100%'}} id="inubicacion" value={ubicacionT} suggestions={ubicacionesf} completeMethod={filtroubicaciones} field="label" size={30} placeholder="Escribe el nombre de tu distrito" minLength={1} onChange={ubicacionChange}/>
		  </div>}
      </div>}
      <AppSeeker style={{top:'54px', position:'fixed', paddingTop:'8px', width:'100%', zIndex:'7'}} box = {box} expandir = {expandir} onCiudadChange={onCiudadChange} ciudades={ciudades} pais={zona.data?.Pais} departamento={departamento}  onProvinciaChange={provincias?.length!==0?onProvinciaChange:onDistritoChange} provincias={provincias?.length!==0?provincias:distritos} dpto={zona.data?.Departamento} provincia={provincias?.length!==0?provincia:distrito}/>
		{selectedCountry===''?
	  <div className="evaluar" style={{background:'#fecc00', color:'#fff', position:'relative',zIndex:'10',width:'85%', maxWidth:'400px', margin:'auto', top:'2.5em',  textAlign:'center'}}>
		<img className="escritorio" src={ruta+logo} height='47' alt={"servicontacto.com"}/>
		  <ul className="pais">
			{paisesEs}			
		  </ul>		  
	  </div>
	  :''}
	  
    </Fragment>
  );

}
import axios from "axios";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { StoreContext } from "./store/StoreProvider";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import listPlugin from '@fullcalendar/list';
import {types}  from './store/StoreReducer';
import {InfoData} from "./InfoData";


export const Calendario = (props) => {
  const {store, dispatch} = useContext(StoreContext);
  const { api, ruta, img } = store;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [modo, setModo] = useState("nuevo");
  const [eventoTitulo, setEventoTitulo] = useState("");
  const [fecha, setFecha] = useState(new Date(Date.now() - 86400000));
  const [fecha1, setFecha1] = useState(new Date(Date.now() - 86400000));
  const [fechaLocal, setFechaLocal] = useState("");
  const [fechaLocal1, setFechaLocal1] = useState("");
  const [fechaLocal2, setFechaLocal2] = useState("");
  const [fechaLocal3, setFechaLocal3] = useState("");
  const [checked, setChecked] = useState(false);
  const [hasEnd, setHasEnd] = useState(false);
  const [modoBoton, setModoBoton] = useState("");
  const [Color, setColor] = useState("#4caf50");
  const [textColor, setTextColor] = useState("#f0f4c3");
  const [id, setId] = useState("");
  const [events, setEvents] = useState([]);
  //const [comentario, setComentario] = useState("El personal en el consultorio es muy profesional y el médico fue acogedor. Recomendaría a este dermatólogo.");
  const [eventoDescripcion, setEventoDescripcion] = useState("");
  const [contacto, setContacto] = useState(props.contacto);
  const [socio] = useState(props.socio || { Contacto_ID: "-1" });
  const [usuario, setUsuario] = useState("");  
  const [vendedor, setVendedor] = useState("");    
  const [ficha, setFicha] = useState(false);  
  const fotoF = ruta + img.fotoF;
  const fotoM = ruta + img.fotoM;
  document.documentElement.style.setProperty('--altoCol', 'auto');

  const calendarRef = useRef(null)

  useEffect(() => {
    getEventos("leer", "1"); //  'leer',Usu_ID
  //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.contacto?.Contacto_ID !== contacto?.Contacto_ID) {
      setContacto(props.contacto);
 //     calendarioGlobal.removeAllEvents();
      getEventos("leer", "1");
    }
  //eslint-disable-next-line
  }, [props?.contacto?.Contacto_ID !== contacto?.Contacto_ID]);

  const getEventos = async (accion, evento) => {
    let resource = await serverEventos(accion, evento);
    console.log(resource.data);

    if (accion === "leer") {
      setEvents(resource.data);
    }

    if (accion !== "leer") {
      setEvents(resource.data);
    }
  };

  const serverEventos = async (accion, evento) => {
    var config = { headers: { "content-type": "multipart/form-data" } };

    const formData = new FormData();
    formData.append("operacion", 15);
    formData.append("Contacto_ID", props?.contacto?.Contacto_ID || "0");
    formData.append("Socio_ID", socio.Contacto_ID);
    formData.append("accion", accion);
    formData.append("Usu_ID", props?.user?.Usu_ID || "0");

    if(evento!==undefined){
      let st=evento.start; 
      let mesSt=st?.getMonth()+1;
      let end=evento.end;	
      let mesEnd=end?.getMonth()+1;
      formData.append('id', evento.id);
      formData.append('title', evento.title);
      formData.append('descripcion', evento.descripcion);
      formData.append('start', st?.getFullYear()+'-'+mesSt+'-'+st?.getDate()+' '+st?.getHours()+':'+st?.getMinutes()+':'+st?.getSeconds());
      formData.append('end', end?.getFullYear()+'-'+mesEnd+'-'+end?.getDate()+' '+end?.getHours()+':'+end?.getMinutes()+':'+end?.getSeconds());
      formData.append('allDay', evento.allDay);
      formData.append('hasEnd', evento.hasEnd);
      formData.append('color', Color /*evento.Color*/);
      formData.append('textColor', textColor /*evento.textColor*/);	}

    return await axios.post(api, formData, config);
  };

  const onFechaChange = (e) => {
    setFecha(e.value);
    if (checked) {
      if (fecha1.valueOf() <= e.value) {
        setFecha1(new Date(e.value.valueOf() + 86400000));
      }
    } else {
      if (fecha1.valueOf() <= e.value) {
        setFecha1(new Date(e.value.valueOf() + 3600000));
      }
    }
  };

  const onFecha1Change = (e) => {
    setFecha1(e.value);
    if (checked) {
      if (e.value <= fecha.valueOf()) {
        setFecha(new Date(e.value.valueOf() - 86400000));
      }
    } else {
      if (e.value <= fecha.valueOf()) {
        setFecha(new Date(e.value.valueOf() - 3600000));
      }
    }
  };

  const onChecked = (e) => {
    if (fecha.getDate() >= fecha1.getDate() - 1) {
      setChecked(e.checked);
      if (!e.checked) {
        setFecha(
          new Date(
            new Date(fecha).valueOf() + new Date().getHours() * 3600 * 1000
          )
        );
        setFecha1(
          new Date(
            new Date(fecha1).valueOf() -
              (23 - new Date().getHours()) * 3600 * 1000
          )
        );
        setHasEnd(true);
      } else {
        setFecha(new Date(fecha.setHours(0, 0, 0, 0)));
        if (fecha.getDate() === fecha1.getDate()) {
          setFecha1(new Date(fecha1.setHours(0, 0, 0, 0) + 86400000));
        } else {
          setFecha1(new Date(fecha1.toLocaleDateString()));
        }
        setHasEnd(false);
      }
    }
  };


  const MostrarDialog = (info, eventObj, modo) => {
    let fechaInicio;
    let fechaFin;
    let fechaLocal;
    let fechaLocal1;
    let fechaLocal2;
    let fechaLocal3;
    if (fecha1.valueOf() < fecha.valueOf()) {
      document.getElementsByClassName("fin p-inputtext")[0].style.backgroundColor = "white";
    }
    console.log(info); //new Date(this.state.fecha1).toString('dd/MM/yyyy')         fecha1:(new Date(fechaFin)).toString('dd/MM/yyyy')
    console.log(eventObj); //(new Date(this.state.fecha)).toString('yyyy-MM-dd HH:mm:ss')
//    setModoEditar(eventObj._def?.extendedProps?.Contacto_ID > "0" && typeof props.user !== "undefined" ? "1" : "");
	let contact=eventObj._def?.title.split(' (')[0];
	setVendedor({contacto:eventObj._def?.extendedProps, titulo:contact });
    if (eventObj === 0) {
      if (info.dateStr === undefined) {
        fechaInicio = info.start; fechaFin = info.end;
      } else {
        fechaInicio = info.dateStr; fechaFin = info.dateStr;
      }
//      document.getElementsByClassName("dialogoIcono")[0].style.display = "none";
//      document.getElementsByClassName("dialogoBoton")[0].style.visibility = "visible";
	  setFicha(false);
      setUsuario({Usu_ID:'0'});
      setModo(modo);
      setModoBoton("nuevo");
      setVisible(true);
      setFecha(fechaInicio);
      setFecha1(fechaFin);
      setEventoTitulo("");
      setEventoDescripcion("");
      setChecked(info.allDay);
      setHasEnd(!checked);
      setColor(Color);
      setTextColor(textColor);
      setId("");
    }
    if (info === 0) {
      fechaLocal = eventObj.start.toLocaleDateString("es-PE", {
        weekday: "long",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      fechaLocal1 = eventObj.end.toLocaleDateString("es-PE", {
        weekday: "long",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      fechaLocal2 = eventObj.start.toLocaleDateString("es-PE", {
        month: "long",
        day: "numeric",
      });
      fechaLocal3 = new Date(eventObj.end.valueOf() - 86400000).toLocaleDateString("es-PE", {
        month: "long",
        year: "numeric",
        day: "numeric",
      });

     // document.getElementsByClassName("dialogoIcono")[0].style.display = "inline-block";
     // document.getElementsByClassName("dialogoBoton")[0].style.visibility = "hidden";
      setFechaLocal(fechaLocal);
      setFechaLocal1(fechaLocal1);
      setFechaLocal2(fechaLocal2);
      setFechaLocal3(fechaLocal3);
	  setUsuario({Usu_ID:eventObj.extendedProps.Usu_ID});
      setModo(modo);
      setVisible(true);
      setFecha(eventObj.start);
      setFecha1(eventObj.end);
      setEventoTitulo(eventObj.title);
      setEventoDescripcion(eventObj.extendedProps.descripcion);
      setChecked(eventObj.allDay);
      setHasEnd(eventObj.extendedProps.hasEnd);
      setColor(eventObj.extendedProps.Color);
      setTextColor(eventObj._def.ui.textColor);
      setId(eventObj._def.publicId);
    }
  };

  const ActualizaEventos = (mando) => {
    if (modoBoton === "edita") {
      mando = "edita";
    }

    let Events = events; //window.EditorEmpresaForm.state.events;
    var eventoNuevo = {
      id: id,
      title: eventoTitulo,
      descripcion: eventoDescripcion,
      start: fecha,
      end: fecha1,
      allDay: checked,
      hasEnd: hasEnd,
      Color: Color,
      textColor: textColor,
    };

    if (mando === "agrega") {
      getEventos("agregar", eventoNuevo);
    }

    if (mando === "borra") {
      if (window.confirm("¿Está seguro que desea borrar el evento?")) {
  //      calendarioGlobal.getEventById(id).remove();
        getEventos("borrar", eventoNuevo);
      } else {
        mando = "error";
      }
    }

    if (mando === "edita") {
  //    calendarioGlobal.getEventById(id).remove();
  //    calendarioGlobal.addEvent(eventoNuevo);
      getEventos("editar", eventoNuevo);
    }
    //calendarioGlobal.addEventSource(window.EditorEmpresaForm.state.events);
    //calendarioGlobal.removeAllEvents();
    console.log(Events);
    console.log(eventoNuevo);
    if (mando !== "error") {
      setVisible(false);
      setEvents(Events);
      setModoBoton("nuevo");
      //this.setState({visible:false, events:Events, modoBoton:'nuevo'});
    } else {
      setModoBoton("nuevo");
    } //  this.setState({modoBoton:'nuevo'});
  };
  
  
  const verUser = async (user) => {
    setFicha(true);  
	console.log(user);
	let db = await InfoData(types.authLogin, user);
	setUsuario(db.user.perfil);
	console.log(db.user.perfil);
  }
  
  
  const comentar = (usuario) => {
	console.log(usuario);
	console.log(vendedor.contacto.Contacto_ID);	
	console.log(vendedor.titulo);
	console.log(eventoDescripcion);
	console.log(id);	
	dispatch({ type: types.contacto, payload:{editar: "", Contacto_ID:vendedor.contacto.Contacto_ID, Evento_ID:id, Evento:eventoDescripcion} });
	history.push({pathname:"/"+vendedor.titulo.split(' ').join('-')+"/puntuacion"})
	
  }
    
  

  const hoja = <div><div style={{ float:'left', width:'11em'}}><img src={usuario.Foto?ruta+usuario.Foto:usuario.Genero ==='M'?fotoM:fotoF} alt="Foto de perfil" /></div>
  <div style={{ float:'right', width:'calc(100% - 11em)', padding:'0 1em'}}><span className="p-float-label"> 
  <h5 style={{ marginBlockStart:'0.5em', marginBlockEnd:'0.5em'}}>Nombres y apellidos</h5> <span>{usuario.Nombres+" "+usuario.ApellidoPaterno+" "+usuario.ApellidoMaterno}</span>
  <h5 style={{ marginBlockStart:'1.5em', marginBlockEnd:'0.5em'}}>Teléfono celular</h5> <span>{usuario.Celular}</span>
  <h5 style={{ marginBlockStart:'1.5em', marginBlockEnd:'0.5em'}}>Correo electrónico</h5> <span>{usuario.Email }</span>  
  </span></div>
  </div>; 
  const carnet = <i className="far fa-id-card icoBoton" style={{ marginRight: '0.7em', display:usuario.Usu_ID==='0'?'none':'unset' }} onClick={e => verUser(usuario.Usu_ID)}></i>
  const footer =  <div>
  <i className="far fa-edit dialogoIcono" style={{ marginRight: '0.7em', fontSize: '2em', verticalAlign: 'sub', display:usuario.Usu_ID==='0'?'unset':'none' }} onClick={e => {setModo('nuevo'); setModoBoton('edita');           document.getElementsByClassName("dialogoBoton")[0].style.visibility = "visible"; document.getElementsByClassName("dialogoIcono")[0].style.display = "none";}}></i>
  <i className="far fa-trash-alt dialogoIcono" style={{ marginRight: '4em', fontSize: '2em', verticalAlign: 'sub', display:ficha?'none':'unset' }} onClick={e => ActualizaEventos('borra')}></i>
  <Button label="Salir" icon="far fa-window-close" className="p-button-secondary  dialogoBoton" onClick={() => setFicha(false)} style={{display:ficha?'unset':'none' }}/> 
  <Button label={usuario.Usu_ID==='0'?"Cancelar":"Salir"} icon="pi pi-times" className="p-button-secondary  dialogoBoton" onClick={() => setVisible(false)} style={{display:ficha?'none':'unset' }}/>
  <Button label="Guardar" icon="pi pi-check" className="p-button-raised  dialogoBoton" onClick={e =>  ActualizaEventos('agrega')} style={{display:usuario.Usu_ID==='0'? modo==='editar'?'none':'unset':'none' }}/>
  <Button label="Comentario" icon="pi pi-check" className="p-button-raised  dialogoBoton" onClick={e =>  comentar(usuario)} /*style={{display:usuario.Usu_ID!=='0'? modo==='editar'?'none':'unset':'unset' }}*//>
</div>; 

return (
  <div className='p-fluid panelContacto' style={{width:'100%'}}>
    <div id="calendario" style={{width:'100%',minHeight: '500px'}}>


    <FullCalendar 
                                plugins={[timeGridPlugin, dayGridPlugin,interactionPlugin, listPlugin]}
                                initialView = {props?.user?.Usu_ID ? "dayGridMonth" : "timeGridWeek"}
                                initialDate = {new Date()}
								locales={[esLocale]}  // Aquí configuras el locale en español
                                headerToolbar={{
                                    left:'prev,next today',
                                    center:'title',
                                    right:'dayGridMonth,timeGridWeek,timeGridDay,listMonth,listWeek'
                                }}
                                views={{ listWeek: { buttonText: "Agenda sem." },
                                        listMonth: { buttonText: "Agenda mes" }, month: { buttonText: "mes" } }}
                                ref={calendarRef}
                                events={events}
                                locale="es"
                                slotMinTime="07:00:00"
                                slotMaxTime="20:00:00"
                                height={700}
                                editable={props?.contacto?.Contacto_ID > "0" ? true : false}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                navLinks={true}
                                eventDrop={ function (info) {
                                  var eventoNuevo = {
                                    id: info.event._def.publicId,
									Usu_ID: info.event.Usu_ID,
                                    title: info.event.title,
                                    descripcion: info.event.extendedProps.descripcion,
                                    start: info.event.start,
                                    end: info.event.end,
                                    allDay: info.event.allDay,
                                    hasEnd: info.event.extendedProps.hasEnd,
                                    Color: info.event.extendedProps.Color,
                                    textColor: info.event._def.ui.textColor,
                                  };
                                  console.log(this);
                                  getEventos("editar", eventoNuevo);
                                }}
                                eventResize = {function (info) {
                                  var eventoNuevo = {
                                    id: info.event._def.publicId,
									Usu_ID: info.event.Usu_ID,
                                    title: info.event.title,
                                    descripcion: info.event.extendedProps.descripcion,
                                    start: info.event.start,
                                    end: info.event.end,
                                    allDay: info.event.allDay,
                                    hasEnd: info.event.extendedProps.hasEnd,
                                    Color: info.event.extendedProps.Color,
                                    textColor: info.event._def.ui.textColor,
                                  };
                                  getEventos("editar", eventoNuevo);
                                }}
                                eventClick={ function (info) {
                                  var eventObj = info.event;
                                  MostrarDialog(0, eventObj, "editar");
                                }}
                                select={function (info) {
                                /*  calendarioGlobal.addEvent({
                                    id: "temporal",
                                    title: "(Sin título)",
                                    start: info.startStr,
                                    end: info.endStr,
                                  });*/
                                  MostrarDialog(info, 0, "nuevo");} } 
                                dateClick={function (info) {
                                  //alert('Clicked on: ' + info.dateStr);
                                  //alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                                  //alert('Current view: ' + info.view.type);
                                  //cambia el color de fondo del día seleccionado
                                  // info.dayEl.style.backgroundColor = '#d6fbff6e';
                                  // MostrarDialog(info,0);
                                }} //SAME
                            />
    </div>

    <Dialog header={ficha?"Información de cliente":modo==='editar'?"Revisar evento programado":"Agregar evento y la hora"} visible={visible} style={{width: '480px'}} footer={/*modoEditar?'':*/footer} onHide={() => setVisible(false)}>
      {!ficha?<div className="p-grid dialogoFormulario">
        <div className="p-col-12 p-md-12" style={{margin:'1.5em 0'}} >  <span className="p-float-label"><span id="eventoTitulo1" style={{'display': modo === 'editar' ? 'inline-block':'none'}}>{carnet}{eventoTitulo}</span> <InputText id="eventoTitulo" style={{width:'100%', 'display': modo === 'nuevo' ? 'inline-block':'none'}} value={eventoTitulo} onChange={(e) => setEventoTitulo(e.target.value)} /> <label htmlFor="eventoTitulo">Titulo</label> </span></div> 
        <div className="p-col-12 p-md-12" style={{'display': modo === 'editar' ? 'inline-block':'none' }}> <span className="p-float-label"><span style={{'display': fecha.valueOf() === fecha1.valueOf() ? 'inline-block':'none'}}>  {fechaLocal} - {fecha1.toString()} Horas </span><span style={{'display': (fecha.toString("dd/MM/yyyy") !== fecha1.toString("dd/MM/yyyy")) && !checked ? 'inline-block':'none'}}>  {fechaLocal} - {fechaLocal1} Horas </span> <span style={{'display': ((fecha1.valueOf() - fecha.valueOf())/3600000) !==24 && checked ? 'inline-block':'none'}}>  {fechaLocal2} - {fechaLocal3} </span>  <span style={{'display': ((fecha1.valueOf() - fecha.valueOf())/3600000) ===24 && checked ? 'inline-block':'none'}}> {fechaLocal3} </span><Calendar className="calendario" id="fecha" value={fecha}  style={{'display': modo === 'nuevo' ? 'inline-block':'none'}}/> <label htmlFor="fecha">Fecha del evento</label> </span></div>
        <div className="p-col-12 p-md-3" style={{'display': modo === 'nuevo' ? 'inline-block':'none' }}> <span className="p-float-label"> <Calendar className="calendario" id="inicio" value={fecha} onChange={onFechaChange} dateFormat="dd/mm/yy" /> <label htmlFor="inicio">Inicio de evento</label> </span> </div>
        <div className="p-col-12 p-md-3" style={{'display': modo === 'nuevo' ? 'inline-block':'none' }}> <span className="p-float-label"> <Calendar className="calendario" id="hinicial" disabled={checked} value={fecha} onChange={onFechaChange} timeOnly={true} stepMinute={15} hourFormat={checked === true ? "24":"12"} /> <label htmlFor="hinicial">Hora inicial</label> </span> </div> 
        <span style={{'display': modo === 'nuevo' ? 'inline-block':'none', margin:'-3px', position: 'relative', fontWeight: 'bold', top: '1.3em'}}> - </span>  
        <div className="p-col-12 p-md-3" style={{'display': modo === 'nuevo' ? 'inline-block':'none' }}> <span className="p-float-label"> <Calendar className="calendario" id="hfinal" disabled={checked} value={fecha1} onChange={onFecha1Change} timeOnly={true} stepMinute={15} hourFormat={checked === true ? "24":"12"} /> <label htmlFor="hfinal">Hora final</label> </span> </div>
        <div className="p-col-12 p-md-3" style={{'display': modo === 'nuevo' ? 'inline-block':'none' }}> <span className="p-float-label"> <Calendar className="calendario fin" id="final" value={fecha1} onChange={onFecha1Change} dateFormat="dd/mm/yy" /> <label htmlFor="final">Final de evento</label> </span> </div>					
        <div className="p-col-12 p-md-12" style={{margin:'1.5em 0 0'}} > <span className="p-float-label"><span id="eventoDescripcion1" style={{'display': modo === 'editar' ? 'inline-block':'none', textAlign: 'justify'}}>{eventoDescripcion}</span> <InputText id="eventoDescripcion" style={{display: 'none'}} value="-" onChange={(e) => setEventoDescripcion(e.target.value)} /><InputTextarea value={eventoDescripcion} onChange={(e) => setEventoDescripcion(e.target.value)} rows={2} cols={51} autoResize={true} style={{textAlign: 'justify', 'display': modo === 'nuevo' ? 'inline-block':'none'}}></InputTextarea> <label htmlFor="eventoDescripcion">Descripción</label> </span></div>	
        <div className="p-col-12 p-md-12" style={{'display': /*modo === 'nuevo' ? 'inline-block':*/'none'}} > <Checkbox disabled checked={checked} onChange={onChecked} /><div style={{marginTop: '-1.5em', fontSize: '12px', color: '#898989',marginLeft: '2.3em'}}>Evento fijado para el día completo</div></div>
      </div>:hoja}
    </Dialog>			
  </div>
);

}

import { WebSite } from './Site';
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { useContext, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "primereact/button";
import { InfoData } from "./InfoData";
import axios from "axios";

export const Pagina = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, especialidad, api} = store;
const [especialistas, setEspecialistas] = useState([]);
const [pag404, setPag404] = useState(false);
//console.log(props.match.params.userweb);

	useEffect(() => {
	window.scrollTo(0, 0);
	Contenido();
	verifica();
  //eslint-disable-next-line
	}, []);
	
	
const verifica = () => { 
	let salida = false;
	if(contacto.banner==='' || contacto.staff==='' || contacto.Presentacion==='' || contacto.usoweb==='0'){
	salida = true;
	}
	setPag404(salida);
}
	
	
const visita = async (contacto, tipo) => {
    let db = await InfoData('visitaWeb', {contacto:contacto,tipo:tipo});  
	console.log(db.data)
}	

	
const Contenido = () => {
     if(!contacto.load||contacto.load==='porListado'){
      contactoData(props.match.params.userweb);
     }else{
	 visita(contacto.Contacto_ID, 'i');
	 Especialistas(contacto.Contacto_ID);
	 }
}
  
  
const contactoData = async (userweb) => {
//	let resource = await InfoData( types.contacto, userweb);
	let resource = await serverContacto(userweb);	
	//console.log(resource.data);
    if(!!resource.data?.Contacto_ID){
    Especialidades(resource.data?.Contacto_ID);	
    Especialistas(resource.data?.Contacto_ID);
    dispatch({ type: types.contacto, payload: {...resource.data } })
	if(!contacto.Contacto_ID){
	visita(resource.data.Contacto_ID, '');
	console.log('cargo la visita externa');
	}else{visita(contacto.Contacto_ID, 'i'); console.log('cargo la visita interna');}
    }
}


const serverContacto = async (userweb) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 5);
  formData.append('userweb', userweb);
  return await axios.post(api, formData, config);
}
	
const Especialidades = async (Contacto_ID) => {
    let cadena = await InfoData( types.especialidad, {Contacto_ID:Contacto_ID});
	cadena = cadena.data;	
    let cadena1 = cadena.pop(); 	
    dispatch({ type: types.especialidad, payload: cadena });
    dispatch({ type: types.categorias, payload: cadena1 });
}

	
const Especialistas = async (Contacto_ID) => {
	  let cadena = await InfoData('especialista', {contacto:Contacto_ID,catID:''});
	  cadena = cadena.data;
      cadena.pop(); 	
      setEspecialistas(cadena);
}

	  
const fotoIco = (caso) => {
}
	  
	  
	//--------------------------------
    // Zona de renderización final


return (
  <Fragment>
	  {!pag404?<WebSite contacto={contacto} fotoIco={fotoIco} banners={fotoIco} especialidad={especialidad}  especialistas={especialistas} exibir={true}/>:
	   <div className="pagina" style={{height:'calc(100vh - 170px)'}}>
			<div style={{top:'50%', left:'50%', position:'absolute', transform:'translate(-50%, -50%)', textAlign:'center'}}>
			<h1>Esta página no esta disponible</h1>
			<p>Es posible que el enlace este roto o que se haya eliminado</p>	
			<p>la página. Verifica que el enlace que quieres abrir es correcto</p>
			<Link to='/'><Button  className="usuBoton" label="Ir al inicio"/></Link>
			</div>
	  </div>}
	</Fragment>  
	  );
}



import { useContext, useState, /*useEffect,*/ Fragment } from 'react';
import { StoreContext } from './store/StoreProvider.js';
import { useHistory } from 'react-router-dom';
import { types }  from './store/StoreReducer';
import { Agenda } from './Agenda';
import classNames from 'classnames';
import { InfoData } from "./InfoData";
import { useHorario } from './hooks/useHorario';
import { Slideshow, Slide } from './Slideshow';
import styled from "styled-components";
import Barras from './components/loaders/Barras';

export const Site = (props) => {
const {store} = useContext(StoreContext);
const {contacto, especialidad} = store;

const fotoIco = (caso) => {
  console.log(caso);  
}
 
return (
  <div className="dashboard">
    <div className="card contactoN">
      <div className="layout-Seeker" style={{marginTop: '5px'}}>
      <WebSite contacto={contacto} fotoIco={fotoIco} router='no' especialidad={especialidad} />
      </div>
    </div>    
  </div>    
  );

	
}


const Presentasocio = (props) => {
/*Componente que muestra la foto carnet y el texto de presentación de cada asociado*/
const {store} = useContext(StoreContext);
const {ruta} = store;
const [mostrarCompleto, setMostrarCompleto] = useState( false );
const texto = props.socio.Presentacion?.split('</p>')[0];
	const textoBreve = texto.slice(" ", 225) + ' ...</p>';
	let nombre = <h2 style={{color:props.tema}}> {props.socio.Nombre_Comercial} </h2>;
	let html = <><span className="presentacion" id={props.socio.item} dangerouslySetInnerHTML={{ __html: mostrarCompleto ? props.socio.Presentacion : textoBreve }}></span>
	<p id="masp" className="mas" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setMostrarCompleto(!mostrarCompleto)} >
			{mostrarCompleto ? "Leer menos" : "Leer más"}
        </p></>;
return (
       <div style={{display:'flex', flexWrap:'wrap', margin:'0px -22px 20px 0'}}>
			<div className="col-foto">
			<img alt="imagen ancho" src={ruta+props.socio.Foto} style={{width:'90%', borderRadius:'0!important'}}/>
			</div>
			<div className="col-dato movilGrande" style={{padding:'0 10px'}}> 
			{nombre}<p className='text-espec'>{html}</p>
			</div>
			<div className="movilMini" style={{padding:'10px 0'}}>
			{nombre} <p className='text-espec1'>{html}</p>
			</div>
		</div>
		);
}


export const WebSite = (props) => {

const {store, dispatch } = useContext(StoreContext);
const {contacto, especialidad, ruta, img, fecha, fechal, disponibilidad} = store;
const horarioData = useHorario(contacto); // Usamos el hook
const history = useHistory();
const [socio, setSocio] = useState([]);
const [especialista, setEspecialista] = useState([]);
const [area, setArea] = useState([]);
const [clic, setClic] = useState(false);
const [estado, setEstado] = useState('home');
const tiktok = ruta+img.tiktok;
const calendar = ruta+img.calendar;
const instagram = ruta+img.instagram;
var futuro=false;
const [mostrarCompleto, setMostrarCompleto] = useState(window.innerWidth  > 800 ? true : false );
	 
	
 const Fecha = (e, largo) => {
	futuro = (new Date(e?.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
	let hasta = 'HASTA '+ (new Date(e?.cerradohasta)).toLocaleDateString("es-PE",{weekday: largo?"long":"short", day: "numeric"}).toUpperCase()
	let fechaPanel = futuro?hasta:largo?fechal:fecha;
	return fechaPanel.toLowerCase();
	}


 const salto = (id) => { 
	if(document.getElementById(id)!==null){
	window.scrollTo({top: document.getElementById(id).offsetTop, behavior:'smooth'});}
	setClic(false);
    }
	
	
 const salir = () => {
  document.getElementById("subMenu").style.visibility='hidden'
  document.getElementById("subMenu1").style.visibility='hidden' 
  document.getElementById("subMenu2").style.visibility='hidden' 
}


 const home = () => {
  dispatch({ type: types.contacto, payload: {...contacto, booking:''} });
	setClic(false);
	setEstado('home');
}
 
 
 const socios = (socio) => {
  setSocio(socio);
  console.log(socio);
  setClic(false);
  setEstado('socio');
  window.scrollTo({top: 0, behavior:'smooth'});
} 


 const areas = (categoria) => {
  setArea(categoria);
  setEstado('area');
  setEspecialista([]);
  setClic(false);   
  Especialistas(contacto.Contacto_ID, categoria.Arbol_ID)
  console.log(categoria);
//  window.scrollTo({top: 0, behavior:'smooth'});
} 

 const agendado = () => {
 setEstado('agenda');
 setClic(false);  
} 


 const link = (opt) => {
 if(opt==='1'){
 	history.push({pathname:'/contacto/'+contacto.Nombre_Comercial?.split(' ').join('-') +'/'+ contacto.userweb})
} 
 if(opt==='2'){
 	history.push({pathname:'/feedback/'+ contacto.userweb})
}
} 


const acercaDe = (texto) => {
	const textoBreve = texto?.split('</p>')[0] + '</p>';
return (
		<><span className="presentacion" id="breve" dangerouslySetInnerHTML={{ __html: mostrarCompleto ? texto : textoBreve }}></span>
		<p id="masp" className="mas" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setMostrarCompleto(!mostrarCompleto)} >
			{mostrarCompleto ? "Leer menos" : "Leer más"}
        </p></>
		);
}

	
const Especialistas = async (Contacto_ID,catID) => {
    let cadena = await InfoData('especialista', {contacto:Contacto_ID,catID:catID});
	cadena = cadena.data;
	cadena.pop(); 	
 	setEspecialista({...especialista, socio:cadena});
	console.log(cadena.length);
  } 
  
  
const carnet = (Socio,tema) => {
return ( <Presentasocio socio={Socio} tema={tema} /> );
}


const icons = 7-(0+(contacto.Tiktok?1:0)+(contacto.Instagram?1:0)+(contacto.Facebook?1:0)+(contacto.Twitter?1:0)+(contacto.Youtube?1:0)+(contacto.celular?1:0));
const anchoImg = String(icons<2?(11.5+2.5*icons):icons<4?(6.5+5*icons):21.5)+'vw';
const anchoIco = String(icons<2?(12.5+3*icons):icons<4?(7.5+icons*5.5):24)+'vw';
const dia = <span  className="calendario" style={{ top:'27px', left:'20px', fontSize:'20px'}} onClick={e => dispatch({ type: types.contacto, payload: {...contacto, booking:contacto.booking?'':'agenda'} })}>{new Date().toLocaleDateString("es-PE", {day: "numeric" })}</span>;
const mes = <span className="calendario" style={{top:'10px', left:'20px', fontSize:'7px'}}>{new Date().toLocaleDateString("es-PE", {month: "long" })}</span>;
const calen = <div style={{float:'left', position:'relative', display:'flex'}}>{mes}{dia}<img onClick={ e => {dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} }); agendado();}} className="icoBoton" alt='' src={calendar} style={{margin:'0', width:'40px'}}/></div>
const TT = contacto.Tiktok?<a href={contacto.Tiktok} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={tiktok} style={{ width: anchoImg }}/></a>:'';
const IG = contacto.Instagram?<a href={contacto.Instagram} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={instagram} style={{ width: anchoImg }}/></a>:'';
const FB = contacto.Facebook?<a href={contacto.Facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-square icoPag" style={{fontSize: anchoIco, margin:'0 7px 0 0'}}></i></a>:'';
const TW = contacto.Twitter?<a href={contacto.Twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter icoPag" style={{color: '#1d9bf0', fontSize: anchoIco}}></i></a>:'';	
const YT = contacto.Youtube?<a href={contacto.Youtube} target="_blank" rel="noreferrer"><i className="fab fa-youtube icoPag redes" style={{color: '#f70000', fontSize: 'calc('+anchoIco+' - 5vw)', padding:'7% 0'}}></i></a>:'';
const WA = contacto.celular?<a href={'https://wa.me/'+contacto.DDI+contacto.celular} target="_blank" rel="noreferrer"><i className="fab fa-whatsapp-square icoPag" style={{color: '#44e561', fontSize: anchoIco }}></i></a>:'';

 const notaM = disponibilidad[contacto.nodisponible]?.usoM;
 const nota = disponibilidad[contacto.nodisponible]?.uso; 
 const tema = contacto.color?contacto.color:contacto.tipo==='4'?'#f6d8cc':contacto.tipo==='3'?'#006aa7':'#00adef';
 const dispon  = contacto.nodisponible!=='0'?<div className="letrero" style={{float:'right', color:contacto.tipo==='2'?'#fff':tema}}><span className="escritorio" >{nota}</span><span className="movil" >{notaM}</span><span>{Fecha(contacto)}</span></div>:'';
 const online  = <div className="letrero"><i className="pi pi-video" style={{margin:'4px 12px'}}></i><span>Consultas on-line</span></div>
 const agendar = <div className="letrero" style={{background:tema, padding:'0', textAlign:'center'}} onClick={ e => {dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} }); agendado();}}>{calen}<span>Agendar una cita</span></div>
 const whatsapp= <a href={'https://wa.me/'+contacto.DDI+contacto.celular} target="_blank" rel="noreferrer"><div className="letrero" style={{float:'right', display:'flex', color:contacto.tipo==='2'?'#fff':'#44e561'}}><i className="fab fa-whatsapp" style={{ fontSize:'30px', margin:'6px', float:'left', background:'#44e561', padding:'4px 7px', borderRadius:'7px', color:'#fff', fontWeight:'lighter', boxShadow:'0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)'}}></i><span style={{width:'100%'}}>Contacto directo</span></div></a>	
 const gratis  = <div className="letrero" style={{float:'right', background:tema, padding:'0', textAlign:'center'}}> <span >{'Consulta gratuita '}</span><span className="escritorio" style={{lineHeight:'1', display:'block'}}> {contacto.telefono?contacto.telefono:contacto.celular}</span></div>
 const redes = <Redes> {FB}{WA}{IG}{TT}{YT}{TW} </Redes>	

 const booking = <Agenda contacto={props?.contacto} socio={props?.socio}/>;
 //(Icono de agenda aun no lo uso en la pagina)  const agenda = <i className="pi pi-calendar icoBoton" onClick={ e => {dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} }); /*window.scrollTo(0, document.getElementById('staffUnico').offsetTop - 50)*/ }} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto','display':/*props.preview === 'ver'*/true?'block':'none' }}></i>;
 const submenu =  especialidad?.map(lista  => <li key={lista.Arbol_ID} onClick={()=>areas(lista)} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}} >{lista.label}</li>);
 const submenu1 =  props?.especialistas?.map(lista  => <li key={lista.item} onClick={()=>socios(lista)} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}} >{lista.Nombre_Comercial}</li>);
 const submenu2 = <ul id='subMenu2' style={{width:'10.5em'}}>
                    <li onClick={(e) => salto('contacto')} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Contacto</li>
                    <li onClick={(e) => salto('horario')} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Horario de atención</li>
					<li onClick={(e) => link('1')} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Perfil Servicontacto</li>
					<li onClick={(e) => link('2')} onMouseOut={()=>salir()}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Reputación comercial</li>
                    <li onMouseOut={()=>salir()} onClick={ e => {dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} }); agendado(); salir();}}  style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Agendar una cita</li>
					{contacto.web?<li onClick={(e) => salto('ubicacion')} style={{padding:'0.4em 0.5em 0.4em 1em',width:'100%', fontSize:'14px'}}>Ubicación</li>:''}</ul>;
 const listali = <><li onClick={()=>home()}>Inicio</li>
        <li onMouseOut={()=>salir()} onMouseOver={()=>document.getElementById("subMenu1").style.visibility='visible'}><span onClick={(e) => salto('textoPag')}>{"Perfil de " + contacto.Nombre_Padre}</span><ul id='subMenu1'>{submenu1}</ul></li>
        <li onMouseOut={()=>salir()} onMouseOver={()=>document.getElementById("subMenu").style.visibility='visible'}><span onClick={(e) => {home(); salto('especialidades');}}>Áreas de práctica</span><ul id='subMenu'>{submenu}</ul></li>
        <li onMouseOut={()=>salir()} onMouseOver={()=>document.getElementById("subMenu2").style.visibility='visible'}>Contáctanos{submenu2}</li>
        {contacto.web?<li><a href={contacto.web} target="_blank" rel="noreferrer" style={{color:'#fff'}}>{contacto.abueloID==='1'?'Web alterna':'Tienda'}</a></li>:<li onClick={(e) => salto('ubicacion')}>Ubicación</li>}</>;
 const listaMenu=<ul className={props.exibir?"menu":"menu mini"} style={{margin:'0',float:'right', position:'relative'}}>
        {listali}</ul>;
		
 const listaliNueva = <><li onClick={()=>{home(); setClic(!clic);}}>Inicio</li>
        <li onClick={()=>setClic(!clic)} >{"Perfil de " + contacto.Nombre_Padre} </li>
        <li onClick={()=>setClic(!clic)} ><span onClick={(e) => {home(); salto('especialidades');}}>Áreas de práctica</span> </li>
        <li onClick={()=>setClic(!clic)} >Contáctanos{submenu2} </li>
        {contacto.web?<li onClick={()=>setClic(!clic)}><a href={contacto.web} target="_blank" rel="noreferrer" style={{color:'#fff'}}>{contacto.abueloID==='1'?'Web alterna':'Tienda'}</a></li>:<li onClick={(e) => salto('ubicacion')}>Ubicación</li>}</>;
 const listaNueva= <ul>{listaliNueva}</ul>;		
 const className = classNames('dashboard', {'panelContacto': props.router ==='no'}, props.className);
 const personal = classNames({'staffGrupo': contacto.staffWidth === 1000 || contacto.tipo < 3, 'staffUnico': contacto.tipo >= 3});
 
 const titulo = contacto.tipo >=3? contacto.Nombre_Cat_Solo?contacto.Nombre_Cat_Solo:(contacto.Nombre_Padre_solo?contacto.Nombre_Padre_solo+' '+<span style={{textTransform:'lowercase'}} >contacto.Nombre_Cat</span>:contacto.Nombre_Cat):
         contacto.Nombre_Padre_Grupo+' '+<span style={{textTransform:'lowercase'}} >contacto.Nombre_Cat</span>;

 const ancho = window.innerWidth>'800'?(especialidad.length ===1?'49%':especialidad.length ===2?'49%':especialidad.length ===3?'32%':especialidad.length ===6?'32%':'24%'):(especialidad.length ===1?'99%':especialidad.length ===2?'49%':especialidad.length ===3?'32%':especialidad.length ===6?'32%':'24%');
 const botonEspecialidad = especialidad?.map(lista  =>	<div className="p-col-3 especial"  onClick={()=>areas(lista)} key={lista.Arbol_ID} style={{background:tema, width:ancho, fontSize:window.innerWidth>'800'?'inherit':especialidad.length ===1?'16px':especialidad.length ===2?'11px':especialidad.length ===3?'11px':'8px'}}><i className={lista.icon} style={{fontSize:especialidad.length <3?'50px':'25px' }}></i><br/>{lista.label}</div>);
 const relleno = <li onClick={(e) => salto('textoPag')} style={{borderRight:'1px solid #fefefe', width:'calc('+((6-especialidad.length)*(props.exibir?1:0.575))+' * 96vw / 6)'}}><i className='fa fa-id-card'></i><br/>{props.contacto.Nombre_Padre_solo+' '+props.contacto.Nombre_Cat +" en el distrito de "+props.contacto.Nombre_Distrito+" en la ciudad de "+props.contacto.Nombre_Departamento}</li>
 const mas = 	 <li onClick={(e) => salto('especialidades')} style={{overflow:'inherit', borderRight:'1px solid #fefefe'/*, fontSize:'11px', width:'33.3vw'*/}}><i className='fa fa-plus'></i><br/>más especialidades</li>
 const barraEspecialidad = especialidad?.map(lista  =>	<li style={{display:((lista.item<7 && especialidad.length<7) || lista.item<5)?'block':'none', borderRight:lista.item===0?'1px solid #fefefe':''}} onClick={()=>areas(lista)} key={lista.Arbol_ID}><i className={lista.icon}></i><br/>{lista.label}</li>);
 const barraEspecialidad1 = especialidad?.map(lista  =>	<li style={{display:((lista.item<4 && especialidad.length<4) || lista.item<2)?'block':'none', fontSize:'calc(20px - '+((especialidad.length<4?especialidad.length*especialidad.length:9) +(lista.label.length>24?especialidad.length<2?7:0:0))+'px)', fontWeight:'400', width:'calc(100vw / '+(especialidad.length<4?especialidad.length:3)+')'}} onClick={()=>areas(lista)} key={lista.Arbol_ID}><i className={lista.icon}></i><br/>{lista.label}</li>);
 const t1 = contacto.Nombre_Comercial?.length;
 
 return (
<Fragment>
{props.editBoton?<div style={{position:'relative', width:'100%', textAlign:'end'}}>{props.editBoton}</div>:''}
 <div className={className} style={{minHeight:window.innerWidth>'800'?'calc(100vh - 45px)':'calc(100vh - 77px)'}}>
  {props.cabecera}
  {!contacto.Contacto_ID?<Barras/>:
  <div className="pagina" style={{position:'relative'}}>
  
     <div className="headPag" style={{background:contacto.tipo==='2'?tema:'#fff', color:contacto.tipo==='2'?'#fff':'#999'/*, height:document.getElementById('logoEmp')?.clientHeight+20+'px'*/}}>
		<div className="ancho" style={{margin:'auto', width:props.exibir?'90%':'100%', display:'flex'}} >
		   <div className="logoPag" style={{width:contacto.tipo >='3'?'auto':'auto', zIndex:'12'}} onClick={()=>home()}>
				{contacto.tipo>2?
			   <div style={{padding:t1<20?'0.7em 0':'1em 0', whiteSpace:'nowrap'}}> 
					<h1 className='SinLogo' style={{fontSize:window.innerWidth>'800'?'':t1>32?'18px':t1<20?'30px':'22px'}}>{contacto.Nombre_Comercial}</h1> <i className='far fa-star oculto' style={{marginRight:'5px', color:'#3b93ef', cursor:'pointer'}}/>
					<span style={{fontSize:'19px', textTransform:'uppercase'}}>{titulo}</span>
			   </div>:
			   <div	style={{padding:'15px 0 15px', overflowX:'hidden'}}>
				   <div	style={{width:'100%', overflowX:'hidden'}}>			   
				   <img id="logoEmp" className="logoContacto1" src={contacto.logo?ruta+contacto.logo:props.router?(contacto.tipo==='1'?ruta+img.logoC:ruta+img.logoB):''} alt="logo" style={{/*width:'auto', maxHeight:'120px', maxWidth:'220px', position:'absolute', top:'50%', transform:'translate(0, -50%)'*/}}/>
				   <img src={contacto.logo?ruta+contacto.logo:props.router?(contacto.tipo==='1'?ruta+img.logoC:ruta+img.logoB):''} alt="logo" style={{width:'auto', maxHeight:'120px', maxWidth:'440px', visibility:'hidden'}}/>
				   </div>
			   </div>
			   }
		   </div>
		   <div className="escritorio" style={{width:'61%', height:contacto.tipo >='3'?'135px':'120px'}}>
		   </div>		   
		   <div className="callBar escritorio" onClick={()=>futuro = false}>
				<div className="llamadaPag">{props.colorWeb}
					{contacto.mensajeweb==='0'?'':contacto.mensajeweb==='1'?dispon:contacto.mensajeweb==='2'?agendar:contacto.mensajeweb==='3'?whatsapp:contacto.mensajeweb==='4'?gratis:contacto.mensajeweb==='5'?redes:contacto.mensajeweb==='6'?online:''}
				</div>
			</div>
		</div>
	 </div>
	{window.innerWidth>'800'?	
	<div className="barra escritorio" style={{background:contacto.tipo==='2'?'transparent':tema, position:contacto.tipo==='2'?'absolute':'relative', color:contacto.tipo==='2'?'#d7d7d7':'#d7d7d7', zIndex:'11', marginTop:'-48px'}}>{listaMenu}</div>
	:<div className="menuMini movil  " style={{background:tema, width:'100vw', height:'50px', color:'#fff', display:'flex', flexWrap:'nowrap', position:'relative', top:'-1px'}}>
		<div className=" movil500menos" style={{width:'calc(100% - 42px)', height:'100%', float:'left', textAlign:'center'}}>
			<div style={{display:'inline-block'}}>{contacto.mensajeweb==='0'?'':contacto.mensajeweb==='1'?dispon:contacto.mensajeweb==='2'?agendar:contacto.mensajeweb==='3'?whatsapp:contacto.mensajeweb==='4'?gratis:contacto.mensajeweb==='5'?'':contacto.mensajeweb==='6'?online:''}</div>
		</div>		
		{/*<button className="p-link layout-menu-button movil500menos" type="button" onClick={()=>setClic(!clic)/*()=>{document.getElementById("menuMovil").classList.toggle('oculto')}*/ /*} style={{float:'right', padding:'8px 12px 8px 0', fontSize:'30px'}}>
		  <span className="pi pi-bars" style={{color:'#fff', verticalAlign:'text-bottom', fontWeight:'bold'}}/>
		</button>*/}
		<Burger1><div className={`icon nav-icon-5 ${clic ? 'open' : ''}`} onClick={()=>setClic(!clic)}><span></span><span></span><span></span></div></Burger1>
		<Minibar >{listaMenu}
		</Minibar>			
	</div>}
	<Navbar className=" oculto" style={{background:tema}}>
		<div className=" movil500menos" style={{width:'calc(100% - 60px)', height:'100%', float:'left', textAlign:'center'}}>
			<div style={{display:'inline-block'}}>{contacto.mensajeweb==='0'?'':contacto.mensajeweb==='1'?dispon:contacto.mensajeweb==='2'?agendar:contacto.mensajeweb==='3'?whatsapp:contacto.mensajeweb==='4'?gratis:contacto.mensajeweb==='5'?'':contacto.mensajeweb==='6'?online:''}</div>
		</div>
		<div className={`links ${clic ? 'active' : ''}`}>
		{listaNueva}
		</div>
		<BgDiv className={`initial ${clic ? 'active' : ''}`}></BgDiv>
		<Burger onClick={()=>setClic(!clic)}><div></div></Burger>
	</Navbar>
	
	
	
	
	{estado==='home'?
	<>
	<div id='Banner'>
	{props.exibir?(document.getElementById("textoPag")?.style.display!=='none'?
	<Slideshow >
			<Slide>
				<img src={contacto.banner?ruta+contacto.banner:props.exibir?'':ruta+img.banner} alt='Portada principal'/>
			</Slide>{contacto.banner1?
			<Slide>
				<img src={contacto.banner1?ruta+contacto.banner1:props.exibir?'':ruta+img.banner} alt='Portada secundaria'/>
			</Slide>:''}
			{contacto.banner2?
			<Slide>
				<img src={contacto.banner2?ruta+contacto.banner2:props.exibir?'':ruta+img.banner} alt='Portada tercearia'/>
			</Slide>:''}
			{contacto.banner3?
			<Slide>
				<img src={contacto.banner3?ruta+contacto.banner3:props.exibir?'':ruta+img.banner} alt='Portada cuarta'/>
			</Slide>:''}			
	</Slideshow>:''):
	<div style={{background:'#fff', position:'relative', display:'flex'}}>
	{contacto?.fondo==='1'?
		<><img src={contacto.banner?ruta+contacto.banner:props.exibir?'':ruta+img.banner} alt="banner" width='100%' />{props.banners()}{props.fotoIco(1)} </>:
	 contacto?.fondo==='2'?	
		<><img src={contacto.banner1?ruta+contacto.banner1:props.exibir?'':ruta+img.banner} alt="banner1" width='100%' />{props.banners()}{props.fotoIco(3)}</>:
	 contacto?.fondo==='3'?		
		<><img src={contacto.banner2?ruta+contacto.banner2:props.exibir?'':ruta+img.banner} alt="banner2" width='100%' />{props.banners()}{props.fotoIco(4)}</>:
	 contacto?.fondo==='4'?		
		<><img src={contacto.banner3?ruta+contacto.banner3:props.exibir?'':ruta+img.banner} alt="banner3" width='100%' />{props.banners()}{props.fotoIco(5)}</>:
		<><img src={contacto.banner?ruta+contacto.banner:props.exibir?'':ruta+img.banner} alt="banner" width='100%' />{props.banners()}{props.fotoIco(1)} </>
	}	
	</div>}
	</div>
	<div id="iconosWeb" className={props.exibir?"iconos escritorio":"iconos mini escritorio"} style={{background:tema, color:'#0202027a', textShadow:'3px 3px 10px #000000e6, -1px 0px 1px #fff'}}>
		<ul className={props.exibir?"menuIco":"menuIco mini"}>{especialidad.length<5?relleno:''}{especialidad.length>6?mas:''}{barraEspecialidad}</ul>
	</div>
	<div id="iconosWeb1" className="iconos movil" style={{background:tema, color:'#fff'}}>
		<ul className="menuIco">{especialidad.length>3?mas:''}{barraEspecialidad1}</ul>
	</div>
	{contacto.mensajeweb==='5'?
	<div className="movil" style={{padding:'0 0 12px', background:'#fff'}}>
		{redes}
	</div>:''}
	</>:
	''}


    <div className={props.exibir?"margen max":"margen"}>
	
       {estado==='agenda' ? <><div className="texto movil500mas" style={{padding:'0'}}></div><Booking>{booking}</Booking></> : 
	    estado==='socio'?
       <div className="texto" id="socio" /*style={{'display':'none'}}*/>
         <h1 style={{color:tema}}> {socio.Nombre_Comercial} </h1>
         <div style={{margin:'auto', textAlign:'justify'}}>
           <div className={socio?.usoweb!=='0'?'staffUnico':''}>
		   {socio.staff?			   
				<img id='staff' src={socio.staff?ruta+socio.staff:props.exibir?'':(socio.tipo==='3'?ruta + img.perfil_M:ruta + img.perfil_F)} alt="staff" width='100%'/>
			:
			<div style={{marginTop:'2em', textAlign:'center'}}>
				<div  >
					<img alt="imagen" src={ruta+socio.Foto} style={{borderRadius:'0!important'}}/>
				</div>
				<div   style={{padding:'1em'}}>				
					<h2>{socio.Nombre_Cat_Solo ? (socio.categorias > 1 ? socio.Nombre_Padre_solo + ' ' + socio.Nombre_Cat?.toLowerCase() : socio.Nombre_Cat_Solo):(socio.Nombre_Padre_solo!==undefined?socio.Nombre_Padre_solo + ' ' + socio.Nombre_Cat?.toLowerCase():'')}</h2>
				</div>
			</div>}	
			</div>
           <div className={socio?.usoweb!=='0'?'staffUnico':''}>
			{acercaDe(socio.Presentacion) /* Es el texto que aparece para describir a uno de los socios del contacto*/}				
           </div>			
         </div> 
       </div>:
	   estado==='area'?	   
	   <div className="texto" id="areas">
         <h1 style={{color:tema}}> {area.label} </h1>
         <div style={{ margin:'2em 0 0 0',textAlign:'justify'}}>
			{acercaDe(area.Descripcion) /* Es el texto que aparece para describir el area o la especialidad de un contacto*/}			
		 {especialista?.socio?.length?<h1 style={{color:tema, marginBottom:'10px'}}>{especialista?.socio?.length>1?'Especialistas':'Especialista'}</h1>:''}
		 {especialista?.socio?.map(fila  =>  <Fragment key={fila.item}>{carnet(fila, tema)}</Fragment>)} 
         </div>
       </div>:
	   estado==='home'?
	   <>
       <div className="texto" id="textoPag" style={{'display':'flow-root'}}>
         <h1 style={{color:tema}}> {contacto.Nombre_Comercial} </h1>
         {props.editorweb}
         <div style={{margin:'auto', textAlign:'justify'}}>
           <div className={personal} style={{float:'right'}}>
			{acercaDe(contacto.Presentacion) /* Es el texto que aparece para describir al contacto en su sitio web*/}
           </div>	
           <div className={personal} style={{float:contacto.tipo>2?'right':'none', position:'relative', zIndex:props.router?'0':'-1'}}>
           <img id='staff' src={contacto.staff?ruta+contacto.staff:props.exibir?'':contacto.tipo==='3'?ruta + img.perfil_M:contacto.tipo==='4'?ruta + img.perfil_F:ruta + img.staff} alt="staff" width='100%'/>{props.fotoIco(2)}
           </div>		   
         </div>
       </div>
       <div className="texto" id="especialidades" style={{clear:'both'}}>
	   <h1 style={{marginBottom:'1em'}}>Especialidades</h1>
			<div className='p-grid'>{botonEspecialidad}
			</div>
	   </div>
	   </>:''}
	   
	   
	   
       <Ubicacion className="ubicación">
         <div className="panelfolleto" style={{display:'inline-block',width:'100%'}}>
           <div className="datosPagina">
             <h2 className="subtitulo" id="contacto">Contacto</h2>  
             <i className="fa fa-users" style={{color: '#1da1f2'}}></i><span style={{marginLeft: '8px'}}>Redes sociales</span> <br/>	
			 <Redespag>{redes}</Redespag>
			  <div style={{clear:'both'}}> </div>
             <i className="fas fa-map-marker-alt" style={{color: '#18b32b'}}></i><span style={{marginLeft: '8px'}}>Dirección</span> <br/>
             <p>{contacto.direccion}</p>
             <p>{"Distrito de "+contacto.Nombre_Distrito+", "+contacto.Nombre_Provincia+ " - "+contacto.Nombre_Departamento}</p>
             <i className="fas fa-phone-alt" style={{color: '#3F51B5'}}></i><span style={{marginLeft: '8px'}}>Teléfono</span><br/>
             <p>{(contacto.telefono?"Fijo: ":'')+contacto.telefono +(contacto.telefono?" - ":'')+(contacto.telefono?"Móvil: ":'')+contacto.celular}</p>
             <i className="far fa-envelope" onClick={(e)=> {alert('carta');}} style={{color: '#3F51B5'}}></i><span style={{marginLeft: '8px'}}>Correo electrónico</span><br/>
             <p>{contacto.email}</p>
			 
             <h2 className="subtitulo" id="horario">Horario de atención</h2>
			 <Horario>
				<div className="horario-list">
					{horarioData.periodos.map((periodo, index) => (
						<Fragment key={index}>
							<span>{periodo.dia}</span>
							<span>{periodo.horario}</span>
						</Fragment>
					))}
				</div>
			 </Horario>

             </div> 
             <div className="mapa" id="ubicacion" style={{width: '50%', float:'right', padding: '1.5em'}}>
             <iframe src={contacto.Gmaps} title="mapa" width="100%" height="490" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
           </div>
         </div>
       </Ubicacion>

       <Navmenu>
		   <div className={`links ${clic ? 'active' : ''}`}>
		   <ul>{listali}</ul>
		   </div>		
       </Navmenu>       

     </div>	
   </div>}
 </div>
 
 {props.preview==='no'?
<div className="movil p-fluid reg-content-wrapper" style={{padding:'1em', background:'#fff'}}>
	<div className="form-container edit-box">
		<fieldset>			
		{props.editPerfil}
		</fieldset>
	</div>
</div>		
:''}
 
</Fragment>
 );
	
}


const Ubicacion = styled.div`
@media screen and (max-width:800px)
{ margin-bottom:2em; }
@media screen and (min-width:800px)
{ margin-bottom:4em; }
` 

const Redes = styled.div`
display:flex;
@media screen and (max-width:800px)
{ padding:10px; }
@media screen and (min-width:800px)
{ padding:10px 0 0 10px; }
`


const Booking = styled.div`
@media screen and (max-width:800px)
{ padding:0; }
@media screen and (min-width:800px)
{ padding:2.5em; }
`


const Redespag = styled.div`
@media screen and (max-width:800px)
{ margin:0 -12px; }
@media screen and (min-width:800px)
{ margin:0 10px; }
`

const Horario = styled.div`
height:6em;
@media screen and (max-width:800px)
{ margin:0 0 4em 0; }
@media screen and (min-width:800px)
{ margin:0px; }
`
/*
const H1nombre = styled.h1`
height:6em;
@media screen and (max-width:800px)
{ font-size:t1>32?18px:t1<20?30px:22px; }
@media screen and (min-width:800px)
{ font-size:22px; }
` */

const Minibar = styled.div`
width:100%;
li ul{display:grid;  }
@media(max-width:600px)
{display:none; }
`

const Navmenu = styled.nav`
background: #424242d1;
li{cursor: pointer; color: #ffffff; line-height: 1.5; font-family: 'Lato',Helvetica,Arial,Lucida,sans-serif; font-size: 18px; padding: 0.2em 1em;}
@media(min-width:600px)
{display:none; }
.links{
position:absolute;
top: -1500px;
left:-2000px;
/*right: 0;*/
margin-left: auto;
margin-right: auto;
text-align: center;
transition:all .5s ease;
@media(min-width:600px)
{ 
	position:initial;
	margin:0;
	ul{display:flex;}	
	li{	font-size:1rem;	display: inline-block;}
	li ul{ position: relative!important;}
  }
@media(max-width:600px)
{ 
	ul{text-align: left; left: 20%; }
	li{ /*display: inline-block;*/  }	
	li ul{ left: 0%; }
  }  
}
.links.active{
background: #424242d1;
position: absolute;
z-index: 1000;
width: 90%;
left: 5%;
top: 190px;
height: auto;
border-radius: 0 0 20% 2%;
li ul{background:none!important;}
}
`

const Navbar = styled.nav`
width:100vw; 
height:50px; 
top:-1px;
color:#fff; 
display:flex;
align-items: center;
justify-content: space-between;
flex-wrap:nowrap;
position:relative;
z-index:10;
ul{display:block; margin:0; position: relative; }   
li{margin-right: 1rem; font-weight:bold; font-size:1.1rem; line-height:1.8; position: relative;}
li ul{ display: grid!important; position: absolute!important;}
.links{
position:absolute;
top: -700px;
left:-2000px;
right: 0;
margin-left: auto;
margin-right: auto;
text-align: center;
transition:all .5s ease;
@media(min-width:600px)
{ 
	position:initial;
	margin:0;
	ul{display:flex;}	
	li{	font-size:1rem;	display: inline-block;}
	li ul{ position: relative!important;}
  }
@media(max-width:600px)
{ 
	ul{text-align: left; left: 20%; }
	li{ /*display: inline-block;*/  }	
	li ul{ left: 0%; }
  }  
}
.links.active{
	width:100%;
	display:block;
	position:absolute;
	margin-left:auto;
	margin-right:auto;
	top:30%;
	left:0;
	right:0;
	text-align:center;
	ul{display:block;}

}
@media screen and (max-width:800px)
{   }
@media screen and (min-width:800px)
{   }
`

const BgDiv = styled.div`
	position: absolute;
	background: #333333ba;
	top:-700px;
	left:-1000px;
	width:100%;	
	height:100%;	
	z-index:-1;
	transition:all .6s ease;
	&.active{
	top:0;
	left:15%;	
	width:70%;	
	height:31em;
	border-radius: 0 0 40% 5%;
	}
	@media(min-width:600px)
	{ 
	display:none;
	}
`

const Burger = styled.div`
  float:right;
  top: 50%;
  right: 0%;
  transform: translate(-15%, -50%);
  position: absolute;
  width: 45px;
&:before,&:after,div {
  background: #fff;
  content: "";
  display: block;
  height: 5px;
  border-radius: 3px;
  margin: 8px 0;
  transition: 0.5s;
}
open{
*&:before {
  transform: translateY(12px) rotate(135deg);
}
*&:after {
  transform: translateY(-12px) rotate(-135deg);
}
div { transform: scale(0);}
}
/*&:hover:before {
  transform: translateY(12px) rotate(135deg);
}
&:hover:after {
  transform: translateY(-12px) rotate(-135deg);
}
&:hover div {
  transform: scale(0);
}*/
@media screen and (min-width:600px)
{ display:none; }
`


const Burger1 = styled.div`
.nav-icon-5{
width:35px;
height:30px;
margin: 10px 10px;
position:relative;
cursor: pointer;
display:inline-block;
}
.nav-icon-5 span{
background:#fff;
position:absolute;
border-radius: 2px;
transition: .3s cubic-bezier(.8, .5, .2, 1.4);
width:100%;
height:4px;
transition-duration:500ms;
}
.nav-icon-5 span:nth-child(1){
top:0px;
left:0px;
}
.nav-icon-5 span:nth-child(2){
top:13px;
left:0px;
opacity:1;
}
.nav-icon-5 span:nth-child(3){
bottom:0px;
left:0px;
}
.nav-icon-5:not(.open):hover span:nth-child(1){
transform:rotate(-1deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(2){
transform:rotate(1deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(3){
transform:rotate(-1deg) scaleY(1.1);
}
.nav-icon-5.open span:nth-child(1){
transform:rotate(45deg);
top: 13px;
}
.nav-icon-5.open span:nth-child(2){
opacity: 0;
}
.nav-icon-5.open span:nth-child(3){
transform: rotate(-45deg);
top: 13px;
}
@media screen and (min-width:600px)
{ display:none; }
`

import React, { useContext, useState, useEffect, useRef, useCallback  } from "react";
import { Link, Redirect } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InfoData } from "./InfoData";
import { StoreContext } from "./store/StoreProvider";
import { types } from "./store/StoreReducer";
import Barras from './components/loaders/Barras';


const useLogin = () => {
  const [entrada, setEntrada] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [pwdtipo, setPwdtipo] = useState("password");

  const validarEmail = (valor) => /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,4})*(\.\w{2,3})+$/.test(valor);
  const validarCelular = (valor) => !isNaN(valor) && valor.length <= 8;

  const verificarDisponibilidad = async (tipo, valor) => {
    const payload = { usuario: "", celular: "", email: "" };
    payload[tipo] = valor;
    const resource = await InfoData("disponibilidadUsr", payload);
    return resource.data.disponibilidad;
  };

  const disponibilidadUsuario = (user) => verificarDisponibilidad("usuario", user);
  const disponibilidadEmail = (email) => verificarDisponibilidad("email", email);
  const disponibilidadCelular = (celular) => verificarDisponibilidad("celular", celular);

  const reconocer = async (valor) => {
    if (validarEmail(valor)) return { tipo: 1, db: await disponibilidadEmail(valor), Usuario:"", Email:valor, Celular:"" };
    if (validarCelular(valor)) return { tipo: 2, db: await disponibilidadCelular(valor), Usuario:"", Email:"", Celular:valor };
    return { tipo: 3, db: await disponibilidadUsuario(valor), Usuario:valor, Email:"", Celular:"" };
  };

  return { entrada, setEntrada, password, setPassword, checked, setChecked, pwdtipo, setPwdtipo, reconocer };
};


  //funcion para loguearse usando la cuenta de google
  function LoginG(props) {
  const { store, dispatch } = useContext(StoreContext);
  const { home, ubicacion } = store;
  //const [redirect, setRedirect] = useState(false);
  
  useEffect(() => {
    async function handleCredentialResponse(response) {
	dispatch({ type: types.authLogin, payload: { login: true } });  // activa las barras de carga
      //console.log('Encoded JWT ID token: ' + response.credential);
      // Aquí puedes enviar el token de ID a tu servidor para verificarlo
	  let resource = await InfoData('loginGoogle', { token:response.credential });
	  console.log(resource);
      let perfil = resource.data;
		if (perfil.error === '') {
			dispatch({ type: types.authLogin, payload: { ...perfil, login: true, ...ubicacion } });
			props.setRedirect(true);
		} else {
			console.error('Error en el login:', perfil.error);
		}	
    }

    function initializeGoogleSignIn() {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: '351655778650-t9nou81k1asic69tm4mpb215jc58ab1r.apps.googleusercontent.com', // Reemplaza con tu ID de cliente
          callback: handleCredentialResponse,
        });
        const buttonDiv = document.getElementById('buttonDiv');
        if (buttonDiv) {
          window.google.accounts.id.renderButton(buttonDiv, {
            theme: 'outline',
            size: 'large',
            text: 'continue_with',
            shape: 'rectangular',
            logo_alignment: 'left',
			width: window.innerWidth > 800?'345px':"",
          });
        window.google.accounts.id.prompt();
		} 
      } else {
        setTimeout(initializeGoogleSignIn, 100); // Intenta de nuevo después de 100ms
      }
    }

    initializeGoogleSignIn();
  }, [dispatch, home, props, ubicacion]);
  
/*    if (redirect) {
        return <Redirect to={home} />;
    }*/
  
  
 /*   const google = <svg height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
        <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
        <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
        <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
        <path d="M1 1h22v22H1z" fill="none"/>
      </svg>;
*/
  
    return (
    <div>
      <div className="divider-wrapper">
        <span className="divider">o</span>
      </div>
		<div id="buttonDiv" className="g_id_signin"></div>
    </div>
  );
}



export const LogForm = (props) => {
  const { entrada, setEntrada, password, setPassword, checked, setChecked, pwdtipo, setPwdtipo, reconocer } = useLogin();
  const { store, dispatch } = useContext(StoreContext);
  const { home, logo, ruta } = store;
  const [redirect, setRedirect] = useState(false);
  const inputRef = useRef(null);
  const isMounted = useRef(true); // Bandera para verificar si el componente está montado

  const ajuste = useCallback((normal) => {
    if (props.ajuste === '1') {
      const header = document.getElementsByClassName("p-dialog-header")[0];
      const content = document.getElementsByClassName("p-dialog-content")[0];
      const formWrapper = document.getElementsByClassName("form-wrapper")[0];

      if (normal === '1') {
        header.style.padding = '0.3rem 0.3rem 1.5rem 1.5rem';
        content.style.padding = '0 1.5rem';
        content.style.background = '#ffffff';
      } else {
        header.style.padding = '0';
        content.style.padding = '0';
        formWrapper.style.border = '0';
        formWrapper.style.background = 'transparent';
        content.style.background = 'transparent';
      }
    }
  }, [props.ajuste]);
  

  useEffect(() => {
    ajuste();
    if (inputRef.current) { inputRef.current.type = "password"; }

    // Cleanup function para desmontar la bandera
    return () => {
      isMounted.current = false;
    };
  }, [ajuste]);
  
  


  const iniciar = useCallback(async () => {
	dispatch({ type: types.authLogin, payload: { login: true } });  // activa las barras de carga
    try {
      // Paso 1: Reconocer el tipo de entrada (email, celular, usuario)
      let tipo = await reconocer(entrada);

      // Paso 2: Hacer la solicitud de inicio de sesión
      let resource = await InfoData('login', { tipo, password, checked });
      let perfil = resource.data;

      // Verificar si el componente sigue montado antes de continuar
      if (!isMounted.current) return;

      if (perfil.error === "") {
        // Paso 3: Actualizar la ubicación en el estado global
        let ubicacion = {
          Departamento: perfil.Departamento,
          Departamento_ID: perfil.Departamento_ID ? perfil.Departamento_ID : "",
          Distrito: perfil.Distrito,
          Distrito_ID: perfil.Distrito_ID ? perfil.Distrito_ID : "",
          Pais: perfil.Pais,
          Pais_ID: perfil.Pais_ID ? perfil.Pais_ID : '',
          Provincia: perfil.Provincia,
          Provincia_ID: perfil.Provincia_ID ? perfil.Provincia_ID : '',
          label: perfil.Distrito + ", " + perfil.Provincia + " - " + perfil.Departamento + " - " + perfil.Pais,
          value: "",
        };
        dispatch({ type: types.ubicacion, payload: ubicacion });
		localStorage.setItem('ubicacion', JSON.stringify(ubicacion));
		localStorage.setItem('selectedCountry',perfil.Pais_ID);

        // Paso 4: Actualizar el estado de autenticación y guardar en localStorage
        dispatch({ type: types.authLogin, payload: { ...perfil, login: true } });

        // Paso 5: Ajustar estilos (si es necesario)
        ajuste('1');

        // Paso 6: Actualizar contactos en el estado global
        dispatch({ type: types.contactos, payload: [{ Contacto_ID: "0" }] });
        let db3 = await InfoData(types.contactosUser, perfil.Usu_ID);
        dispatch({ type: types.contacto, payload: [] });
		console.log(db3);
        dispatch({ type: types.contactosUser, payload: db3 });

//		props.setBarras(false);		
        // Paso 7: Finalmente, redirigir al usuario
        if (isMounted.current) {
          setRedirect(true); // Esto activará el Redirect
        }
		//return <Redirect to={home} />;		
      } else {
        alert(tipo.db === "ocupado" ? "Tu contraseña es incorrecta" : `${entrada} no está registrado`);
		dispatch({ type: types.authLogin, payload: { ...perfil, login: false } });
      }
    } catch (error) {
      console.error("Error en la función iniciar:", error);
    }

  }, [entrada, password, checked, reconocer, dispatch, ajuste]);

  if (redirect) {
    return <Redirect to={home} />;
  }
  
  
  

  return (
    <div className="reg-content-wrapper single" style={{ maxWidth: "400px" }}>
      <div className="join-form-container form-container">
        <div className="join-form-wrapper form-wrapper">
          <div className="alerts-cont" style={{ textAlign: "center", position: "relative", top: '-1em', display: props.ajuste ? 'block' : 'none' }}>
            <Link to='/'><img src={ruta + logo} height='47' alt={"servicontacto.com"} /></Link>
          </div>
          <div className="loading-indicator hidden"></div>
          <fieldset>
            <legend>Únete a Supercontacto</legend>
            <form onSubmit={(e) => { e.preventDefault(); iniciar(); }}>
				<input type="text" name="username" autoComplete="username" style={{ display: 'none' }} defaultValue={entrada} />  
				{/* Asigna el nombre de usuario actual si está disponible */}
              <ul className="input-fields container-box" style={{ listStyle: "none", paddingInlineStart: "0px" }}>
                <li className="cell-input1">
                  <div className="cell-body" style={{ display: "grid" }}>
                    <label className="float-label" htmlFor="user">Correo electrónico, teléfono o nombre de usuario</label>
                    <InputText id="user" value={entrada} onChange={(e) => setEntrada(e.target.value)} onBlur={() => reconocer(entrada)} autoComplete="current-password" />
                  </div>
                </li>
                <li className="cell-input1">
                  <div className="cell-body" style={{ display: "grid" }}>
                    <label className="float-label" htmlFor="inpwdl">
                      <span style={{ float: "right", fontWeight: "lighter" }}>
                        <Link to="/usuario/recovery">¿Has olvidado tu contrase&ntilde;a?</Link>
                      </span>
                      Contraseña
                    </label>
                    <span className="p-input-icon-right">
                      <i className={pwdtipo === "password" ? "pi pi-eye" : "pi pi-eye-slash"} onClick={() => setPwdtipo((prev) => (prev === "password" ? "text" : "password"))} style={{ right: "0.5em", cursor: "pointer", top: "0.8em", fontSize: "1.5em", color: "#ddd" }} />
                      <InputText id="inpwdl" ref={inputRef} type={pwdtipo} value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
                    </span>
                  </div>
                </li>
                <li className="cell-input1">
                  <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked)} />
                  <span htmlFor="binary" style={{ verticalAlign: "middle", marginLeft: "0.7em" }}>Equipo propio</span>
                </li>
              </ul>
              <Button className="logboton join-btn" label="Iniciar sesión" disabled={ !entrada ?'disabled':''} type="submit" />
            </form>
            <p className="signin-link">¿No eres miembro de Servicontacto? <Link to="/usuario/registro">Reg&iacute;strate aqu&iacute;</Link></p>
			
			{true && <LoginG setRedirect={setRedirect}/>}
			
			
          </fieldset>
        </div>
      </div>
    </div>
  );
};


export const Login = (props) => {
  const { store } = useContext(StoreContext);
  const { user } = store;
  const isLargeScreen	= window.innerWidth > 800;

  return (
    <div className="p-fluid dashboard">
	{user.login && <Barras/>}
      {isLargeScreen ? 
        <div id="screen-banner" className="escritorio marco reg">
          <section className="envoltura">
            <div id="contenido">
              <div className="imagenizq">
                <h2 id="textoTitulo">
                  Todo lo que necesitas para hacer de tu vida m&aacute;s productiva
                </h2>
                <p id="textoSubTitulo">
                  Lo encontrar&aacute;s aqu&iacute; al alcance de un clic
                </p>
              </div>
            </div>
            <div id="logSC">
              <LogForm className={"join-form-wrapper form-wrapper"} />
            </div>
          </section>
        </div> :
        <div className="movil marco" style={{ padding: '5em 10px' }}>
          <LogForm className={"join-form-wrapper form-wrapper movil"} />
        </div>
      }
    </div>
  );
};
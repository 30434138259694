import React, { useEffect } from 'react';



export const Home = (props) => {

useEffect(() => {
  document.getElementById('superMenu').style.display = 'block';
}, []);


return (
<div className="home">
</div>
);
}
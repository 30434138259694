import React, { useContext, useState } from 'react';
import axios from "axios";
import { ProgressBar } from 'primereact/progressbar';
import { StoreContext } from './store/StoreProvider';
import { SubirFile } from "./SubirFile";

export const UploadForm = (props) => {

const {store} = useContext(StoreContext);
const {user, contacto, apiFile} = store;
const [file, setFile] = useState(null);  
const [progress, setProgress] = useState(0);  

console.log(props.uploadCase);

    // Función para manejar la subida del archivo
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            console.error("No se ha seleccionado ningún archivo.");
            return;
        }

        let res = await uploadFile(file);
        if (res.data.status === "success") {
            handleUploadSuccess(res.data.url);
        }
    };

    // Función para manejar el éxito de la subida
    const handleUploadSuccess = (url) => {
        switch (props.uploadCase) {
            case 'SubirFotoPerfil':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, contacto.tipo);
                break;
            case 'SubirBanner':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, '9');
                break;
            case 'SubirBanner1':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, '10');
                break;
            case 'SubirBanner2':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, '11');
                break;
            case 'SubirBanner3':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, '12');
                break;
            case 'subirStaff':
                props.callBackOnSuccessUpload(url, contacto.Contacto_ID, contacto.tipo >= 3 && contacto.tipo <=4  ? '8' : '7');
                break;
            case 'SubirFotoUsuario':
                props.callBackOnSuccessUpload(url, user.Usu_ID, '0');
                break;
            default:
                console.log("No hay coincidencias");
        }
    };

    // Función para manejar el cambio de archivo en el input type="file"  // va con el input type="file" deshabilitado
/*    const onChange = (e) => {
        setFile(e.target.files[0]);
        setProgress(0);
    };*/

    // Función para manejar la subida del archivo desde el componente SubirFile
    const onFileUpload = async (files) => {
        if (files.length > 0) {
            setFile(files[0]); // Actualiza el estado con el archivo seleccionado
            let res = await uploadFile(files[0]);
            if (res.data.status === "success") {
                handleUploadSuccess(res.data.url);
            }
        }
    };

    // Función para subir el archivo (reutilizable)
    const uploadFile = async (file) => {
        const config = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            },
            headers: { 'content-type': 'multipart/form-data' }
        };

        const formData = new FormData();
        formData.append('avatar', file);

        switch (props.uploadCase) {
            case 'SubirFotoPerfil':							//la foto de peril de o logo de contacto
            case 'SubirBanner':
            case 'SubirBanner1':
            case 'SubirBanner2':
            case 'SubirBanner3':
            case 'subirStaff':
                formData.append('idp', contacto.Contacto_ID);
                break;
            case 'SubirFotoUsuario':						//la foto de peril del usuario de servicontacto
                formData.append('idp', user.Usu_ID);
                break;
            default:
                console.log("No hay coincidencias");
        }

        return await axios.post(apiFile, formData, config);
    };
	
	
	const mensajes = {
	  1: 'La imagen puede tener un ancho de 220 píxeles y una altura de 85 píxeles',
	  2: 'La imagen puede ser transparente y debe tener un ancho de 220 píxeles',
	  3: 'La foto debe tener una resolución de 175 x 175 píxeles',
	  4: 'La foto debe tener una resolución de 175 x 175 píxeles',
	  5: 'La imagen puede ser transparente y tener un ancho de 220 píxeles y una altura de 220 píxeles',
	  6: 'La imagen puede tener un ancho de 220 píxeles y una altura de 220 píxeles',
	};


    // Función para mostrar el título según el caso
    const titulo = () => {
        let salida = '';
        switch (props.uploadCase) {
            case 'SubirFotoPerfil':
                salida = mensajes[contacto.tipo];
                break;
            case 'SubirBanner':
                salida = 'La foto de portada debe tener una resolución de 1200 x 450 píxeles';
                break;
            case 'SubirBanner1':
                salida = 'La 2da foto de portada debe tener una resolución de 1200 x 450 píxeles';
                break;
            case 'SubirBanner2':
                salida = 'La 3ra foto de portada debe tener una resolución de 1200 x 450 píxeles';
                break;
            case 'SubirBanner3':
                salida = 'La 4ta foto de portada debe tener una resolución de 1200 x 450 píxeles';
                break;
            case 'subirStaff':
                salida = contacto.tipo >= 3 ? 'La foto debe tener una resolución de 500 x 700' : 'La foto debe tener una resolución de 1000 x 500';
                break;
            case 'SubirFotoUsuario':
                salida = 'La foto de perfil debe tener una resolución de 340 x 300 píxeles';
                break;
            default:
                console.log("No hay coincidencias");
        }
        return salida;
    };

    return (
        <form onSubmit={onSubmit}>
            <p>{titulo()}</p>
          {/*  <input type="file" onChange={onChange} />
            <button className="logboton join-btn" type="submit" style={{ float: 'right' }}>Subir archivo</button>*/}
            <span className="p-float-label" style={{ color: '#fafafa' }}>{progress}</span>
            <ProgressBar style={{ display: progress !== 0 ? 'block' : 'none', marginBottom: '1em' }} value={progress}></ProgressBar>
            <SubirFile onUpload={onFileUpload} />
        </form>
    );
};
import { Galleria } from "primereact/galleria";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { MegaMenuSC } from "./MegaMenuSC";
import { StoreContext } from "./store/StoreProvider";
import { types } from "./store/StoreReducer";
import { InfoData } from "./InfoData";
import './layout/Supermenu.css';
import './layout/Megamenu.css';
import portada3 from './assets/images/portada-3-990-400.png';
import portada4 from './assets/images/portada-4-990-400.png';
import portada5 from './assets/images/portada-5-990-400.png';
import portada1 from './assets/images/portada-1-400-400.png';
import portada2 from './assets/images/portada-2-400-400.png';
import portada6 from './assets/images/portada-3-400-400.png';


export const Supermenu = (props) => {
  const {store, dispatch} = useContext(StoreContext);
  const { categoria ,zona, ruta, menu, img } = store;
  const [menuCel, setMenuCel] = useState([]);
  const [esEscritorio, setEsEscritorio] = useState(window.innerWidth > 800);
  const [loadedImages, setLoadedImages] = useState(null); // Estado para imágenes cargadas

  //Imagenes para pantallas grandes
/*  const images = [
    { "itemImageSrc": portada3,  "alt":"Descripción de imagen 1" },
    { "itemImageSrc": portada4, "alt":"Descripción de imagen 2" },
    { "itemImageSrc": portada5, "alt":"Descripción de imagen 3" },	
  ];
  //Imagenes para pantallitas
    const imagesM = [
    { "itemImageSrc": portada1,  "alt":"Descripción de imagen 1" },	
    { "itemImageSrc": portada2,  "alt":"Descripción de imagen 2" },		
    { "itemImageSrc": portada6,  "alt":"Descripción de imagen 6" },		
  ];*/
  
  // Precargar imágenes en memoria
  useEffect(() => {
    const loadImages = async () => {
      try {
        const imagePromises = [
          loadImage(portada3, "Descripción de imagen 1"),
          loadImage(portada4, "Descripción de imagen 2"),
          loadImage(portada5, "Descripción de imagen 3"),
        ];
        const imagePromisesM = [
          loadImage(portada1, "Descripción de imagen 1"),
          loadImage(portada2, "Descripción de imagen 2"),
          loadImage(portada6, "Descripción de imagen 6"),
        ];
        const [loadedImagesDesktop, loadedImagesMobile] = await Promise.all([
          Promise.all(imagePromises),
          Promise.all(imagePromisesM),
        ]);
        //console.log("Imágenes cargadas (desktop):", loadedImagesDesktop);
        //console.log("Imágenes cargadas (mobile):", loadedImagesMobile);
        setLoadedImages({ desktop: loadedImagesDesktop, mobile: loadedImagesMobile });
      } catch (error) {
        console.error("Error cargando imágenes:", error);
      }
    };
    loadImages();
  }, []);

  // Función para cargar una imagen y devolver un objeto
  const loadImage = (src, alt) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        //console.log(`Imagen cargada: ${src}`);
        resolve({ itemImageSrc: src, alt }); // Usamos la URL original en lugar del objeto Image
      };
      img.onerror = () => reject(new Error(`No se pudo cargar la imagen: ${src}`));
    });
  };
 
  
  const responsiveOptions = [
    { breakpoint: "1024px", numVisible: 5 },
    { breakpoint: "768px", numVisible: 3 },
    { breakpoint: "560px", numVisible: 1 },
  ];

  
  useEffect(() => {
  const handleResize = () => {
    setEsEscritorio(window.innerWidth > 800);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);
  
  
  
  
  useEffect(() => {
    	categorias('leer');
		
  }, []);   //eslint-disable-line react-hooks/exhaustive-deps

  const ParametroBus = (item, etiqueta, categoria) => {
    let data = {
      abueloID: item.abuelo || "",
      abuelo: categoria,
      padreID: item.papa || "",
      padre: etiqueta,
      Arbol_ID: item.Cat_ID,
      Arbol: item.label,
    };
    let query = {
      abueloID: item.abuelo || "",
      padreID: item.papa || "",
      Arbol_ID: item.Cat_ID,
    };
	let barra=query.Arbol_ID?'c='+query.Arbol_ID:query.padreID?'f='+query.padreID:'a='+query.abueloID;
    let Categoria = categoria;
    let Etiqueta = etiqueta;
    let Label = item.label;
    let v = "/";
    let parametro = Categoria + v + Etiqueta + v + Label;
    parametro = parametro.split(" ").join("-");
    dispatch({ type: types.categoria, payload: { data, query, parametro, barra } });
    dispatch({ type: types.contactos, payload: [{ Contacto_ID: "0" }] });
  };
  
  
const categorias = async (accion) => {
  let datos = JSON.parse(localStorage.getItem("items")) || [];
  if (datos.length === 0) {
    let resource = await InfoData(types.categorias, accion);
    //console.log(resource.data);
    localStorage.setItem("items", JSON.stringify(resource.data));
    datos = resource.data;
  }
  // Genera el menú para escritorio y móvil con la misma función
  let categoriasEscritorio = crearMenu(0, datos, false); // Escritorio
  let categoriasMovil = crearMenu(0, datos, true); // Móvil
  dispatch({ type: types.menu, payload: categoriasEscritorio });
  setMenuCel(categoriasMovil);
  return categoriasEscritorio;
};



// Función externa que crea el comando para el botón "Mostrar"
// Esto se usa para capturar los valores de 'padre' y 'clase' en el momento exacto de la creación del ítem,
// evitando que la función 'command' use valores cambiantes del bucle (problema de cierres en JS).
const createCommand = (padre, clase, isMobile) => {
  // Retorna una función que se ejecutará cuando se haga clic en "Mostrar"
  return () => {
    // Recorre los elementos en 'clase' y alterna su visibilidad
    for (let n in clase) {
      if (!isNaN(n)) {
        clase[n].classList.toggle("p-oculto");
      }
    }
    // Define el índice del botón "Mostrar" según el dispositivo
    const masIndex = isMobile ? 0 : 0; // Ajustado a 0 para ambos casos, según tu última versión
    // Alterna las clases "p-menos" y "p-oculto" en el botón "Mostrar"
    document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.toggle("p-menos");
    document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.toggle("p-oculto");
    // Si el botón vuelve a su estado inicial ("p-mas") y el scroll está abajo, sube al inicio
    if (
      document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.value ===
      padre + " p-menuitem-link p-mas"
    ) {
      if (window.scrollY > 150) {
        window.scrollTo(0, 0);
      }
    }
  };
};

// Función principal que genera el modelo del menú para MegaMenuSC
const crearMenu = (ID_padre, arbol, isMobile = false) => {
  let hijos = [];
  // Posición inicial del panel, diferente para móvil (-215) y escritorio (-120)
  let top = isMobile ? -215 : -120;

  // Recorre las categorías principales (tallos)
  for (var tallo in arbol) {
    if (parseInt(arbol[tallo].padre) === ID_padre) {
      let items = [];
      let contador = 0;
      let set = 0;
      let por = 1;

      // Recorre los submenús (ramas) bajo cada categoría
      for (var rama in arbol) {
        if (arbol[rama].padre === arbol[tallo].Cat_ID && contador <= 12) {
          let lista = [];
          let conteo = 0;
          let clase = "";
          let padre = "";

          // Recorre los ítems hoja bajo cada submenú
          for (var hoja in arbol) {
            if (arbol[hoja].padre === arbol[rama].Cat_ID) {
              if (conteo < 3) {
                lista[conteo] = { label: arbol[hoja].label, Cat_ID: arbol[hoja].Cat_ID };
              } else {
                lista[conteo] = {
                  label: arbol[hoja].label,
                  Cat_ID: arbol[hoja].Cat_ID,
                  padre: arbol[hoja].padre,
                  oculto: true,
                };
              }
              conteo = conteo + 1;
              padre = arbol[hoja].padre;
              // Lista de elementos DOM que se modificarán al hacer clic en "Mostrar"
              clase = document.getElementsByClassName("p-menuitem-link " + arbol[hoja].padre);
            }

            // Si hay más de 3 ítems, agrega el botón "Mostrar"
            if (conteo > 3) {
              lista[conteo] = {
                label: "Mostrar",
                padre: padre,
                mas: true,
                // Usa createCommand para fijar los valores de 'padre' y 'clase' en este momento
                command: createCommand(padre, clase, isMobile),
              };
            }
          }

          // Define cómo se dividen los submenús en columnas según el dispositivo
          const splitPoints = isMobile ? [0, 12] : [0, 6, 24, 54];
          if (splitPoints.includes(contador * por)) {
            items[contador - set] = [{ label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista }];
          } else {
            items[contador - 1 - set].push({ label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista });
            por = set = set + 1;
          }
          contador = contador + 1;
        }
      }

      // Agrega la categoría completa al array de hijos
      hijos.push({
        icon: arbol[tallo].icon,
        items: items,
        label: arbol[tallo].label,
        Cat_ID: arbol[hoja].Cat_ID,
        top: top,
      });
      top = top - 100; // Ajusta la posición para el siguiente panel
    }
  }
  return hijos;
};



/*
// Función unificada para crear el modelo del menú, compatible con escritorio y móvil
const crearMenu = (ID_padre, arbol, isMobile = false) => {
  let hijos = [];
  // Ajusta la posición inicial del panel según el dispositivo
  let top = isMobile ? -215 : -120;
  // Recorre las categorías principales (tallos)
  for (var tallo in arbol) {
    if (parseInt(arbol[tallo].padre) === ID_padre) {
      let items = [];
      let contador = 0;
      let set = 0;
      let por = 1;
      // Recorre los submenús (ramas) bajo cada categoría
      for (var rama in arbol) {
        if (arbol[rama].padre === arbol[tallo].Cat_ID && contador <= 12) {
          let lista = [];
          let conteo = 0;
          let clase = "";
          let padre = "";
          // Recorre los ítems hoja bajo cada submenú
          for (var hoja in arbol) {
            if (arbol[hoja].padre === arbol[rama].Cat_ID) {
              // Muestra hasta 3 ítems visibles, el resto ocultos
              if (conteo < 3) {
                lista[conteo] = { label: arbol[hoja].label, Cat_ID: arbol[hoja].Cat_ID };
              } else {
                lista[conteo] = {
                  label: arbol[hoja].label,
                  Cat_ID: arbol[hoja].Cat_ID,
                  padre: arbol[hoja].padre,
                  oculto: true,
                };
              }
              conteo = conteo + 1;
              padre = arbol[hoja].padre;
              clase = document.getElementsByClassName("p-menuitem-link " + arbol[hoja].padre);
            }
            // Si hay más de 3 ítems, agrega un botón "Mostrar"
            if (conteo > 3) {
              lista[conteo] = {
                label: "Mostrar",
                padre: padre,
                mas: true,
                command: () => {
                  // Alterna la visibilidad de los ítems ocultos
                  for (let n in clase) {
                    if (!isNaN(n)) {
                      clase[n].classList.toggle("p-oculto");
                    }
                  }
                  // Ajusta el índice del botón "Mostrar" según el dispositivo
                  const masIndex = isMobile ? 0 : 0;
                  document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.toggle("p-menos");
                  document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.toggle("p-oculto");
                  // Desplaza al inicio si el botón está en estado inicial y el scroll está abajo
                  if (
                    document.getElementsByClassName("p-mas " + padre)[masIndex]?.classList.value ===
                    padre + " p-menuitem-link p-mas"
                  ) {
                    if (window.scrollY > 150) {
                      window.scrollTo(0, 0);
                    }
                  }
                },
              };
            }
          }
          // Divide los submenús en columnas según el dispositivo
          const splitPoints = isMobile ? [0, 12] : [0, 6, 24, 54];
          if (splitPoints.includes(contador * por)) {
            items[contador - set] = [{ label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista }];
          } else {
            items[contador - 1 - set].push({ label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista });
            por = set = set + 1;
          }
          contador = contador + 1;
        }
      }
      // Agrega la categoría al array de hijos
      hijos.push({
        icon: arbol[tallo].icon,
        items: items,
        label: arbol[tallo].label,
        Cat_ID: arbol[hoja].Cat_ID,
        top: top,
      });
      top = top - 100; // Incrementa la posición para el siguiente panel
    }
  }
  return hijos;
};*/
  
  

  const getMenuClickData = (event, item) => {
    console.log(event);
    console.log(item);
	dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] });
  };

  const buscar = (caso, web, gen) => {
    ParametroBus(
      {
        Cat_ID: gen === 1 ? caso : "",
        papa:   gen === 2 ? caso : "",
        abuelo: gen === 3 ? caso : "",
        label: web.split("/")[2],
      },
      web.split("/")[1],
      web.split("/")[0]
    );
    document.getElementById("superMenu").style.display = "none";
    window.scrollTo(0, 0);
  };
  
  const prepara = (caso, web, gen) => {
    ParametroBus(
      {
        Cat_ID: gen === 1 ? caso : "",
        papa:   gen === 2 ? caso : "",
        abuelo: gen === 3 ? caso : "",
        label: web.split("/")[2],
      },
      web.split("/")[1],
      web.split("/")[0]
    );
  };

  

  const itemTemplate = (item) => {
    return React.createElement("img", {
      src: item.itemImageSrc,
      alt: item.alt,
      style: { display: "block" },
    });
  };

  
  const thumbnailTemplate = (item) => {
    return React.createElement("img", {
      src: item.thumbnailImageSrc,
      alt: item.alt,
      style: { display: "block" },
    });
  };

 /*
  const regresar = () => {
	document.getElementById('lateral').style.width='15em'
	document.getElementById('regreso').style.display='none';
	document.getElementById('superMenu').style.position='fixed';	
  };  
  */
  
  
   // Seleccionar las imágenes según el dispositivo solo si loadedImages está listo
    const galleriaImages = loadedImages ? (esEscritorio ? loadedImages.desktop : loadedImages.mobile) : [];
//  const galleriaImages =  esEscritorio ? images : imagesM ; 
//    console.log("galleriaImages:", galleriaImages);
  
const galeria = (
  <div className="fix-content fix-content-right">
    <div id="first-banner" className="first-banner ui-switchable">
      <div className="scroller">
        <div
          className="first-banner-content ui-switchable-content first-banner-content-position"
          data-role="content"
        >
          <div className="banner-item ui-switchable-panel banner-item-float"></div>
          <div className="banner-item ui-switchable-panel banner-item-float-zindex">
            <Galleria
              className="banner-image"
              value={ galleriaImages }
              responsiveOptions={responsiveOptions}
              numVisible={5}
              showItemNavigators
              showThumbnails={false}
              showItemNavigatorsOnHover
              showIndicators
              item={itemTemplate}
              thumbnail={thumbnailTemplate}
              circular
              autoPlay
              transitionInterval={3500}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const colDer = (
  <div className="colu col-right">
    <div className="ta-promotion row-content" data-spm="201704">
      <Link
        className="top-banner"
        to={zona.parametro + 'Profesionales/todos-los-profesionales/todas-las-categorias?' + categoria.barra + zona.query}
      >
        <span
          onMouseOver={(e) => prepara(1, 'Profesionales/todos-los-profesionales/todas-las-categorias', 3)}
          onClick={(e) => buscar(1, 'Profesionales/todos-los-profesionales/todas-las-categorias', 3)}
        >
          Tus servicios profesionales
          <span className="view-more">A solo un clic</span>
        </span>
      </Link>
      <div className="ta-promotion-list">
        <div className="ta-promotion-item zoom-wrap popul ares">
          <div
            className="title"
            onMouseOver={(e) => prepara(2, 'profesionales/abogados/todas-las-especialidades', 2)}
            onClick={(e) => buscar(2, 'profesionales/abogados/todas-las-especialidades', 2)}
          >
            <Link to={zona.parametro + 'profesionales/abogados/todas-las-especialidades?' + categoria.barra + zona.query}>
              <div className="promotion-title-text">Abogados</div>
            </Link>
          </div>
          <Link to={zona.parametro + 'profesionales/abogados/Derecho-de-familia?' + categoria.barra + zona.query}>
            <div
              className="view-more"
              onMouseOver={(e) => prepara(7, 'profesionales/abogados/Derecho-de-familia', 1)}
              onClick={(e) => buscar(7, 'profesionales/abogados/Derecho-de-familia', 1)}
            >
              Derecho familiar
            </div>
          </Link>
          <div className="item-banner">
            <Link to={zona.parametro + 'profesionales/abogados/todas-las-especialidades?' + categoria.barra + zona.query}>
              <img
                onMouseOver={(e) => prepara(2, 'profesionales/abogados/todas-las-especialidades', 2)}
                onClick={(e) => buscar(2, 'profesionales/abogados/todas-las-especialidades', 2)}
                src={ruta + "assets/images/abogados.png"}
                alt="abogados"
                className="zoom-in"
              />
            </Link>
          </div>
        </div>
        <div className="ta-promotion-item zoom-wrap popul">
          <div
            className="title"
            onMouseOver={(e) => prepara(16, 'profesionales/médicos/todas-las-especialidades', 2)}
            onClick={(e) => buscar(16, 'profesionales/médicos/todas-las-especialidades', 2)}
          >
            <Link to={zona.parametro + 'profesionales/médicos/todas-las-especialidades?' + categoria.barra + zona.query}>
              <div className="promotion-title-text">Medicos</div>
            </Link>
          </div>
          <Link to={zona.parametro + 'profesionales/médicos/Dermatólogos?' + categoria.barra + zona.query}>
            <div
              className="view-more"
              onMouseOver={(e) => prepara(34, 'profesionales/médicos/Dermatólogos', 1)}
              onClick={(e) => buscar(34, 'profesionales/médicos/Dermatólogos', 1)}
            >
              Dermatólogos
            </div>
          </Link>
          <div className="item-banner">
            <Link to={zona.parametro + 'profesionales/médicos/todas-las-especialidades?' + categoria.barra + zona.query}>
              <img
                onMouseOver={(e) => prepara(16, 'profesionales/médicos/todas-las-especialidades', 2)}
                onClick={(e) => buscar(16, 'profesionales/médicos/todas-las-especialidades', 2)}
                src={ruta + "assets/images/medicos.png"}
                alt="medicos"
                className="zoom-in"
              />
            </Link>
          </div>
        </div>
        <div className="ta-promotion-item zoom-wrap">
          <div
            className="title"
            onMouseOver={(e) => prepara(114, 'profesionales/arquitectos/todas-las-especialidades', 2)}
            onClick={(e) => buscar(114, 'profesionales/arquitectos/todas-las-especialidades', 2)}
          >
            <Link to={zona.parametro + 'profesionales/arquitectos/todas-las-especialidades?' + categoria.barra + zona.query}>
              <div className="promotion-title-text">Arquitectos</div>
            </Link>
          </div>
          <Link to={zona.parametro + 'profesionales/arquitectos/Edificador-residencial?' + categoria.barra + zona.query}>
            <div
              className="view-more"
              onMouseOver={(e) => prepara(115, 'profesionales/arquitectos/Edificador-residencial', 1)}
              onClick={(e) => buscar(115, 'profesionales/arquitectos/Edificador-residencial', 1)}
            >
              Edif. residencial
            </div>
          </Link>
          <div className="item-banner">
            <Link to={zona.parametro + 'profesionales/arquitectos/todas-las-especialidades?' + categoria.barra + zona.query}>
              <img
                onMouseOver={(e) => prepara(114, 'profesionales/arquitectos/todas-las-especialidades', 2)}
                onClick={(e) => buscar(114, 'profesionales/arquitectos/todas-las-especialidades', 2)}
                src={ruta + "assets/images/arquitectos.png"}
                alt="arquitectos"
                className="zoom-in"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

return (
  <div className="main-content main-first-floor" id="superMenu">
    <div className="panelMenu ">
      <div className="row category-banner-ta">
        <div className="fix-content fix-content-left">
          {galeria}
          {colDer}
        </div>
        <div className="colu col-left">
          <div className="home-category">
            <div className="category-left">
              <div id="categorias" className="left-title">
                TODAS LAS CATEGORÍAS
              </div>
              <div className="menu-category">
                <MegaMenuSC
                  isMobile={!esEscritorio}
                  model={esEscritorio ? menu : menuCel}
                  parametros={zona}
                  id="lateral"
                  ParametroBus={ParametroBus}
                  getMenuClickData={getMenuClickData}
                  orientation="vertical"
                />
              </div>
            </div>
            {!esEscritorio && (
              <div id="cerrar" className="cerrar-float">
                <i
                  className="pi pi-times cerrar"
                  onClick={() => (document.getElementById('superMenu').style.display = 'none')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
  
  };

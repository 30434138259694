//import { Chart } from 'primereact/chart';
//<Chart type="line" data={basicData} options={basicOptions} />
import React, { useContext, useState , useEffect  } from 'react';
import { StoreContext } from './store/StoreProvider';
import { InfoData } from "./InfoData";

export const Stats = (props) => {

const {store} = useContext(StoreContext);
const {contacto} = store;
const [stats, setStats] = useState([]); 
//const [basicData, setBasicData] = useState([]); 

useEffect(() => {
visitas(contacto.Contacto_ID);
console.log(contacto);
//eslint-disable-next-line
}, []);


  const visitas = async (Contacto) => {
    let resource = await InfoData('visitaInfo', Contacto)
	setStats(resource.data);
    }	

	
const visiInMes = stats?.reduce((sum, value) => (sum + value.visitaIn), 0 );
const visiExMes = stats?.reduce((sum, value) => (sum + value.visitaEx), 0 );	
const visiInCtoMes = stats?.reduce((sum, value) => (sum + value.visitaCtoIn), 0 );
const visiExCtoMes = stats?.reduce((sum, value) => (sum + value.visitaCtoEx), 0 );	
//const visiHoy = (new Date((stats[stats.length-1]?.estampa+24*3600)*1000).getDate() === new Date().getDate())?(stats[stats.length-1]?.visitaEx + stats[stats.length-1]?.visitaIn):0;
const visiHoy = (parseInt(stats[stats?.length-1]?.fecha?.substr(8,2)) === new Date().getDate())?(stats[stats?.length-1]?.visitaEx + stats[stats?.length-1]?.visitaIn):0;

const visiIn = Math.round(parseInt(contacto.visitaIn)*100/(parseInt(contacto.visitaEx) + parseInt(contacto.visitaIn))*10)/10 || 0;
const visiEx = Math.round(parseInt(contacto.visitaEx)*100/(parseInt(contacto.visitaEx) + parseInt(contacto.visitaIn))*10)/10 || 0;
const visiCtoIn = Math.round(parseInt(contacto.visitaCtoIn)*100/(parseInt(contacto.visitaCtoEx) + parseInt(contacto.visitaCtoIn))*10)/10 || 0;
const visiCtoEx = Math.round(parseInt(contacto.visitaCtoEx)*100/(parseInt(contacto.visitaCtoEx) + parseInt(contacto.visitaCtoIn))*10)/10 || 0;
const visiCtoHoy = (parseInt(stats[stats.length-1]?.fecha?.substr(8,2)) === new Date().getDate())?(stats[stats.length-1]?.visitaCtoEx + stats[stats.length-1]?.visitaCtoIn):0;
console.log(new Date((stats[stats.length-1]?.estampa+24*3600)*1000).getDate());
console.log(parseInt(stats[stats.length-1]?.fecha?.substr(8,2)));
console.log(new Date().getDate());


return (
  <div className='p-fluid panelContacto p-grid' style={{top:'0.7em'}}>
	<h1 style={{width:'100%'}}>Estadísticas de visitas en página web y perfil</h1>
    {contacto.usoweb!=='0'?
	<div className='ancho' id="Stats1" style={{width:'50%',minHeight: '400px', float:'left', padding:'1em'}}>
		<h1>Página web</h1>
		<p>{contacto.Nombre_Comercial}</p>
		<h1>{visiInMes+visiExMes}</h1>	
		<p>Vistas (30 días)</p>	

		<h1>{visiHoy}</h1>	
		<p>Vistas el día de hoy</p>		
		<h1>{parseInt(contacto.visitaEx) + parseInt(contacto.visitaIn)+' Vistas en su ciclo de vida'}</h1>	
		<p>{'Vistas desde Servicontacto ----------- '+  visiIn  +'%'}</p>	
		<p>{'Vistas de acceso externo -------------- ' + visiEx +'%' }</p>		
    </div>:''}	
    <div className='ancho' id="Stats2" style={{width:contacto.usoweb!=='0'?'50%':'100%',minHeight: '400px', float:'right', padding:'1em', borderLeft:'2px solid #f3f3f3'}}>
		<h1>Perfil en Servicontacto</h1>
		<p>{contacto.Nombre_Comercial}</p>
		<h1>{visiInCtoMes+visiExCtoMes}</h1>	
		<p>Vistas (30 días)</p>	

		<h1>{visiCtoHoy}</h1>	
		<p>Vistas el día de hoy</p>		
		<h1>{parseInt(contacto.visitaCtoEx) + parseInt(contacto.visitaCtoIn)+' Vistas en su ciclo de vida'}</h1>	
		<p>{'Vistas desde Servicontacto ----------- '+  visiCtoIn  +'%'}</p>	
		<p>{'Vistas de acceso externo -------------- ' + visiCtoEx +'%' }</p>	
    </div>		
  </div>
);

}






import { useContext, useEffect, useState, Fragment, useCallback } from 'react';
import { Button } from "primereact/button";
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";
import { FormularioContacto } from './FormularioContacto';
import {ScrollPanel} from 'primereact/scrollpanel';
import { Panel } from './Panel';


export const Contactos = (props) => {
const {store, dispatch} = useContext(StoreContext);
const {user, contactosUser, contacto, zona } = store;
const [menu, setMenu] = useState(true);
const [reload, setReload] = useState(false);
const [categorias, setCategorias] = useState([]);


const editar = (Contacto, contacto_ID) => { 
	console.log(categorias);
	console.log(Contacto.abueloID );	
//	const abuelo = Contacto.Contacto_ID===''?'':categorias.filter(item => item.Arbol_ID === Contacto.abueloID)[0].label;
	const abueloEncontrado = categorias.find(item => item.Arbol_ID === Contacto.abueloID);
	const abuelo = abueloEncontrado ? abueloEncontrado.label : '';
	console.log(abuelo);
	dispatch({ type: types.contacto, payload: { ...Contacto, Nombre_Abuelo:abuelo, editar:'ok', user:user.Usu_ID} }) 
	window.scrollTo(0, 0);	
  }

  
const obtenerCategorias = async () => {
console.log('obtener cat');
    let resource = await InfoData('categoriaSelect', '0');
	setCategorias(resource.data);
}   
  
 
const ContactoData = useCallback (async (Usu_ID) => {
	let db = await InfoData(types.contactosUser, Usu_ID);
	db = db.length===0?[]:db;
	dispatch({ type: types.contactosUser, payload: db  });
  },[ dispatch ]);
  
// Zona de carga automática

useEffect(() => {
console.log('buscando contactos');
setReload(false);
categorias.length ===0 && obtenerCategorias();
if(user.CtoCreados!==0 || user.CtoAdminis!==0){
  ContactoData(user.Usu_ID);
  }
}, [contactosUser.length,reload,user.CtoCreados,user.CtoAdminis,user.Usu_ID, ContactoData, categorias.length]);
  

  const contactosMenu = !!contactosUser?contactosUser.map((number) => <li className="menuContactos" onClick={() => editar(number)} key={number.item} style={{borderTop:number.item===0?'solid 1px #cfd3e099':'0' }}>
  {number.Nombre_Comercial}</li>):'';
  const contactosPanel = !!contactosUser?contactosUser.map((number) => 
  <div className="layout-Seeker" key={'c'+number.Contacto_ID}><li>
  <Panel contacto={number} router='no' elegir={editar}/>
  </li></div>
  ):'';
  const numCtos =  contactosUser?.length===undefined?0:contactosUser?.length;
  const menuCtos = <div className="layout-sidebar layout-sidebar-light" style={{width:'100%', position:'unset' }} >
  <div>
  <span className="menuContactosH">
  {numCtos===1?'Administras '+numCtos+' contacto':'Administras '+numCtos+' contactos'}
  <i className="pi pi-chevron-down icoCombo"></i>
  </span>
  <ScrollPanel style={{width: '100%', height:'70vh'}}>
  <ul >
  {contactosMenu}
  </ul>
  </ScrollPanel>
  </div>  
  <div className="formulario">
    <div className="reg-content-wrapper single" style={{width:'auto'}}>
      <div className="join-form-container form-container">
      <fieldset className="altoPC"> 
        <Button  icon="pi pi-plus" className="logboton join-btn" label="Crear contacto nuevo" onClick={() => editar({Nombre_Cat:'', Nombre_Padre_Grupo:'', color:'', Contacto_ID:'', direccion:'', distrito:'', celular:'', telefono:'', tipo:'', item:numCtos>0?numCtos:0, bloqGuard:true, Nombre_Pais:zona.data.Pais})} style={{width:'90%', marginBottom:'1em'}} />
      </fieldset>
      </div>
    </div>
  </div>  
</div>;
  const menuMovil = 
  		<button className="p-link layout-menu-button movil" onClick={()=>{let caso=document.getElementById("menuMovil").classList.toggle('oculto'); setMenu(caso)}} type="button" style={{float:'right', padding:'0 0.3em', fontSize:'2em', margin:'0',color:'#999', position:'relative', width:'36px'}}>
		  <span className={menu?"pi pi-bars":"pi pi-times"}/>
		</button>;
  
  
  return (
  <div id="panelEdicion" className="marco" style={{display:'flex', flexWrap:'wrap'}}> 
  {contacto.editar?<FormularioContacto contacto={contacto} setReload={setReload} categorias={categorias}/>:
	  <Fragment> 
		<div className="p-col-12 movil" style={{display: 'flex', background:'#fff', padding:'5px', height:'43px', boxShadow:'#00000059 1px 1px 1px'}}><h1 className="h1M" style={{width:'calc(100% - 36px)', textAlign:'center', margin:'5px'}}>{numCtos===1?'Tu contacto':'Tus '+numCtos+' contactos'}</h1>{menuMovil}</div>
		<div className="p-col-3 escritorio" style={{padding:'0'}}>	
		  <div style={{width:'23.8vw', position:'fixed'}}>
		  {menuCtos}
		  </div>  
		</div>  		
		<div className="p-col-8 negocio">
			<ul>
			  {contactosPanel}
			</ul> 
		</div>  
		<div className="p-col-1 escritorio">	
		</div> 
		<div id="menuMovil" className="menuMovil movil oculto">
		  {menuCtos}
		</div> 	
	  </Fragment>}
  </div>    
  ); 
	
}

import axios from "axios";
import { useContext, useEffect, useState, Fragment } from 'react';
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { Calendar } from "primereact/calendar";
import { Calendario } from './Calendario';
import { useHorario } from './hooks/useHorario';
import { InfoData } from "./InfoData";
import { AutoComplete } from 'primereact/autocomplete';
/*Nota la variable usoweb 0 no web, 1 web, 2 oculto, 3 web y oculto, 4 contacto web teniendo web*/
/*la variable nodisponible indica diferentes formas de indisponibilidad o no*/


export const Consultor = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, especialidad, ruta, img, fecha, disponibilidad} = store;
const [especialidad1, setEspecialidad1] = useState([]); 
const [mostrar, setMostrar] = useState(""); 
const fotoF = ruta+ img.fotoF;
const fotoM = ruta+ img.fotoM;
const logo = ruta+ img.logo;
const tiktok = ruta+img.tiktok;
const instagram = ruta+img.instagram;
const calendar = ruta+img.calendar;
var futuro=false;


const especialistas = (id,catID,item) => { 
	document.getElementsByClassName('masContacto')[item].style.display='block';
	if(item!==especialidad1.item){Especialistas(id,catID,item);
	if(typeof document.getElementsByClassName('masContacto')[especialidad1.item] !== 'undefined'){
	document.getElementsByClassName('masContacto')[especialidad1.item].style.display='none';}
	}
 }

 
 const Especialistas = async (Contacto_ID,catID,item) => {
	if(item!==especialidad1.item){setEspecialidad1({...especialidad1, socio:[]});}
	document.getElementsByClassName('masCajita')[item].style.display='block';
    let cadena = await InfoData('especialista', {contacto:Contacto_ID,catID:catID});
	cadena.pop(); 	
	console.log(cadena);
	setEspecialidad1({...especialidad1, socio:cadena, item:item});
document.getElementsByClassName('masCajita')[item].style.display='none';
  }
  
  
const webSite = (e,modo) => {
dispatch({ type: types.contacto, payload:{...contacto, socio:e, booking:modo}   })
window.scrollTo({top: document.getElementById('panelSocio')?.offsetTop - 120, behavior:'smooth'})
}	


const subContacto = (id,catID,item, modo) => { 
} 


const Fecha = (e) => {
futuro = (new Date(e.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
let hasta = 'Hasta '+ (new Date(e.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"})
let fechaPanel = futuro?hasta:fecha.toLowerCase();
return <span style={{marginLeft:'7px', fontFamily:'arial', fontSize:'16px'}}>{fechaPanel}</span>;
}
	
	
	
//----------------------------
// Zona de renderización final


	const TT = <img className="icoBoton" alt='' src={tiktok} style={{margin:'0 5px 0 0', width:'25px', verticalAlign:'text-bottom'}}/>;
	const IG = <img className="icoBoton" alt='' src={instagram} style={{margin:'0 5px 0 0', width:'25px', verticalAlign:'text-bottom'}}/>;
	const FB = <i className="fab fa-facebook-square icoPag" style={{fontSize:'2.5em', margin:'0 5px 0 0'}}></i>;
	const TW = <i className="fab fa-twitter icoPag" 		style={{color: '#1d9bf0', fontSize:'2.5em', margin:'0 5px 0 0'}}></i>;	
	const YT = <i className="fab fa-youtube icoPag" 		style={{color: '#f70000', fontSize:'2.5em', margin:'0 5px 0 0', padding:'1% 0' }}></i>;
	const WA = <i className="fab fa-whatsapp-square icoPag" style={{color: '#44e561', fontSize:'2.5em', margin:'0 5px 0 0'}}></i>;
	
	const dia = <span  className="calendario" style={{ top:'27px', left:'20px', fontSize:'20px'}}>{new Date().toLocaleDateString("es-PE", {day: "numeric" })}</span>;
	const mes = <span className="calendario" style={{top:'10px', left:'20px', fontSize:'7px'}}>{new Date().toLocaleDateString("es-PE", {month: "long" })}</span>;
	const calen = <div style={{float:'left', position:'relative', display:'flex'}}>{mes}{dia}<img className="icoBoton" alt='' src={calendar} style={{margin:'0', width:'40px'}}/></div>

	const notaM = disponibilidad[contacto.nodisponible]?.usoM;
	const nota = disponibilidad[contacto.nodisponible]?.uso;
	const dispon  = contacto.nodisponible!=='0'?<div className="letrero" style={{float:'right', color:contacto.color, fontSize:'18px'}}><span className="escritorio" >{nota}</span><span className="movil" >{notaM}</span><span>{Fecha(contacto)}</span></div>:'';
	const online  = <div className="letrero" style={{color:contacto.color}}><i className="pi pi-video" style={{margin:'4px 12px'}}></i><span>Consultas on-line</span></div>
	const agendar = <div className="letrero" style={{float:'right', padding:'0', textAlign:'center', color:contacto.color}}>{calen}<span>Agendar una cita</span></div>
	const whatsapp= <div className="letrero" style={{float:'right', display:'flex', color:'#44e561'}}><i className="fab fa-whatsapp" style={{ fontSize:'30px', margin:'6px', float:'left', background:'#44e561', padding:'4px 7px', borderRadius:'7px', color:contacto.tipo === '2' ?'#fff':'#7e7e7e', fontWeight:'lighter'}}></i><span style={{width:'100%'}}>Contacto directo</span></div> 	
	const gratis  = <div className="letrero" style={{float:'right', padding:'0', textAlign:'center', color:contacto.color}}> <span >{'Consulta gratuita '}</span><span className="escritorio" style={{lineHeight:'1', display:'block'}}> {contacto.telefono?contacto.telefono:contacto.celular}</span></div>
	const redes= <div style={{float:'right', padding:'5px'}}> {FB}{WA}{IG}{TT}{YT}{TW} </div>	

	
    const cajita =  especialidad1?.socio?.map(fila  =>  <Fragment key={fila.item}><li  onClick={(e) => webSite(fila)} style={{float:'left', cursor:'pointer'}}>{fila.Nombre_Comercial}</li><i className='pi pi-calendar icoBoton' onClick={(e) => webSite(fila,'agenda')} style={{float:'right',margin:'0'}}></i><i className='pi pi-times icoBoton' onClick={(e) => props.subContacto(contacto.Contacto_ID,'0',fila.Contacto_ID,'del')} style={{float: 'right', margin: '0 0 0 10px', color:'red', 'display':props.preview === 'no'?'block':'none'}}></i></Fragment>);
    const listaEsp = especialidad?.map(lista => <Fragment key={lista.Arbol_ID}><li className={lista.uso!==null?"mas":''} onClick={() => lista.uso!==null?especialistas(contacto.Contacto_ID,lista.Arbol_ID, lista.item):''}>{lista.label}  <div className="masContacto" onMouseLeave={() => document.getElementsByClassName('masContacto')[lista.item].style.display='none'} style={{'display':'none', minHeight:lista.uso*2+'em'}}> <img  style={{width:'auto'}} className="masCajita" alt="loader" src={ruta+img.loader}/><ul style={{fontWeight:'bold', color:'#006aa7'}}>{cajita}</ul></div></li><i className='pi pi-user-plus icoBoton' onClick={(e) => subContacto(contacto.Contacto_ID,lista.Arbol_ID, lista.item)} style={{float: 'left', margin: '0 0 0 10px', color:'#bdbdbd', 'display':props.preview === 'no'?'block':'none'}}></i></Fragment>);
    return (
    <div id="panelEdicion" className="panelContacto">
      <Dialog visible={mostrar?true:false} style={{width: '50vw'}} modal onHide={() => setMostrar('')}>
      </Dialog>
      <img src={ contacto.tipo >= '3'?contacto.Foto?ruta + contacto.Foto:(contacto.tipo === '3' ? fotoM : fotoF):contacto.logo?ruta + contacto.logo:logo} alt="Foto de perfil" style={{background:contacto.color, width: contacto.tipo >= '3'?'15%':'210px', marginBottom:'1em', padding:contacto.tipo ==='2'?'10px':'0', float:'left'}}/>
      <h2 className='ancho' style={{float:'right', width:contacto.tipo >= '3'?'85%':'calc(100% - 210px)', textAlign:'center', padding: '0.5em', lineHeight:'1.4'}}>{'Panel de consultor de ' + contacto.Nombre_Comercial}</h2>
      <div className='ancho' style={{float:'right',width:'51%', overflow:'hidden'/*, background:'#f3f3f3', height:'4em', borderRadius:'5px'*/}}>
	  {['1','4'].includes(contacto.usoweb)?contacto.mensajeweb==='0'?'':contacto.mensajeweb==='1'?dispon:contacto.mensajeweb==='2'?agendar:contacto.mensajeweb==='3'?whatsapp:contacto.mensajeweb==='4'?gratis:contacto.mensajeweb==='5'?redes:contacto.mensajeweb==='6'?online:'':''} </div>
      <div style={{clear:'both', borderBottom:'1px solid #f2f2f2', margin:'7em -1.2em 1em'}}>         
	  </div>
      <Panelito contacto={contacto}/>
      <div className="acerca"> 
        <h2>Panel de consultor por especialidades</h2>
        <div id='listaCat' className="columnas" style={{clear:'both', minHeight:'4em'}}>
          <ul style={{listStyle:'inside',marginBlockStart:'0', minHeight:'20em'}}> 
            {listaEsp}
          </ul>
        </div>
      </div> 	
      {contacto.socio?<div id="panelSocio" className="acerca"> <img src={contacto.socio.Foto ? ruta + contacto.socio.Foto : contacto.socio.tipo === '3' ? fotoM : fotoF} alt="Foto de perfil" style={{float: 'left', width: '15%',padding: '0 1em 0 0'}}/>
      <h2 style={{float:'right', width:'85%'}}>{'Panel '+contacto.socio.Nombre_Cat.toLowerCase()+' con '+contacto.socio.Nombre_Comercial}</h2>
      <Panelito contacto={contacto.socio}/></div>:''}
      {contacto.booking?<div className="acerca"><h2>{'Calendario '+contacto.socio.Nombre_Cat.toLowerCase()+' con '+contacto.socio.Nombre_Comercial}</h2>
      <Calendario contacto={contacto.socio} socio={contacto}/></div>:''}
    </div>
    ); 



}





export const Panelito = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, contactosUser, api, fecha, monedas, horarioConfig, disponibilidad} = store;
const horarioData = useHorario(contacto); // Usamos el hook
const socio = contacto.Contacto_ID - props.contacto.Contacto_ID;
const [buffer, setBuffer] = useState(socio==='0'?contacto:props.contacto);
const [adms, setAdms] = useState([]);
const [persona, setPersona] = useState([]);
const [personaf, setPersonaf] = useState([]);
const descansos = [{value:'0', label:'Mantiene la atención'},{value:'1', label:'Periodo sin atención'}];
const mensaje = [{value:'0', label:'Sin mensaje a mostrar'},{value:'1', label:'Disponibilidad'},{value:'2', label:'Agendar citas'},{value:'3', label:'Contacto Whatsapp'},{value:'4', label:'Consulta gratuita'},{value:'5', label:'Redes sociales'},{value:'6', label:'Consultas On-line'}]
const [descanso, setDescanso] = useState('');
var futuro=false;


useEffect(() => {
if(adms.length===0){
  editores(props.contacto.Contacto_ID, '', '');
  }
  //eslint-disable-next-line
}, []);

const Fecha = (e) => {
	futuro = (new Date(e.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
	let hasta = 'HASTA '+ (new Date(e.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
	let fechaPanel = futuro?hasta:fecha;
	return fechaPanel;
}


const personaChange = (e) => {
	setPersona(e.value);
} 

const filtroPersonas = async (event) => {   
  var resource = [];
  var ubica = [];
   if(event.query.length >= 1){resource = await InfoData('persona', {nombre:event.query.toLowerCase(), Usu_ID:''}); 
	ubica = resource.data;}; 
    setTimeout(() => {
    var results = ubica.filter((persona) => {
    return persona.label.toLowerCase();
    });
    setPersonaf(results);
        }, 250);
    }

	
const editores = async (Contacto_ID, Usu_ID, accion) => {
    let resource = await InfoData('editor', {Contacto_ID:Contacto_ID, Usu_ID:Usu_ID, accion:accion}); 
	console.log(resource.data);
	setAdms(resource.data);
 }
 


const onUsowebChange = (e) => {
	/*Esta función no esta disponible para socios*/
	dispatch({ type: types.contacto, payload: {...contacto, usoweb:e.checked?'4':'1' } });
	guardar('usuweb', e.checked);
	}
	

const onvideollamadaChange = (e) => {
	if(socio===0){
	dispatch({ type: types.contacto, payload: {...contacto, videollamada:e.checked } });
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, videollamada:e.checked}} });
	}
	guardar('online', e.checked);
    }		
	
/*	
const onhorarioChange = (e) => {
	if(socio===0){
	dispatch({ type: types.contacto, payload: {...contacto, horario:e.checked } });
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, horario:e.checked}} });
	}
	guardar('horario', e.checked);
    }*/
	

const onPublicarTarifaChange = (e) => {
	if(socio===0){
	dispatch({ type: types.contacto, payload: {...contacto, vertarifa:e.checked } });
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, vertarifa:e.checked}} });
	}
	guardar('vercst', e.checked);
    }		
	
	
const onTarifaChange = (e) => {
	setBuffer({...buffer, tarifa:e.target.value});
    }		
	
	
const onMonedaChange = (e) => {
	setBuffer({...buffer, moneda:e.target.value});
    }

	
const onCerradohastaChange = (e) => {
 	let st=e.value;
 	let mes=st.getMonth()+1;
	setBuffer({...buffer, cerradohasta:st.getFullYear()+'-'+mes+'-'+st.getDate()});	
}		


const mensajeChange = (e) => {
	setBuffer({...buffer, mensajeweb:e.target.value});	
//	dispatch({ type: types.contacto, payload: {...contacto, mensaje:e.target.value } });
    }

const guardaForm = async (caso) => {
cancelCase(caso);
if (caso==='sms'){ guardar(caso,'');
	dispatch({ type: types.contacto, payload: {...contacto, mensajeweb:buffer.mensajeweb  } });
}
if (caso==='dat'){ guardar(caso,'');
	if(socio===0){
	dispatch({ type: types.contacto, payload: {...contacto, cerradohasta:buffer.cerradohasta, nodisponible:buffer.nodisponible } });
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, cerradohasta:buffer.cerradohasta, nodisponible:buffer.nodisponible}} });
    }
}
if (caso==='cst'){ guardar(caso,'');
	if(socio===0){
	dispatch({ type: types.contacto, payload: buffer})
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:buffer} });
    }
}
if (caso==='hor'){ guardar(caso,'');
	if(socio===0){
	dispatch({ type: types.contacto, payload: buffer});
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, socio:buffer} });
    }
}
}	
	
const guardar = async (caso, dato) => {	
		let resource = await registrarServer (caso, dato);
		console.log(props.contacto.Contacto_ID);
		console.log(contacto.Contacto_ID);
		
		let contactos=contactosUser;
		contactos[contacto.item]=contacto;
		dispatch({ type: types.contactosUser, payload: contactos }); 		
		
return resource.data;
}



const registrarServer = async (caso, dato) => {		
    var config = { headers: { 'content-type': 'multipart/form-data' } };
	const formData = new FormData();
	
	formData.append('accion', socio);	
	formData.append('tipo', caso);	
	formData.append('operacion', 6.2);
	formData.append('usoweb', dato?4:1);
	formData.append('mensajeweb', buffer.mensajeweb);	
	formData.append('vertarifa', dato?1:0);
	formData.append('videollamada', dato?1:0);
 	formData.append('nodisponible', buffer.nodisponible);	
	formData.append('tarifa', buffer.tarifa);
	formData.append('moneda', buffer.moneda);
    formData.append('cerradohasta', buffer.cerradohasta); 
	formData.append('Contacto_ID', props.contacto.Contacto_ID);
	formData.append('Socio_ID', contacto.Contacto_ID);
	formData.append('catID', props.contacto.Arbol_ID);
	formData.append('horario', buffer.horario);
	formData.append('start', buffer.inicio);
	formData.append('end', buffer.fin);
    formData.append('start1', buffer.inicio1); 
	formData.append('end1', buffer.fin1);
    formData.append('refrigerio', buffer.refrigerio); 
	formData.append('refriTiempo', buffer.entretiempo);
	
    return await axios.post(api, formData, config);
  } 
  
 
const editarCase = (caso) => { 
let item=0;
if(socio===0){item=0;}else{item=4;}
let a = document.getElementsByClassName(caso);
let b = ['dat', 'cst', 'hor', 'adm'];
a[item].classList.add('oculto'); 
a[item+1].classList.remove('oculto'); 
a[item+2].classList.add('oculto'); 
a[item+3].classList.remove('oculto');
for(let n in b){if(b[n]!==caso){cancelCase(b[n])}}
}

const cancelCase = (caso) => { 
let item=0;
if(socio===0){item=0;}else{item=4;}
let a = document.getElementsByClassName(caso); 
a[item].classList.remove('oculto'); 
a[item+1].classList.add('oculto');
a[item+2].classList.remove('oculto'); 
a[item+3].classList.add('oculto');
}
  	

console.log( props.contacto.creador)
const personal1 = adms.map((number) => <li key={number.item}><span style={{display:'inline-grid', cursor:'pointer'}}>{number.Usu_ID===props.contacto.creador?'Administrador - ':'Editor ------------ '}{number.Nombre}</span></li>);
const personal = adms.map((number) => <li key={number.item}><i className={number.Usu_ID===props.contacto.creador?'fa fa-briefcase icoBoton':'pi pi-pencil icoBoton'}></i><span style={{display:'inline-grid', minWidth:'80%', cursor:'pointer'}}>{number.Nombre}</span>
<i className='pi pi-times icoBoton' onClick={(e) => {if (window.confirm("¿Está seguro que desea eliminar el rol de usuario?")) if(number.Usu_ID===props.contacto.creador){alert('No puedes eliminar a un administrador')}else{editores(props.contacto.Contacto_ID, number.Usu_ID, 'borrar')}}} style={{ color:'red'}}></i></li>);
const glob = <i className="fas fa-globe" style={{marginRight:'0.3em',color:'#03a9f4', verticalAlign:'middle'}}></i>;
const horarioT = <div className="horario-list">
					{horarioData.periodos.map((periodo, index) => (
						<Fragment key={index}>
							<span>{periodo.dia}</span>
							<span>{periodo.horario}</span>
						</Fragment>
					))}
				 </div>; 
const hoy = <div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px', width:'140px', textAlign:'center', padding:'0', font:'14px / 1em fallback-font, Arial, sans-serif'}}>
				<div style={{padding:'5px'}}><i className="pi pi-calendar"  style={{color: '#03a9f4',verticalAlign:'text-top',float:'left' }}></i>{Fecha(props.contacto)}</div>
				<div style={{background:props.contacto.nodisponible>'1'?'#fffafa':'#f7fff2', padding:'5px',color:props.contacto.nodisponible>'1'?'red':'green'}}>{props.contacto.nodisponible>'1'?'Sin citas disponibles':'Citas disponibles'}</div>
			</div>;
return( <div className="p-grid p-fluid miniTxt" style={{ width:'100%', padding:'1em 0 1em 1em', clear: 'both'}}>

		{socio===0?(['1','4'].includes(contacto.usoweb))?<Fragment>
		<div className="p-col-3 ancho">Mensaje en tu web</div>
		<div className="p-col-8 sms campo">{mensaje[contacto.mensajeweb]?.label}</div>
		<div className="p-col-7 sms campo reg-content-wrapper oculto" style={{background:'#fafafa', border:'1px solid #d3d3d3'}}>
			<ul className="input-fields container-box">	
				<li className="cell-input">
					<div className="cell-body"><label className="float-label" htmlFor="inPeriodo">Mensaje en la cabecera de la página web</label>
					<Dropdown id="inMensaje" value={buffer.mensajeweb} options={mensaje} onChange={mensajeChange} placeholder="Mensaje en cabecera web"/></div>								
				</li>					
			</ul>
		</div>
		<div className="p-col-1 sms lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('sms')} >Editar</span></div>					
		<div className="p-col-2 sms lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('sms')}/><br/><Button  className="usuBoton" label="Guardar" onClick={() => guardaForm('sms')}/></div>
		</Fragment>:'':''}
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>			
		{socio===0?(['1','4'].includes(contacto.usoweb))?<Fragment>
		<div className="p-col-3 escritorio">Contacto web</div>
		<div className="p-col-6 ancho"><Checkbox inputId="binary" checked={props.contacto.usoweb==='4'?true:false} onChange={onUsowebChange} />
		<span style={{marginLeft:'1em'}}>Boton de <span style={{background:'#f9f7f6', borderRadius:'5px', padding:'7px', border:'1px solid #DDD', whiteSpace:'nowrap'}}>{glob}contacto web</span> en mi perfil</span></div>
		<div className="p-col-3 escritorio" style={{textAlign:'right'}}>
		<span className="escritorio" style={{textDecoration:props.contacto.usoweb?'auto':'line-through', color: props.contacto.usoweb ? '#000' : '#bbb', background:'#f9f7f6', borderRadius:'5px', padding:'7px', border:'1px solid #DDD'}}>{glob}Contacto web</span>
		</div></Fragment>:'':''}
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>			
		<div className="p-col-3 escritorio">Consultas en línea</div>
		<div className="p-col-6 ancho"><Checkbox inputId="binary1" checked={props.contacto.videollamada} onChange={onvideollamadaChange} />
		<span style={{marginLeft:'1em'}}>Habilitar consultas on-line</span></div>
		<div className="p-col-3 escritorio" style={{textAlign:'right'}}><span style={{textDecoration:props.contacto.videollamada?'auto':'line-through',color:props.contacto.videollamada?'#000':'#bbb'}}><i className="pi pi-video"  style={{marginRight:'0.3em',color:'#03a9f4', verticalAlign:'middle'}}></i>Consulta on-line</span></div>					
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>	
		<div className="p-col-3 ancho">Disponibilidad</div>
		<div className="p-col-8 dat campo"> 
		{contacto.nodisponible!=='0'?hoy:'Sin información'}
		</div>
		<div className="p-col-7 dat campo reg-content-wrapper oculto" style={{background:'#fafafa',padding:'1em 2em 1em 1em'}}> 
			<ul className="input-fields container-box">	
				<li className="cell-input">
					<div className="cell-body"><label className="float-label" htmlFor="inPeriodo">Indicador de disponibilidad</label>
					<Dropdown id="inPeriodo" value={buffer.nodisponible} options={disponibilidad} onChange={(e)=> setBuffer({...buffer, nodisponible:e.target.value}) } placeholder="Disponibilidad"/></div>				
				</li>
				<li className="cell-input" style={{display:buffer.nodisponible<'3'?'none':'block'}}>
					<div className="cell-body"><label className="float-label" htmlFor="inuser">Fecha de retorno a la disponibilidad</label>
					<Calendar id='fechaConsultor' value={(new Date(buffer.cerradohasta))} onChange={onCerradohastaChange} /*locale={es}*/  dateFormat="dd/mm/yy" showIcon={true} style={{marginRight: '2em'}}/></div>								
				</li>					
			</ul>
		</div>					
		<div className="p-col-1 dat lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('dat')} >Editar</span></div>	
		<div className="p-col-2 dat lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('dat')}/><br/><Button  className="usuBoton" label="Guardar" onClick={() => guardaForm('dat')}/></div>
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>	
		<div className="p-col-3 ancho">Horario de atención</div>
		<div className="p-col-8 hor campo">{horarioT}</div>
		<div className="p-col-7 hor campo reg-content-wrapper oculto" style={{background:'#fafafa', border:'1px solid #d3d3d3'}}>
			<ul className="input-fields container-box">			
				<li className="cell-input">
					<div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="inPeriodo">Atención de días periódicos</label>
					<Dropdown id="inPeriodo" value={buffer.horario} options={horarioConfig} onChange={(e)=> setBuffer({...buffer, horario:e.target.value}) } placeholder="Periodo de atención"/></div>				
					<div className="cell-body" style={{width:'22%', margin:'0 2%',float:'right'}}><label className="float-label" htmlFor="inStar">{buffer.horario==='0'?'Hasta':'Salida'}</label>
					<Calendar id="inStar" value={ new Date(new Date(new Date().setMinutes(buffer.fin)).setHours(buffer.fin/60))} onChange={(e) => setBuffer({...buffer, fin:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="12" />
					</div>
					<div className="cell-body" style={{width:'22%',float:'right'}}><label className="float-label" htmlFor="inEnd">{buffer.horario==='0'?'Desde':'Ingreso'}</label>
					<Calendar id="inEnd" value={ new Date(new Date(new Date().setMinutes(buffer.inicio)).setHours(buffer.inicio/60))} onChange={(e) => setBuffer({...buffer, inicio:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="12" />
					</div>
				</li>
				<li className="cell-input" style={{display:(buffer.horario==='0'||buffer.horario>='4'||buffer.horario==='')?'none':'block'}}>
					<div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="inPeriodo">Atención de día solitario</label>
					<InputText id="inStar" value={horarioConfig[buffer.horario]?.solo} style={{width:'89%'}}/></div>				
					<div className="cell-body" style={{width:'22%', margin:'0 2%',float:'right'}}><label className="float-label" htmlFor="inStar">Salida</label>
					<Calendar id="inStar" value={ new Date(new Date(new Date().setMinutes(buffer.fin1)).setHours(buffer.fin1/60))} onChange={(e) => setBuffer({...buffer, fin1:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="12" />
					</div>
					<div className="cell-body" style={{width:'22%',float:'right'}}><label className="float-label" htmlFor="inEnd">Ingreso</label>
					<Calendar id="inEnd" value={ new Date(new Date(new Date().setMinutes(buffer.inicio1)).setHours(buffer.inicio1/60))} onChange={(e) => setBuffer({...buffer, inicio1:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="12" />
					</div>
					</li>				
				<li className="cell-input" style={{display:buffer.horario==='0'?'none':'block'}}>
					<div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="inPeriodo">Cierre por refrigerio</label>
					<Dropdown id="inPeriodo" value={descanso} options={descansos} onChange={(e)=> {setDescanso(e.target.value); setBuffer({...buffer, entretiempo:e.target.value*60})}} placeholder="Descanso por refrigerio"/></div>							
					<div className="cell-body" style={{width:'22%', margin:'0 2%',float:'right'}}><label className="float-label" htmlFor="inStar">Duración</label>
					<Calendar id="inStar" value={ new Date(new Date(new Date().setMinutes(buffer.entretiempo)).setHours(buffer.entretiempo/60))} onChange={(e) => setBuffer({...buffer, entretiempo:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="24" />					
					</div>
					<div className="cell-body" style={{width:'22%',float:'right'}}><label className="float-label" htmlFor="inEnd">Inicio</label>
					<Calendar id="inEnd" value={ new Date(new Date(new Date().setMinutes(buffer.refrigerio)).setHours(buffer.refrigerio/60))} onChange={(e) => setBuffer({...buffer, refrigerio:e.value.getHours()*60+e.value.getMinutes()})} timeOnly hourFormat="12" />
					</div>
				</li>							
			</ul>					
		</div>
		<div className="p-col-1 hor lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('hor')} >Editar</span></div>					
		<div className="p-col-2 hor lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('hor')}/><br/><Button  className="usuBoton" label="Guardar" onClick={() => guardaForm('hor')}/></div>
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>	
		<div className="p-col-3 ancho">Tarifa de consulta</div>
		<div className="p-col-8 cst campo"><Checkbox inputId="binary" checked={props.contacto.vertarifa} onChange={onPublicarTarifaChange} /><span style={{marginLeft:'1em'}}>{props.contacto.tarifa!=='0'?'Publicar tarifa ('+(props.contacto.tarifa?props.contacto.tarifa:'no indica costo') +' '+ (monedas.find(item => item.value === props.contacto.moneda)?.label ||'')+' por consulta)':'Publicar tarifa (Consulta gratuita)'}</span></div>
		<div className="p-col-7 cst campo reg-content-wrapper oculto">
			<ul className="input-fields container-box">							
				<li className="cell-input">
					<div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="intar">Tarifa por turno</label>
					<InputText id="intar" value={buffer.tarifa} onChange={onTarifaChange}/></div>
					<div className="cell-body" style={{width:'48%',float:'right', height:'35px'}}><label className="float-label" htmlFor="inMoneda">Tipo de moneda</label>
					<Dropdown id="inMoneda" value={buffer.moneda} options={monedas} onChange={onMonedaChange} placeholder="Moneda"/></div>
				</li>								
			</ul>					
		</div>
		<div className="p-col-1 cst lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('cst')} >Editar</span></div>					
		<div className="p-col-2 cst lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('cst')}/><br/><Button  className="usuBoton" disabled={!['1','2','3','4'].includes(buffer.moneda)} label="Guardar" onClick={() => guardaForm('cst')}/></div>		
		<div className="p-col-12" style={{margin:'0.5rem'}}></div>				
		{socio===0?<Fragment>
		<div className="p-col-3 ancho">Administración</div>
		<div className="p-col-8 adm campo"><span >{'Página web creada por '+(props.contacto.creador_Nombre?props.contacto.creador_Nombre:'')}</span><ul>{personal1}</ul></div>
		<div className="p-col-7 adm campo oculto" style={{paddingLeft:'0'}}><ul>{personal}</ul>
		<label className="float-label reg-content-wrapper" htmlFor="personas">Asignar un administrador</label>
		<div className="cell-body" style={{display:'flex', flexWrap:'nowrap' }}>
		<AutoComplete style={{width:'100%'}} id="personas" value={persona} suggestions={personaf} completeMethod={filtroPersonas} field="label" size={30} placeholder="Escribe el nombre o el correo electrónico de un amigo" minLength={1} onChange={personaChange} />
		<Button label="Agregar" onClick={(e) => {if (window.confirm("¿Está seguro que desea asignar el rol de usuario?")) editores(props.contacto.Contacto_ID, persona.Usu_ID, 'agregar')}} style={{color:'#fff', fontWeight:'bold', margin:'0 1em', background:'#006aa7', padding:'0.5em 1em', width:'7em', border:'0' }}/>
		</div>		
		</div>
		<div className="p-col-1 adm lado"><span style={{color:'#00adef',cursor:'pointer'}} onClick={() => editarCase('adm')} >Editar</span></div>					
		<div className="p-col-2 adm lado oculto"><Button  className="usuBoton" label="Cancelar" onClick={() => cancelCase('adm')}/><br/><Button  className="usuBoton" label="Guardar" onClick={() => guardaForm('adm')}/></div>
		</Fragment>:''}
		</div>
);

}
import { TreeTable } from 'primereact/treetable';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MegaMenuSC } from "./MegaMenuSC";
import './layout/Megamenu.css';



import { InputText } from "primereact/inputtext";
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { StoreContext } from './store/StoreProvider';
//import { types }  from './store/StoreReducer';


var padre = {};
var ExpandedKeys = {};

export const Categoria = (props) => {

const {store} = useContext(StoreContext);
const {api} = store;
const [itemNuevo, setItemNuevo] = useState('');
const [expandedKeys, setExpandedKeys] = useState({});
//const [selector, setSelector] = useState('fas fa-check-square');
//const [seleccionFila, setSeleccionFila] = useState('');
const [cataloga, setCataloga] = useState(false);
const [menuSeleccionado, setMenuSeleccionado] = useState('');
const [menuUsado, setMenuUsado] = useState('');
const [Areas, setAreas] = useState('');
const [AreasPlano, setAreasPlano] = useState('');
const [cambioArea, setCambioArea] = useState('');
const [areaUpdate, setAreaUpdate] = useState('');
const [displayDialog, setDisplayDialog] = useState('');
const [menu, setMenu] = useState([]);
const [areasLista, setAreasLista] = useState([]);
//const [areaHijos, setAreaHijos] = useState('');
//const [keyPadre, setKeyPadre] = useState('');
const [espera, setEspera] = useState('');
const [altura, setAltura] = useState('360px');
const [selectedNodeKeys3, setSelectedNodeKeys3] = useState('');
const [categoriaNuevaCatalogo, setCategoriaNuevaCatalogo] = useState([]);
const [categoriaNuevaID, setCategoriaNuevaID] = useState('');
const [categoriaNuevaIDN, setCategoriaNuevaIDN] = useState('');

const tipoMenu = [{label:'general', value:'1'},{label:'Industrial', value:'2'}];
const menu1 = [
    {  label: 'Profesionales', icon: 'fas fa-user-tie', top: -120, items: [ ] },
    {  label: 'Técnicos y oficios', icon: 'fas fa-user-nurse', top: -220,  items: [ ]  },
    {  label: 'Vendedores', icon: 'fas fa-cash-register', top: -320,  items: [ ]  },
    {  label: 'Productores', icon: 'fas fa-seedling', top: -420,  items: [ ]  },
    {  label: 'Negocios', icon: 'fas fa-briefcase', top: -520,  items: [ ]  },
    {  label: 'Segunda mano', icon: 'pi pi-fw pi-users', top: -620,  items: [ ]  },
    {  label: 'Alquiler', icon: 'fas fa-car-alt', top: -720,   items: [ ]  },    
    {  label: 'Industrial', icon: 'fas fa-industry', top: -820,  items: [ ]  }
    ];
	

	
useEffect(() => {
  categorias('leer');
  listaCategorias('leer');
  //eslint-disable-next-line
}, []);


  const ParametroBus = (item, etiqueta, categoria) => {
    let data = {
      abueloID: item.abuelo || "",
      abuelo: categoria,
      padreID: item.papa || "",
      padre: etiqueta,
      Arbol_ID: item.Cat_ID,
      Arbol: item.label,
    };
    let query = {
      abueloID: item.abuelo || "",
      padreID: item.papa || "",
      Arbol_ID: item.Cat_ID,
    };
    let Categoria = categoria;
    let Etiqueta = etiqueta;
    let Label = item.label;
    let v = "/";
    let parametro = Categoria + v + Etiqueta + v + Label;
    parametro = parametro.split(" ").join("-");
	console.log({ data, query, parametro });
  //  dispatch({ type: types.categoria, payload: { data, query, parametro } });
  //  dispatch({ type: types.contactos, payload: [{ Contacto_ID: "0" }] });
  alert(parametro);
  };


  const getMenuClickData = (event,item) => {
	console.log(event);
	console.log(item);
    }

  const onMenuChange = (e) => {
  let menuUsado;
    if (e.value === '1') menuUsado=menu1;
    if (e.value === '2') menuUsado=menu;

	setMenuSeleccionado(e.value); setMenuUsado(menuUsado);
    }

  const crearMenu = (ID_padre,arbol) => {
  let hijos=[];
  let top=-120;
  for(var tallo in arbol){
    if (parseInt(arbol[tallo].padre) === ID_padre) {
       let items=[];
       let contador=0;
       let set=0;
       let por=1;
      for(var rama in arbol){
        if(arbol[rama].padre === arbol[tallo].Cat_ID && contador <= 12)  { 
             let lista=[];
             let conteo=0;
             let clase="";
             let padre="";
             for(var hoja in arbol){
                if(arbol[hoja].padre === arbol[rama].Cat_ID)  { 
                  if(conteo<3) {lista[conteo] = {label:arbol[hoja].label, Cat_ID:arbol[hoja].Cat_ID};}
                else{lista[conteo] = {label:arbol[hoja].label, Cat_ID:arbol[hoja].Cat_ID, padre:arbol[hoja].padre , oculto:true};}
                conteo=conteo+1;
                padre=arbol[hoja].padre;
                clase = document.getElementsByClassName('p-menuitem-link '+arbol[hoja].padre);
                }
                if(conteo>3) { 
                  //eslint-disable-next-line
                  lista[conteo] = {label:'Mostrar', padre:padre, mas:true ,command: () => {
                  for(let n in clase){if(!isNaN(n)){clase[n].classList.toggle('p-oculto')}} 
                  document.getElementsByClassName('p-mas '+padre)[1].classList.toggle('p-menos');
                  document.getElementsByClassName('p-mas '+padre)[1].classList.toggle('p-oculto');
                  if(document.getElementsByClassName('p-mas '+padre)[1].classList.value === padre+' p-menuitem-link p-mas')
                  {if(window.scrollY > 150){window.scrollTo(0, 0);}}
                }};}
              }
            if([0,6,24,54].includes(contador*por)){items[contador-set] = [{label:arbol[rama].label,Cat_ID:arbol[hoja].Cat_ID, items:lista}];}
            else{items[contador-1-set].push({label:arbol[rama].label, Cat_ID:arbol[hoja].Cat_ID, items:lista});por=set=set+1;}
            contador=contador+1;
          }
        }
    hijos.push({icon:arbol[tallo].icon, items:items, label:arbol[tallo].label, Cat_ID:arbol[hoja].Cat_ID, top:top});
    top=top-100;
    }
  }
  return hijos;  
  }



  const crearHijos = (ID_padre,arbol,baseString) => {
	  let hijos=[];
	  let children=[];
	  let contador=0;
	  let guion='';
	   
	  for(var rama in arbol){
		  if(parseInt(arbol[rama].padre) ===parseInt(ID_padre))  { 
			  let data=[];
			  data.Arbol_ID=arbol[rama].Arbol_ID;
			  data.Cat_ID=arbol[rama].Cat_ID;
			  data.label=arbol[rama].label;
			  data.padre=arbol[rama].padre;
			  data.abuelo=arbol[rama].abuelo;			  
			  data.uso=arbol[rama].uso;
			  data.icon=arbol[rama].icon;
			  data.item=arbol[rama].item;
				
			  if(baseString!==""){guion='-';}
			  children = crearHijos(arbol[rama].Cat_ID,arbol,baseString+guion+contador.toString());
			  hijos.push({key:baseString+guion+contador.toString(),data:data,children:children});
			  if (children.length>0){
				if (arbol[rama].padre!==''){ 
					padre.ultimoPadre=arbol[rama].padre;
					padre.itemUP=rama;
					padre.keyUP=baseString+guion+contador.toString()
					}
				ExpandedKeys[baseString+guion+contador.toString()] = true;
				}
			  if(rama===(arbol.length-1)){ 
				  padre.ultimoItem=arbol[rama].Cat_ID; padre.padreUI=arbol[rama].padre; padre.itemUI=arbol[rama].item;padre.keyUI=baseString+guion+contador.toString();
				  }
			  contador=contador+1;
			  }
		  if(arbol[rama].Cat_ID===arbol[arbol.length-1].padre){padre.itemUIP=rama;}
	  }
	  ExpandedKeys['0'] = true;
	  return hijos;  
  }




  const categorias = async (accion,evento) => {
	let resource = await servercategoria(accion,evento);
    let categorias=crearMenu(0,resource.data);
    let hijos=crearHijos(0,resource.data,"");
    let expandedKeysUpdate=expandedKeys;
    if (expandedKeysUpdate[0]===''){expandedKeysUpdate=ExpandedKeys;}
	// let AreasPlanoOrden= resource.data.sort(function (a, b) { if (a.label > b.label) {return 1;}  if (a.label < b.label) {return -1;}return 0;});

	setAreas(hijos); setAreasPlano(resource.data); setItemNuevo(''); setAreaUpdate(evento); 
	setMenu(categorias); setMenuUsado(categorias);
  }
  
  const servercategoria = async (accion,evento) => { var config = { headers: { 'content-type': 'multipart/form-data' }  };
	  const formData = new FormData();
	  formData.append('operacion', 31);
	  formData.append('accion', accion);
	  if(evento!==undefined){
	  formData.append('catID', evento.catID);
	  formData.append('catIDN', evento.catIDN);
	  formData.append('padre', evento.padre);
	  formData.append('abuelo', evento.abuelo);	  
	  formData.append('padreNuevo', evento.padreNuevo); }
    return await axios.post(api, formData, config);
  } 



  const listaCategorias = async (accion) => {
    let resource = await serverListaCategorias(accion);
	setAreasLista(resource.data); setCategoriaNuevaCatalogo('');
    } 

	
  const serverListaCategorias = async (accion) => {
    var config = { headers: {'content-type': 'multipart/form-data'} };
    const formData = new FormData();
    formData.append('operacion', 32);
    formData.append('accion', accion);
    if(accion==='agregar'){
    formData.append('categoriaNueva', categoriaNuevaCatalogo);}
    if(accion==='editar' || accion==='borrar'  ){
    formData.append('categoriaNombre', categoriaNuevaID);
    formData.append('catID', categoriaNuevaIDN);}
    return await axios.post(api, formData, config);
  } 


  const onSelect = (event) => {
    if(itemNuevo!==''){
		if(event.node.data.Cat_ID!==''){
	//  dataAreas('leer',ide);
		setItemNuevo('');	
		}
	}
    //let AreaHijos = event.node.children.length>0?true:false;
  
    let areas =[];  
    areas.catID = event.node.data.Cat_ID;
    areas.padre = event.node.data.padre;
    areas.item = event.node.data.item;
    if(event.node.data.item!==0){
		if(event.node.data.padre===AreasPlano[event.node.data.item-1].Cat_ID || event.node.data.padre===AreasPlano[event.node.data.item-1].padre)
		{
		areas.padreConSangria = AreasPlano[event.node.data.item-1].Cat_ID;
		areas.padreSinSangria = AreasPlano[event.node.data.item-1].padre;
		}else{
			for(let i in AreasPlano){
				if(event.node.data.padre===AreasPlano[i].Cat_ID){
				areas.padreConSangria = AreasPlano[i].Cat_ID;
				areas.padreSinSangria = AreasPlano[i].padre;
				}
			}
		}
    }
    if(areaUpdate!==areas && cambioArea===true){setCambioArea(false); }
	setAreaUpdate(areas); /*setAreaHijos(AreaHijos);*/
  }


  const toggleApplications = () => {
		let expandir = [];
        if (expandedKeys[0] === undefined)
        {expandir = {...ExpandedKeys};}
        else {expandir = {'10':''};}
        console.log(expandir);
		setExpandedKeys(expandir);
    }


 const Delete = (data) => {
    let evento =[];  
    evento.catID = data.Cat_ID;
    evento.catIDN = '';
    evento.padre = data.padre;
	console.log(evento);	
    if (window.confirm("¿Está seguro que desea borrar la categoría?")){
    categorias('borrar',evento);}
    }


  const agrega = (padreDirecto, abuelo) => {
  let key={};
  key.padreDirecto=padreDirecto;
	  if(itemNuevo===''){
		  let areasPlano = AreasPlano;
		  areasPlano.push({Cat_ID:'',padre:padreDirecto, abuelo:abuelo, label:'',item:AreasPlano?.length});
		  let hijos=crearHijos(0,areasPlano,"");
		  setAreas(hijos); setAreasPlano(areasPlano); setItemNuevo('agrega'); /*setKeyPadre(key);*/
	  }
  }

	
	const areaEditor = (props) => {
        return inputTextEditor(props, 'label'); //Nombre de categoría
    }
	
  
    const inputTextEditor = (props, field) => {
	  let CambioArea = cambioArea;
	  let nieto = props.node.children.length;
	  let abuelo = props.node.data['abuelo'];
      if(props.node.data['label']!==''){
	  if(areaUpdate !== props.node.data){CambioArea = false;}    //verifica que el campo editado no cambie a edicion a otros campos	  
       return (
         <span >
			<span style={{'display': CambioArea ===false ? 'inline-block':'none', backgroundColor:'#c9ff72', fontWeight: nieto !== 0 ? 'bold' : 'normal'}}> 
				{props.node.data[field]}  
				<i className="fas fa-times" style={{marginLeft: '15px', 'display': nieto === 0 ? 'inline-block' : 'none' }} onClick={e => { Delete(props.node.data)}} > </i> 
				<i className="fas fa-pen"   style={{marginLeft: '15px', 'display': nieto === 0 ? 'inline-block' : 'none' }} onClick={e => {setCambioArea(true); setAreaUpdate(props.node.data); setEspera(false); setTimeout(e => {if(espera === false)setCambioArea(false)},5000);}} ></i>
				<i className="fas fa-plus"  style={{marginLeft: '15px', 'display': (nieto !== 0 || abuelo === '0' ) ? 'inline-block' : 'none' }} onClick={e => {agrega(props.node.data['Arbol_ID'], props.node.data['padre']) }} ></i>
			</span>
			<span>  
				<Dropdown value={props.node.data['Cat_ID']} options={areasLista} onChange={e => onEditorValueChange2(props, e.target.value)} style={{width:'calc(100% - 60px)', 'display': CambioArea ===true ? 'inline-flex':'none'}} onMouseDown={ e => setEspera(true)} onContextMenu={ e => setCambioArea(false)} /> 
			</span>
		</span>  
        ); 
      }else{
        return (
		<span > 
			{props.node.data[field]}
			<Dropdown value={props.node.data[field]} options={areasLista} onChange={e => onEditorValueChange1(props, e.target.value)} style={{width:'calc(100% - 75px)', 'display':'inline-flex'}} /> 
		</span>
        );
        }  
    }


  const onEditorValueChange2 = (props, value) => {  
    let evento =[];  
    evento.catIDN = value;  
    evento.catID = props.node.data['Cat_ID'];  
    evento.padre = props.node.data['padre'];
    console.log(evento);
	setCambioArea(false); setAreaUpdate('');
	if (window.confirm("¿Está seguro que desea cambiar la categoría?")){
    categorias('editarArea', evento);}
    }   
 
 
  const onEditorValueChange1 = (props, value) => {
		let evento =[];  
		evento.catID = value; 
		evento.catIDN = '';
		evento.padre = props.node.data.padre;
		evento.abuelo = props.node.data.abuelo;
		console.log(evento);
		if (window.confirm("¿Está seguro que desea agregar esta categoría?")){
		categorias('agregar',evento);}
  } 




	const campoVariable = (node, column) => {
	let icono = node.data.icon || "fas fa-spell-check";

	return (
	<div>
	  <Button icon={icono} onClick={ e => {console.log(node);console.log(icono);}} style={{height:'1.5em',width:'1.5em'}}/>
	</div> );

	}

  
      const header =  <div style={{textAlign:'left'}}>
      <Button icon="fas fa-plus" label="Catalogar categoría" onClick={e => {let Cataloga=!cataloga; setCataloga(Cataloga); setDisplayDialog(true); }} style={{height:'1.5em',width:'12.5em'}}/> 
      <Button icon="fas fa-sync-alt" label="Actualizar árbol" onClick={ e => {categorias('leer'); listaCategorias('leer');}} style={{height:'1.5em',width:'11.5em', marginLeft:'0.5em'}}/>
      <Button icon="fas fa-arrows-alt" label="Expandir/contraer árbol" onClick={toggleApplications} tooltip="Expande o contrae el árbol" style={{height:'1.5em',width:'15em',marginLeft:'0.5em'}}/>
      <Button icon="pi pi-window-maximize" onClick={ e => {setAltura('8000px');}} style={{float:'right', height:'1.5em',width:'1.5em', marginLeft:'0.5em'}}/>
      <Button icon="pi pi-window-minimize" onClick={ e => {setAltura('360px');}} style={{float:'right', height:'1.5em',width:'1.5em', marginLeft:'0.5em'}}/>

      </div>;
      const plantilla = (node) => {
		  let label = node.data.label;
		  let fontWeight = node.children.length !== 0 ? 'bold' : 'normal';
		  return <span style={{fontWeight: fontWeight}}>{label}</span>;
      };
        
      return  (
      <div className="p-fluid dashboard" style={{width:'95%', margin:'auto', marginTop:'1em'}}>
      <div className="row category-tree">
        
          <TreeTable value={Areas} header={header}  selectionMode={false} selectionKeys={selectedNodeKeys3} onSelectionChange={e => setSelectedNodeKeys3(e.value)} onSelect={onSelect} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)} scrollable scrollHeight={altura} >
              <Column body={campoVariable} style={{textAlign:'center', width: '3em', padding: '0'}}/>  
              <Column field="label" header="Categoría" editor={areaEditor} expander body={plantilla} style={{width: '54%'}}/>
              <Column field="Arbol_ID" header="Arbol_ID" sortable={true} style={{textAlign:'center', width: '8em'}} />
              <Column field="Cat_ID" header="Cat_ID" style={{textAlign:'center', width: '7em'}} />
              <Column field="padre" header="Padre" style={{textAlign:'center', width: '7em'}} />
              <Column field="abuelo" header="Abuelo" style={{textAlign:'center', width: '7em'}} />			  
              <Column field="uso" header="uso" style={{textAlign:'center', width: '7em'}} />
              <Column field="item" header="item" sortable={true} style={{'width': '6em', 'textAlign': 'center'}}></Column>
          </TreeTable>   
       
        </div>
      
      <Dialog visible={displayDialog} style={{width: '50vw'}} header="Catalogar categoría nueva, editar categoría" modal={true} onHide={() => setDisplayDialog(false) }>
          {
      <div className="p-grid">
        <div className="p-col-12 p-md-12" style={{padding:'10px'}}> </div>
        <div className="p-col-12 p-md-8">  <span className="p-float-label"> <InputText id="inAreaNueva" style={{width:'100%'}} value={categoriaNuevaCatalogo} onChange={(e) => setCategoriaNuevaCatalogo(e.target.value) } /> <label htmlFor="inAreaNueva">Ingresar nuevo nombre de categoría al catálogo</label> </span>  </div>  
        <div className="p-col-12 p-md-2"> <Button label="Agregar" icon="pi pi-check" onClick={ e => {listaCategorias('agregar');}}/> </div>
        <div className="p-col-12 p-md-2"> <Button label="Salir" icon="pi pi-times" onClick={ e => {setCataloga(false) }}/> </div>
        <div className="p-col-12 p-md-12" style={{padding:'7px'}}> </div>
        <div className="p-col-12 p-md-8"> <Dropdown value={categoriaNuevaID} editable={true} options={areasLista} onChange={e => {if(isNaN(e.target.value)){ setCategoriaNuevaID(e.target.value)}else{ setCategoriaNuevaID(e.target.value); setCategoriaNuevaIDN(e.target.value); }}} placeholder="Seleccionar Categoría del catálogo a Editar" style={{'width': '100%'}}/> </div>  
        <div className="p-col-12 p-md-2"> <Button label="Actualizar" icon="pi pi-check" onClick={ e => {listaCategorias('editar');}}/> </div>
        <div className="p-col-12 p-md-2"> <Button label="borrar" icon="pi pi-times" onClick={ e => { if (window.confirm("¿Está seguro que desea borrar el evento?")){listaCategorias('borrar');}}}/> </div>
        <div className="p-col-12 p-md-12" style={{padding:'7px'}}> {categoriaNuevaID} </div>
      </div>
          }
      </Dialog> 
      
      <div className="main-content-menu main-first-floor" style={{padding: '6em 0'}}>
      <div className="row category-banner-ta">
        <div className="fix-content fix-content-left">
            <div className="fix-content fix-content-right">
            </div>
            <div className="colu col-right">
            </div>
        </div> 
          
        <div className="colu col-left"> 
           <div className="home-category">
                <div className="category-left">
                    <div className="left-title" style={{borderColor:'#F4F4F4'}}>
                        <div style={{background:'none', color:'#333'}}>
                         <Dropdown value={menuSeleccionado} options={tipoMenu} onChange={onMenuChange} 
                         placeholder="MIS CONTACTOS"/>
                          </div>
                    </div>
                    <div className="left-expo" style={{display:'none'}}>
                    </div>
      
                    <div className="menu-category"> 
					<MegaMenuSC isMobile={false} model={menuUsado} parametros={[]} id="lateral" ParametroBus={ParametroBus} getMenuClickData={getMenuClickData} orientation="vertical"/>
                    </div>
                </div>
           </div>  
        </div>
        
        </div>
        </div>
      
        </div>
       );



}

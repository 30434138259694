import classNames from 'classnames';
import { useContext, useState, useRef, useEffect, Fragment } from 'react';
import { Link /*, useHistory*/ } from 'react-router-dom';
import { Agenda } from './Agenda';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { StoreContext } from './store/StoreProvider';
import { useHorario } from './hooks/useHorario';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";
import Barras from './components/loaders/Barras';
import './layout/Contacto.css';
import { Widget, addResponseMessage, dropMessages, toggleWidget,  /*addUserMessage, renderCustomComponent*/  } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';


export const Contacto = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, especialidad, categorias, zona } = store;
const [pag404, setPag404] = useState(false);
const [cargo, setCargo] = useState(false);
const lateral = window.innerWidth>'800'?true:false;
//const history = useHistory();
//console.log(props.match.params);

useEffect(() => {
  ContactoData(props.match.params.userweb);
  dropMessages();
  //eslint-disable-next-line
}, [contacto?.Contacto_ID!=='']);


const verifica = (nombre) => { 
let web1=props.match.params.contacname?.split('_C')[0];
let web2=nombre?.split(' ').join('-');
//console.log(web1);
//console.log(web2);
if(web1!==web2){
//history.push({pathname:"/"})
setPag404(true);
}
setCargo(true);
}


const visita = async (contacto, tipo) => {
    //let db = 
	await InfoData('visitaCto', {contacto:contacto,tipo:tipo});  
	//console.log(db.data)
}

	
const ContactoData = async (userweb) => {
	let interna = contacto.load==='porListado'?true:false;
	let resource = await InfoData( types.contacto, userweb);
	if(props.match.params.contacname.split('_')[1]==='C')
	{dispatch({ type: types.contacto, payload: { ...contacto, ...resource, load:'porContacto', booking:'agenda' } });}
	else{
	dispatch({ type: types.contacto, payload: { ...contacto, ...resource, load:'porContacto'} })
	}
    Especialidades(resource?.Contacto_ID || contacto.Contacto_ID);		
	verifica(resource.Nombre_Comercial || contacto.Nombre_Comercial);
	if(interna){
	visita(resource.Contacto_ID || contacto.Contacto_ID, 'i'); 
	//console.log(resource.visitaCtoIn+' interno '+resource.Contacto_ID)
	}
	else{
	visita(resource.Contacto_ID, ''); 
	//console.log(resource.visitaCtoEx+' externo '+resource.Contacto_ID)
	}	
}

  
const Especialidades = async (Contacto_ID) => {
    let cadena = await InfoData( types.especialidad, {Contacto_ID:Contacto_ID} );
	 cadena =  cadena.data;
    let cadena1 = cadena.pop(); 	
	dispatch({ type: types.especialidad, payload: cadena });
	dispatch({ type: types.categorias, payload: cadena1 });
}  

const i = <i className='pi pi-chevron-right' style={{margin:'0 7px', fontSize:'9px'}}/>
//  console.log(contacto.distrito);
  return (
    <Fragment>
      {!pag404?<div className="marco">
        <Link to= {'/'+zona.data?.Pais+'/'+contacto.Nombre_Departamento?.split('-').join(' ')+'/'+contacto.Nombre_Distrito?.split('-').join(' ')+'/categoria/'+contacto.Nombre_Padre+"/"+contacto.Nombre_Cat+'?c='+categorias[0]+'&d='+contacto.distrito}> 
        <div className="rutaweb contactoN" style={{'visibility':cargo?'visible':'hidden', paddingLeft:'0.5em'}} onClick={(e)=> dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] })}>{zona.data?.Pais}{i}{contacto.Nombre_Departamento?.split('-').join(' ')}{i}{contacto.Nombre_Distrito?.split('-').join(' ')}{i}{contacto.Nombre_Padre}{i}{contacto.Nombre_Cat}</div>
        </Link>
        <div className="layout-Seeker contactoN" style={{marginTop: '5px'}}>
		{!cargo?<Barras/>:
        <Superpanel lateral={lateral} url={props.match.url} contacto={contacto} especialidad={especialidad} categorias={categorias.length}/>}
        </div>
      </div>:
	   <div style={{height:'calc(100vh - 114px)'}}>
			<div style={{top:'50%', left:'50%', position:'absolute', transform:'translate(-50%, -50%)', textAlign:'center'}}>
			<h1>Esta página no esta disponible</h1>
			<p>Es posible que el enlace este roto o que se haya eliminado</p>	
			<p>la página. Verifica que el enlace que quieres abrir es correcto</p>
			<Link><Button  className="usuBoton" label="Ir al inicio"/></Link>
			</div>
	   </div>}    
    </Fragment>    
    );

}



const Semaforo = ({ tipo }) => {
  const message = tipo === '3' || tipo === '4' ? 'SATURADO DE TRABAJO' : 'LOCAL REPLETO';
  //console.log(tipo);
  return (
    <div className="semaforo">
      <span>{message}</span>
    </div>
  );
};



export const Superpanel = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, especialidad, ruta, img, fecha, monedas } = store;
const horarioData = useHorario(contacto); // Usamos el hook
const [mostrar, setMostrar] = useState(false);
const [reporta, setReporta] = useState(false);
const [chat, setChat] = useState(false);
const toast = useRef(null);
const fotoF = ruta+img.fotoF;
const fotoM = ruta+img.fotoM;
const logoC = ruta+img.logoC;
const logoB = ruta+img.logoB;
const logoT = ruta+img.logoT;
const logoQ = ruta+img.logoQ;
const fotoI = ruta+img.fotoI;
const calendar = ruta+img.calendar;
const tiktok = ruta+img.tiktok;
const instagram = ruta+img.instagram;
const imagen = [{img:fotoI},{img:logoC },{img:logoB},{img:fotoM},{img:fotoF},{img:logoT },{img:logoQ}];
const [especialidad1, setEspecialidad1] = useState([]); 
var futuro=false;
const [mostrarCompleto, setMostrarCompleto] = useState(window.innerWidth  > 800 ? true : false );
const textoBreve = contacto.Presentacion?.split('</p>')[0] + '</p>';


const Fecha = (e) => {
futuro = (new Date(e?.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
let hasta = 'HASTA '+ (new Date(e?.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
let fechaPanel = futuro?hasta:fecha;
return fechaPanel;
}
	
		
const salto = (id) => { 
window.scrollTo({top: document.getElementById(id).offsetTop - 120, behavior:'smooth'})
 }

 
const especialistas = (id,catID,item) => { 
	document.getElementsByClassName('masContacto')[item].style.display='block';
	if(item!==especialidad1.item){
		Especialistas(id,catID,item);
			if(typeof document.getElementsByClassName('masContacto')[especialidad1.item] !== 'undefined'){
			document.getElementsByClassName('masContacto')[especialidad1.item].style.display='none';}
	}
	especialidad.map(lista => columnas(lista));  
	if(especialidad1.uso >1 ){document.getElementById('listEsp').style.minHeight = especialidad1.uso*2+'em';}
 }
 

const columnas = (cadena) => {
if (cadena.label.length > 32){document.getElementsByClassName("columnas")[0].style.columnCount='2'}
if (cadena.uso >1 ){document.getElementById('listEsp').style.minHeight = cadena.uso*2+'em';}
}
 

const Especialistas = async (Contacto_ID,catID,item) => {
	if(item!==especialidad1.item){setEspecialidad1({...especialidad1, socio:[]});}
	document.getElementsByClassName('masCajita')[item].style.display='block';
    let cadena = await InfoData('especialista', {contacto:Contacto_ID,catID:catID});
	cadena = cadena.data;
	//console.log(cadena);
	cadena.pop(); 	
 	setEspecialidad1({...especialidad1, socio:cadena, item:item});
	document.getElementsByClassName('masCajita')[item].style.display='none';
  } 
 
 
const webSite = (e,modo) => {
	dispatch({ type: types.contacto, payload:{...contacto, socio:e, booking:modo}   })
	document.getElementById('quilla1').innerHTML = e.Presentacion;
    }


const handleNewUserMessage = async (newMessage) => {
    console.log(`Mensaje del usuario: ${newMessage}`);
    let respuesta = await InfoData( 'chat', {mensaje:newMessage, Contacto_ID:contacto.Contacto_ID} );
    //console.log(`Mensaje del usuario: ${respuesta}`);	
	//console.log(respuesta.data.reply);	
	addResponseMessage(`${respuesta.data.reply}`);
    // Aquí conectarías con tu API de IA
    // Por ahora, solo respondemos con un eco
    //setTimeout(() => {
    //  addResponseMessage(`Recibí tu mensaje: ${newMessage}`);
    //}, 1000);
  };	
	
const chatGpt = () => {
	setChat(!chat);
	setMostrar(false);
	!chat && setTimeout(() => {toggleWidget(); addResponseMessage(`¡Hola! ¿En qué puedo ayudarte?`);}, 2000);
	!chat && setTimeout(() => {toggleWidget(); }, 5000);
	}	
	
const enlace = () => {
    let url = 'https://servicontacto.com' + props.url;
	
  // Usar la API navigator.clipboard para copiar al portapapeles
    navigator.clipboard.writeText(url)
      .then(() => {
        // Mostrar el Toast en caso de éxito
        toast.current.show({
          severity: 'success',
          summary: 'URL',
          detail: 'Dirección copiada a portapapeles',
          life: 3000 // Duración en milisegundos (3 segundos)
        });
      })
      .catch((err) => {
        // Método alternativo si clipboard falla
        const tempInput = document.createElement('input');
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);

        // Mostrar el Toast incluso si usamos el método alternativo
        toast.current.show({
          severity: 'success',
          summary: 'URL',
          detail: 'Dirección copiada a portapapeles',
          life: 3000
        });
      });
	  setMostrar(false);
  };
	
const oos = () => {
	setReporta(!reporta);
	setMostrar(false);
	}
	

	
/*Zona de renderización*/	

const icons = 7-(1+(contacto.Tiktok?1:0)+(contacto.Instagram?1:0)+(contacto.Facebook?1:0)+(contacto.Twitter?1:0)+(contacto.Youtube?1:0)+(contacto.celular?1:0));
const anchoImg = String(icons<2?(11.5+2.5*icons):icons<4?(7+5*icons):22)+'vw';
const anchoIco = String(icons<2?(12.5+3*icons):icons<4?(8+icons*5.5):24.5)+'vw';
const dia = <span  className="calendario" style={{ fontSize:window.innerWidth>'800'?'14px':'28px'}} onClick={e => dispatch({ type: types.contacto, payload: {...contacto, booking:contacto.booking?'':'agenda'} })}>{new Date().toLocaleDateString("es-PE", {day: "numeric" })}</span>;
const mes = <span className="calendario" style={{top:32-2*icons+'%'}}>{new Date().toLocaleDateString("es-PE", {month: "long" })}</span>;
const booking = <Agenda contacto={props?.contacto} socio={props?.socio}/>;
const agenda = <i className="pi pi-calendar icoBoton" onClick={ e => dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} })} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto','display':/*props.preview === 'ver'*/true?'block':'none' }}></i>;
const agendar = <div style={{float:'left', position:'relative', cursor:'pointer'}} onClick={e => dispatch({ type: types.contacto, payload: {...contacto, booking:contacto.booking?'':'agenda'} })}>{mes}{dia}<img className="imgPag" alt='' src={calendar} style={{width:'calc('+anchoImg+' + 0.5vw)'}}/></div>
const linkWeb = props.router?<i className="fas fa-globe" style={{color: '#1877f2'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto web</span></i>:
<Link to= {"/"+contacto?.userweb}><i className="fas fa-globe" style={{color: '#1877f2'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto web</span></i></Link>;
const TT = contacto.Tiktok?<a href={contacto.Tiktok} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={tiktok} style={{width:anchoImg}}/></a>:'';
const IG = contacto.Instagram?<a href={contacto.Instagram} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={instagram} style={{width:anchoImg}}/></a>:'';
const FB = contacto.Facebook?<a href={contacto.Facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-square icoPag" style={{fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';
const TW = contacto.Twitter?<a href={contacto.Twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter icoPag" style={{color: '#1d9bf0', fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';	
const YT = contacto.Youtube?<a href={contacto.Youtube} target="_blank" rel="noreferrer"><i className="fab fa-youtube icoPag" style={{color: '#f70000', fontSize:'calc('+anchoIco+' - 5vw)', padding:'7% 0'}}></i></a>:'';
const WA = contacto.celular?<a href={'https://wa.me/'+contacto.DDI+contacto.celular} target="_blank" rel="noreferrer"><i className="fab fa-whatsapp-square icoPag" style={{color: '#44e561', fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';
const linkSite =  props.router?<i className="fas fa-home" style={{color: '#f9f9f9', margin:'0 8px', fontSize:'1em'}}></i>:
	  <Link to = '/modelo/web'><i className="fas fa-home" style={{color: '#f9f9f9', margin:'0 8px', fontSize:'1em'}}></i></Link>;
const pagina = <a href={contacto.web} target="_blank" rel="noreferrer" style={{fontFamily: 'times'}}><i className="fas fa-globe" style={{color: '#1877f2',margin:'0 3px' }}></i>Página web</a>
const className = classNames('p-grid panelContacto', props.className);
const titulo = contacto?.tipo >=3? contacto.Nombre_Cat_Solo?props.categorias>1?contacto.Nombre_Padre_solo+' '+ contacto.Nombre_Cat?.toLowerCase():contacto.Nombre_Cat_Solo:contacto.Nombre_Padre_solo+' '+ contacto?.Nombre_Cat?.toLowerCase():
				contacto?.Nombre_Padre_Grupo+' '+ contacto.Nombre_Cat?.toLowerCase();
				
				
			
const cajita =  especialidad1?.socio?.map(fila  =>  <Fragment key={fila.item}>
				<li  onClick={(e) => webSite(fila)} style={{float:'left', cursor:'pointer'}}>{fila.Nombre_Comercial}</li>
				<i className='pi pi-calendar icoBoton' onClick={(e) => webSite(fila,'agenda')} style={{float:'right', margin:'0'}}></i>
				<i className='pi pi-times icoBoton' onClick={(e) => props.subContacto(contacto.Contacto_ID,fila,'del')} style={{float: 'right', color:'red', 'display':props.preview === 'no'?'block':'none'}}></i>
				</Fragment>);
const listaEsp = especialidad?.map(lista => <Fragment key={lista.Arbol_ID}>
				<li className={lista.uso!==null?"mas":''} style={{background:lista?.Arbol_ID===contacto.editArea?.Arbol_ID?'#dcefff':'', width:'110%'}}>
					<span onClick={() => lista.uso!==null?especialistas(contacto.Contacto_ID,lista.Arbol_ID, lista.item):''}>{lista.label}</span>  
					<div className="masContacto" onMouseLeave={() => document.getElementsByClassName('masContacto')[lista.item].style.display='none'} style={{'display':'none', minHeight:lista.uso*1.5+'em'}}> 
					<img  style={{width:'auto'}} className="masCajita" alt="loader" src={ruta+img.loader}/>
						<ul style={{fontWeight:'bold', color:'#006aa7'}}>
						{cajita}
						</ul>
					</div>
					<i className='fas fa-pen icoBoton' onClick={(e) => dispatch({ type: types.contacto, payload: {...contacto, editArea:lista} })} style={{float: 'right', margin: '0 0 0 10px', color:'#bdbdbd', 'display':props.preview === 'no'?'block':'none'}}></i>
				{contacto.tipo >=3?'':<i className='pi pi-user-plus icoBoton' onClick={(e) => props.subContacto(contacto.Contacto_ID, lista ,'add')} style={{float: 'right', margin: '0 0 0 10px', color:'#bdbdbd', 'display':props.preview === 'no'?'block':'none'}}></i>}
				</li></Fragment>);
const caso = ((contacto.tarifa!=='0'||contacto.vertarifa)?1:0) + ((contacto.usoweb==='0'&&!contacto.web)?0:1);
const ancho = String(caso<2?(49-17*caso):(49-17*(1+1/caso)))+'%';				
const barraMenu = <Fragment>
		<ul className="menuCto" style={{margin:'0'}}>
		<li style={{width:caso<2?ancho:'calc('+ancho+' + 7%)', marginRight:'2%', display:contacto.usoweb==='0'&&!contacto.web?'none':'block'}}><div>{contacto.web?contacto.usoweb==='4'?linkWeb:pagina:contacto.usoweb==='0'?'':linkWeb}</div></li>
		<li onClick={(e) => salto('ubicacion')} style={{width:ancho}}><i className="fas fa-map-marker-alt" style={{color: '#18b32b'}}><span style={{color: '#777'}}> Ubicación</span></i></li>
		<li onClick={(e) => {salto('telefono'); setChat(!chat)}} style={{width:ancho, marginLeft:'2%'}}><i className="fas fa-phone-alt" style={{color:'#3F51B5'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto</span></i></li>
		<li onClick={(e) => salto('tarifa')} style={{width:caso<2?ancho:'calc('+ancho+' - 7%)', marginLeft:'2%','display':(contacto.tarifa!=='0'||contacto.vertarifa) ? 'block':'none'}}><i className="fas fa-dollar-sign" style={{color:'#1f9f2f'}}><span style={{color: '#777', marginLeft:'5px'}}>Tarifa</span></i></li></ul>
	  </Fragment>;
const verif = <><i className="fa fa-certificate" style={{color: '#1d9bf0', margin:'0 8px', fontSize:'15px', verticalAlign:'middle'}}></i><i className="fa fa-check" style={{color: '#fff', fontSize:'7px', margin:'-19px',  verticalAlign:'middle'}}></i></>
const nombreCto = <h1 className='h1M'>{contacto.Nombre_Comercial}{contacto.verificado==='1'?verif:''}</h1>;
const conFoto = props.router ? true : (contacto.Foto !== '' || contacto.logo !== '');

const reportar =   <ul className="report-list">
					<li className="report-button" onClick={(e)=> oos()}>{contacto.tipo ==='3' || contacto.tipo ==='4' ? 'saturado de trabajo':'Local repleto'}</li>
					<li className="report-button" onClick={enlace}>Copiar enlace </li>
					<li className="report-button" onClick={()=> chatGpt()}>Chat </li>	
					{/*<li className="report-button">Cerrado</li>
					<li className="report-button">Sin público</li>
					<li className="report-button">Objeto perdido</li>
					<li className="report-button">Libro de reclamación</li>*/}
				  </ul>;

return (
<Fragment>
{props.editBoton?<div style={{position:'relative', width:'100%', textAlign:'end'}}>{props.editBoton}</div>:''}
<div className={className} style={{...props.style , marginBottom:'20px'}}>
<Toast ref={toast} />
<Dialog className="anchoc" visible={mostrar} style={{width: '50vw'}} modal onHide={() => setMostrar(false)} header="Reportar un problema">
{reportar}
</Dialog>
	{chat && <Widget handleNewUserMessage={handleNewUserMessage} title="Asistente Virtual" subtitle="Estamos para ayudarte, pregunta lo que quieras"  fullScreenMode={false} showCloseButton={true}/>}
	<style> {/*color del chat*/} 
	{` .rcw-header   { background-color: ${reporta?"#dc3545":""} !important; }
       .rcw-launcher { background-color: ${reporta?"#dc3545":""} !important; } `}
	</style>
	{reporta && <Semaforo tipo={contacto.tipo} />}

	<div className="movilMini" style={{textAlign:'center', width: '100vw', margin:'0.8rem -0.4rem 0.3rem'}}>{nombreCto}</div> 
	<div className="sangria" style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
	{conFoto && 
	<div className="p-col-3 col-foto">  
		<div className="alturaFoto">  
		<img className={contacto.tipo >=3?'fotoContacto panelM':'logoContacto'} alt="imagen" src={props.contacto.tipo ==='3' || props.contacto.tipo === '4'?contacto.Foto?ruta+contacto.Foto:imagen[contacto.tipo?contacto.tipo:'0'].img:contacto.logo?ruta+contacto.logo:imagen[contacto.tipo?contacto.tipo:'0'].img} style={{background:contacto.tipo ==='2'?contacto.color:'none',padding:contacto.tipo ==='2'?'0.5em':'0', borderRadius:contacto.tipo <'3'?'':'1em'}}/>   
		</div>
	</div>}
	<div className= {conFoto ? "p-col-7 col-dato" : "p-col-10"} style={{position:'relative'}}> 
		<div className="p-grid box-dato sangria">
			<div className="p-col-12" style={{fontSize:'14', padding:'10px 0 0 10px', lineHeight:'1.5'}}><span className="movilGrande">{nombreCto}</span><Fragment>{titulo}<span className='mas'><span onClick={(e) => salto('listaCat')}>{props.categorias>1?', '+(props.categorias - 1)+' especialidades más':''}</span></span></Fragment></div>
			<div className="p-col-12" style={{top:'0.5em', lineHeight:'1.5', marginBottom:window.innerWidth>500?'40px':'0'}}>{"Distrito de "+contacto.Nombre_Distrito+", "+contacto.Nombre_Provincia}</div> 
		</div>
		<div className="p-col-12 movil500mas barraHeight sangria" > {barraMenu}</div> 		
	</div>
	<div className="p-col-2 col-lado">  
		{/*props.editBoton*/}
		
		<p style={{cursor:'pointer'}}><i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'-2px 3px 0 0'}}></i><span style={{fontWeight:'bold', float:'left', color:'#03a9f4'}}><Link to = {"/feedback/"+contacto.userweb+'/'}>{contacto.alcance}</Link> </span>{'('+contacto.votos+' opiniones)'}</p>		
		{(contacto.tipo >=3 && contacto.videollamada )?<p style={{textAlign:'left'}}><i className="pi pi-video"  style={{float:'left',color:'#03a9f4',margin:'-1px 4px 0 0'}}></i>Consulta on-line</p>:''}
		{contacto.tipo >=3?contacto.nodisponible!=='0'?<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
			<div style={{padding:'5px'}}>{agenda}{Fecha(contacto)}</div>
			<div style={{background:contacto.nodisponible>'1' || futuro?'#fffafa':'#f7fff2', padding:'5px',color:contacto.nodisponible>'1' || futuro?'red':'green'}}>{contacto?.nodisponible>'1' || futuro?'Sin citas disponibles':'Citas disponibles'}</div>
		</div>:'':''}
		<div style={{textAlign:'left', width:'100%', position:'absolute', bottom:'10px'}}>{agendar}{WA}{FB}{IG}{TT}{/*YT*/}{/*TW*/}</div>
	</div>
	</div>
	<div className="p-col-12 movil500menos" style={{padding:'0.5rem 0'}}>{barraMenu}</div>


	
	<div className="p-col-12 movil500menos" style={{padding:'0.5rem 0', display:'flex'}}>{contacto.tipo>2?agendar:''}{WA}{FB}{IG}{TT}{YT}{TW}</div>	
	{contacto.tipo >= 3 ? contacto?.booking ? booking : '' : ''}
	<div className="cuadro">	
		{props.editorweb}	{/*
		<div className="acerca"> 
			<h2>{contacto.tipo >=3?"Acerca de mi":"Acerca de Nosotros"}</h2>
			<span className="presentacion" id="breve" dangerouslySetInnerHTML={{ __html: mostrarCompleto ? contacto.Presentacion : textoBreve }}></span>
			<p id="masp" className="mas" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setMostrarCompleto(!mostrarCompleto)} >
                {mostrarCompleto ? "Leer menos" : "Leer más"}
            </p>
		</div>*/}
		
		
		<br/>
		<div className="acerca">
		  <div className="title-container">
			<h2 className="section-title" onClick={()=>addResponseMessage('¡Bienvenido a '+ contacto.Nombre_Comercial+'! ¿En qué puedo ayudarte?')}>{contacto.tipo >= 3 ? "Acerca de mí" : "Acerca de Nosotros"}</h2>
			<i className="fas fa-info-circle info-icon" onClick={() => setMostrar(!mostrar)}></i>
		  </div>
		  <div className="presentacion-container">
			<span className="presentacion" id="breve" dangerouslySetInnerHTML={{ __html: mostrarCompleto ? contacto.Presentacion : textoBreve }}></span>
			<p id="masp" className="mas toggle-link" onClick={() => setMostrarCompleto(!mostrarCompleto)} >
			  {mostrarCompleto ? "Leer menos" : "Leer más"}
			</p>
		  </div>
		</div>		
		
		
		{props.selectorCat}
		<div className="acerca"> 
		
			<h2 className="section-title" onClick={()=>setChat(!chat)}>Especialidades</h2>
			<div id="listaCat" className="columnas">
			  <ul id="listEsp" className="specialty-list">
				{listaEsp}
			  </ul>
			</div>			
	
 
			{contacto.editArea?props.editorweb1(contacto.editArea):''}
			<Button className="agendar" label="Cancelar"  onClick={() => dispatch({ type: types.contacto, payload: {...contacto, editArea:''} })} style={{margin:'5px', display:contacto.editArea?'block':'none', top:'-50px', left:'10.5em', width:'8em'}}/>
		</div> 
		<div className="acerca" style={{'display':contacto.socio?.Contacto_ID? 'block':'none'}}> 
			<h2>{'Especialistas de '+contacto.Nombre_Comercial}</h2>
		<PanelSocio contacto={contacto.socio} socio={contacto} fecha={fecha} categorias={contacto?.socio?.categorias} lateral={props.lateral} booking={contacto.booking} style={{boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 0px 0px', marginTop:'1rem'}}/>
		</div>
		{(contacto.tarifa!=='0'||contacto.vertarifa)?<div className="acerca" id="tarifa"> 
			<h2>Tarifa</h2>
			<div style={{clear:'both'}}>
			{contacto.tarifa!=='0'?'Costo de consulta: '+contacto.tarifa+' '+(monedas.find(item => item.value === contacto.moneda)?.label ||''):'Consulta gratuita'}
			</div>
		</div> :''}	
		
		<div className="acerca" id="horario"> 
			<h2>Horario de atención</h2>		
			<div className="horario-list">
				{horarioData.periodos.map((periodo, index) => (
					<Fragment key={index}>
						<span>{periodo.dia}</span>
						<span>{periodo.horario}</span>
					</Fragment>
				))}
			</div>
		</div>	

		<div className="acerca">
		  <h2 className="section-title">Ubicación y contacto</h2>
		  <div className="datosPagina" id="ubicacion">
			<div className="contact-info" style={{ display: props.preview === 'ver' ? 'block' : props.preview === 'no' ? 'none' : 'block' }}>
			  <div className="contact-item">
				<i className="fas fa-map-marker-alt" style={{ color: '#18b32b' }}></i>
				<span>Dirección</span> <br/>
				<p>{contacto.direccion}</p>
				<p>{"Distrito de " + contacto.Nombre_Distrito + ", " + contacto.Nombre_Provincia + " - " + contacto.Nombre_Departamento}</p>
			  </div>
			  {contacto.telefono ? (
				<div className="contact-item">
				  <i className="fas fa-phone-alt" id="telefono" style={{ color: '#3F51B5' }}></i>
				  <span>Teléfono fijo</span><br/>
				  <p>{contacto.telefono}</p>
				</div>
			  ) : null}
			  <div className="contact-item">
				<i className="fas fa-mobile-alt" id={contacto.telefono ? "" : "telefono"} style={{ color: '#3F51B5' }}></i>
				<span>Teléfono móvil</span><br/>
				<p>{contacto.celular}</p>
			  </div>
			  {contacto.email ? (
				<div className="contact-item" id="email">
				  <i className="far fa-envelope" style={{ color: '#3F51B5' }}></i>
				  <span>Correo electrónico</span><br/>
				  <div style={{display:'grid'}}><p style={{ overflow: 'auto' }}>{contacto.email}</p></div>
				</div>
			  ) : null}
			  {contacto.web ? (
				<div className="contact-item">
				  <i className="fas fa-globe" style={{ color: '#1877f2' }}></i>
				  <span>Página web</span>{linkSite}<br/>
				  <p style={{ overflow: 'auto' }}><a href={contacto.web} target="_blank" rel="noreferrer">{contacto.web}</a></p>
				</div>
			  ) : null}
			  <div className="contact-item social-links">
				<i className="fas fa-users" style={{ color: '#3F51B5', marginBottom: '1em' }}></i>
				<span>Redes Sociales</span><br/>
				<div style={{ display: 'flex', marginBottom: '1em' }}>{FB}{IG}{TT}{TW}{YT}{WA}</div>
			  </div>
			</div>
			{props.contactoWeb}
		  </div>
		  <div className="mapa">
			<iframe
			  title="mapa"
			  src={contacto.Gmaps}
			  width="100%"
			  height="625"
			  style={{ border: 0 }}
			  allowFullScreen=""
			  loading="lazy"
			></iframe>
		  </div>
		  {props.mapa}
		</div>
	</div>
</div>

{props.preview==='no'?
<div className="movil p-fluid reg-content-wrapper" style={{padding:'1em', background:'#fff'}}>
	<div className="form-container edit-box">
		<fieldset>			
		{props.editPerfil}
		</fieldset>
	</div>
</div>		
:''}

</Fragment>
);


}








export const PanelSocio = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {contacto, ruta, img, fecha} = store;
const { socio } = contacto;
const { tipo, Nombre_Cat_Solo = '', Nombre_Padre_solo = '', Nombre_Padre_Grupo = '', Nombre_Cat = '' } = socio || {};
var futuro=false;

useEffect(() => {
window.scrollTo({top: document.getElementById('Socio').offsetTop - 120, behavior:'smooth'});
}, [contacto.socio?.Contacto_ID]);


const Fecha = (e) => {
futuro = (new Date(e?.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
let hasta = 'HASTA '+ (new Date(e?.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
let fechaPanel = futuro?hasta:fecha;
return fechaPanel;
}



	
const agenda = <i className="pi pi-calendar icoBoton"  onClick={ e => dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, booking:'agenda'}} })} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto'}}></i>;
const booking = <Agenda contacto={contacto.socio} socio={contacto}/>
const className = classNames('p-grid', props.className);

const titulo = tipo >= 3 ? Nombre_Cat_Solo ? props.categorias > 1 ? `${Nombre_Padre_solo} ${Nombre_Cat.toLowerCase()}`
						 : Nombre_Cat_Solo : `${Nombre_Padre_solo} ${Nombre_Cat.toLowerCase()}` : `${Nombre_Padre_Grupo} ${Nombre_Cat.toLowerCase()}`;

/*const titulo = contacto.socio?.tipo >=3? contacto.socio.Nombre_Cat_Solo?props.categorias>1?contacto.socio.Nombre_Padre_solo+' '+ contacto.socio.Nombre_Cat.toLowerCase():contacto.socio.Nombre_Cat_Solo:contacto.socio.Nombre_Padre_solo+' '+ contacto.socio?.Nombre_Cat.toLowerCase():
				contacto.socio?.Nombre_Padre_Grupo+' '+ contacto.socio?.Nombre_Cat.toLowerCase();*/
//const pcol7 = classNames(props.lateral?'p-col-7':'p-col-9');
//const pcol2 = classNames(props.lateral?'p-col-2':'oculto');
return (
<div id='Socio' className={className} style={props.style}>
	<div className="p-col-3 col-foto">  
		<div className="alturaFoto">  
		<img className='fotoContacto panelM' alt="foto" src={contacto.socio?.Foto ? ruta + contacto.socio?.Foto : contacto.socio?.tipo === '3' ? ruta + img.fotoM : ruta + img.fotoF} style={{borderRadius:'0', background:contacto.socio?.color,padding:'0'}}/>   
		</div>
	</div>
	<div className='p-col-7 col-dato'> 
		<div className="p-grid box-dato">  
			<div className="p-col-12" style={{minHeight:window.innerWidth>'800'?'6em':'auto', fontSize:'14', padding:'10px 0 0 0', lineHeight:'1.5'}}>
				<h2>{contacto.socio?.Nombre_Comercial}</h2> 
				<Fragment>{titulo}<span className='mas'>{props.categorias>1?', '+(props.categorias - 1)+' especialidades más':''}</span></Fragment>
			</div>
			<div className="p-col-12" > </div> 
		</div>
	</div>
	<div className="p-col-2 col-lado" style={{textAlign:'center', padding:'0', font:'1em / 1em fallback-font, Arial, sans-serif'}}>  
		<p style={{cursor:'pointer'}}><i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'-2px 3px 0 0'}}></i><span style={{fontWeight:'bold', float:'left', color:'#03a9f4'}}>{/*<Link to = {"/feedback/"+contacto.socio?.userweb+'/'}>*/contacto.socio?.alcance} </span>{'('+contacto.socio?.votos+' opiniones)'}</p>	
		<p style={{textAlign:'left', marginBottom:'6em'}}><i className="pi pi-video"  style={{float:'left',color:'#03a9f4',margin:'-1px 4px 0 0'}}></i>Consulta on-line</p>
		<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
			<div style={{padding:'5px'}}>{agenda}{ Fecha(contacto.socio)}</div>
			<div style={{background:contacto.socio || futuro?.nodisponible?'#fffafa':'#f7fff2', padding:'5px',color:contacto.socio || futuro?.nodisponible?'red':'green'}}>{contacto.socio?.nodisponible || futuro?'Sin citas disponibles':'Citas disponibles'}</div>
		</div>		
	</div>	
	{props.booking||contacto.socio?.booking?booking:''}
	<div>
	<span className="presentacion" id="quilla1"></span>  
	</div>
</div>
);
}

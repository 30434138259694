import React, { useContext, useState, useEffect, Fragment } from 'react';
import {StoreContext} from './store/StoreProvider';
import {types}  from './store/StoreReducer';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { InfoData } from "./InfoData";
//import './layout/Panel.css';



export const Panel = (props) => {

const {store, dispatch} = useContext(StoreContext);
const {ruta, fecha, img, monedas} = store;
const [especialidad, setEspecialidad] = useState([]);
const [especialidad1, setEspecialidad1] = useState([]); 
const [categorias, setCategorias] = useState([]);  
const [preview, setPreview] = useState("");  
const fotoF = ruta+img.fotoF;
const fotoM = ruta+img.fotoM;
const logoC = ruta+img.logoC;
const logoB = ruta+img.logoB;
const logoT = ruta+img.logoT;
const logoQ = ruta+img.logoQ;
const fotoI = ruta+img.fotoI;
var futuro=false;
const imagen = [{img:fotoI},{img:logoC },{img:logoB},{img:fotoM},{img:fotoF},{img:logoT },{img:logoQ}];
//var parametros = props.parametros || '/'+contacto.Nombre_Departamento+'/Distrito de '+contacto.Nombre_Distrito+'/';
//parametros = parametros.split(' ').join('-');

useEffect(() => {

}, []);


const especialidades = (id,item) => { 
if(especialidad.length===0){
	Especialidades(id,item);}
	setPreview('ver');
 }
 
const listarEspecialidad = (especialidad,item) => { 
    let a = '';
    for (let indice in especialidad) {
	 a="<li>"+especialidad[indice].label+"</li>"+a;
    };
	a="<i class='pi pi-times cerrar movil'></i><ul style='list-style: inside;'>"+a+"</ul>";
	if(a!==''){
	document.getElementsByClassName('masCat')[item].innerHTML = a;
	document.getElementsByClassName('pi pi-times cerrar movil')[document.getElementsByClassName('pi pi-times cerrar movil').length-1].onclick = function(){document.getElementsByClassName('masCat')[item].style.display='none'}
	}
 }

 
 const Especialidades = async (Contacto_ID,item) => {
  let cadena = await InfoData( types.especialidad, {Contacto_ID:Contacto_ID});
	cadena = cadena.data;
  let cadena1 =cadena.pop(); 	  
  setEspecialidad1(cadena);
  cadena.shift(); 	
  setEspecialidad(cadena);
  setCategorias(cadena1);
  listarEspecialidad(cadena,item);
  } 
 
  
const Fecha = (e) => {
  futuro = (new Date(e.cerradohasta)).valueOf() > (new Date()).valueOf() ? true : false;
  let hasta = 'HASTA '+ (new Date(e.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
  let fechaPanel = futuro?hasta:fecha;
  return fechaPanel;
}
  
  
const webSite = (e) => {
	dispatch({ type: types.contacto, payload: {...props.contacto , load:'porListado'} });  
	dispatch({ type: types.especialidad, payload: especialidad1 }); 
	dispatch({ type: types.categorias, payload: categorias });
	window.scrollTo({top: 0, behavior:'smooth'});
	}

const tipo = (valor) => ({ 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 })[valor];
	
	
	//--------------------------------
    // Zona de renderización final

	const verif = <><i className="fa fa-certificate" style={{color: '#1d9bf0', margin:'0 8px', fontSize:'15px', verticalAlign:'middle'}}></i><i className="fa fa-check" style={{color: '#fff', fontSize:'7px', margin:'-19px',  verticalAlign:'middle'}}></i></>
    const agenda = props.router?<i className="pi pi-calendar icoBoton"  style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto' }}></i>:
    <Link to= {'/contacto/'+props.contacto.Nombre_Comercial?.split(' ').join('-') +'_C/'+ props.contacto.userweb}><i className="pi pi-calendar icoBoton" onClick={webSite} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto' }}></i></Link>;
    const link = props.router?<h1 className='h1Panel h1M' onClick={() => props.elegir(props.contacto)} style={{cursor:props.contacto.editar?'':'pointer'}}>{props.contacto.Nombre_Comercial}{props.contacto.verificado==='1'?verif:''}</h1>:
    <Link to= {'/contacto/'+props.contacto.Nombre_Comercial?.split(' ').join('-') +'/'+ props.contacto.userweb}><h1 className='h1Panel h1M' onClick={webSite}>{props.contacto.Nombre_Comercial}{props.contacto.verificado==='1'?verif:''}</h1></Link>
    const linkWeb = props.router?<span style={{'display':props.contacto.usoweb==='1' ? 'block':'none'}}><i className="fas fa-globe" style={{color: '#1877f2'/*,margin:'0 5px 0 21px'*/ }}></i>Contacto web</span>:
    <Link to= {"/"+props.contacto.userweb}><span onClick={webSite} style={{'display':props.contacto.usoweb==='1' ? 'block':'none'}}><i className="fas fa-globe" onClick={webSite} style={{color: '#1877f2'/*, margin:'0 5px 0 21px'*/ }}></i>Contacto web</span></Link>;
    const className = classNames('p-grid panelContacto', props.className);
    /*const pcol7 = classNames(props.lateral?'p-col-7 col-dato':'p-col-9 col-datolado');
    const pcol2 = classNames(props.lateral?'p-col-2 col-lado':'oculto');*/
    const titulo = 
    props.contacto.tipo >= 3 ? 
    props.contacto.Nombre_Cat_Solo ? 

    props.contacto.categorias > 1 ? 
    props.contacto.Nombre_Padre_solo + ' ' + props.contacto.Nombre_Cat?.toLowerCase() : 
    props.contacto.Nombre_Cat_Solo 
    : 
    props.contacto.Nombre_Padre_solo!==undefined?
    props.contacto.Nombre_Padre_solo + ' ' + props.contacto.Nombre_Cat?.toLowerCase():''    
    : 
    props.contacto.Nombre_Cat!==undefined? 
    props.contacto.Nombre_Padre_Grupo + ' ' + props.contacto.Nombre_Cat?.toLowerCase():'';
    
    const barraMenu = <Fragment>
              <span style={{width:'32%', whiteSpace:'nowrap', margin:'0 2% 0 0', 'display': props.contacto.telefono ? 'block':'none', float:'left'}}><i className="fas fa-phone-alt" style={{color:'#3F51B5'/*,marginRight:'5px'*/}}></i> {props.contacto.telefono} </span>
              <span style={{width:'32%', whiteSpace:'nowrap', 'display': props.contacto.celular? 'block':'none', float:'left'}}><i className="fas fa-mobile-alt" style={{color:'#1877f2'/*,marginRight:'3px',marginLeft:'20px'*/ }}></i> {props.contacto.celular} </span>
              <span style={{width:'32%', whiteSpace:'nowrap', margin:'0 0 0 2%' }}>{props.contacto.web?<i className="fas fa-globe" style={{color: '#1877f2'/*,margin:'0 3px 0 20px'*/ }}></i>:linkWeb} <a href={props.contacto.web} target="_blank" rel="noreferrer">{props.contacto.web?'Página web':''}</a></span>
                    </Fragment>;
	const conFoto = props.router ? true : (props.contacto.Foto !== '' || props.contacto.logo !== '');
    return (
    <Fragment>
		<div style={{position:'relative', width:'100%', textAlign:'end'}}>{props.editBoton}</div>
        <div className={className} style={{...props.style/*, margin:'0'*/}}>
		  <div className="movilMini" style={{textAlign:'center', width: '100vw', margin:'-0.2rem -0.4rem 0'}}>{link}</div> 
		{conFoto && 
         <div className="p-col-3 col-foto" style={{ background: '#f9f7f6'}}>  
            <div className="alturaFoto" onClick={() => props.elegir(props.contacto)}>  
            <img className={props.contacto.tipo >=3?'fotoContacto panelM':'logoContacto'} src={props.contacto.tipo ==='3' || props.contacto.tipo === '4'?props.contacto.Foto?ruta+props.contacto.Foto:imagen[props.contacto.tipo?props.contacto.tipo:'0'].img:props.contacto.logo?ruta+props.contacto.logo:imagen[props.contacto.tipo?tipo(props.contacto.tipo):'0'].img} style={{background:props.contacto.tipo ==='2'?props.contacto.color:'none',padding:props.contacto.tipo ==='2'?window.innerWidth/100+'px':'0'}} alt="Foto de perfil"/>   
            {props.fotoSetting}
            </div>
            {props.fotoCombo}
          </div>}
          <div className= {conFoto ? "p-col-10 col-dato" : "p-col-7 col-dato"}> 
            <div className="p-grid">
              <div className="p-col-12" style={{padding:'0 0 0 10px', fontSize:window.innerWidth>'800'?'inherit':'12px'}}><span className="movilGrande">{link}</span><Fragment><span style={{fontWeight:'bold', color:'#757575'}}>{titulo}</span><span className='mas' onClick={() => especialidades(props.contacto.Contacto_ID,props.contacto.item)}>{props.contacto.categorias>1?', '+(props.contacto.categorias - 1)+' especialidades más':''}<div id="masCtg" className="masCat" onMouseLeave={() => setPreview('')} style={{'display': preview ? 'block':'none',minHeight:props.contacto.categorias+'em'}}> <img  style={{width:'auto'}} className="logoContacto" alt="logo" src={ruta+"assets/images/pre-loader.gif"}/> </div></span></Fragment>
              </div>
              <div className="p-col-12 medioPanel" style={{marginBottom:conFoto?"":"20px"}}> <i className="fas fa-map-marker-alt" style={{color: '#18b32b', marginRight:'8px','display': props.contacto.direccion?'inline-block':'none'}} ></i>{props.contacto.direccion}{props.contacto.Nombre_Distrito?', ':''}{props.contacto.Nombre_Distrito}{props.contacto.Nombre_Provincia?" - ":''}{props.contacto.Nombre_Provincia}</div> 
              
            </div>
			<div className="p-col-12 movil500mas barraHeight">{barraMenu}</div> 
          </div>
          <div className="p-col-2 col-lado" style={{textAlign:'center', padding:'0', font:'1em / 1em fallback-font, Arial, sans-serif'}}>  
            {props.contacto.tipo >=3?props.contacto.nodisponible!=='0'?<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
              <div style={{padding:'5px'}}>{agenda}{ Fecha(props.contacto)}</div>
              <div style={{background:props.contacto.nodisponible>'1' || futuro?'#fffafa':'#f7fff2', padding:'5px',color:props.contacto.nodisponible>'1' || futuro?'red':'green'}}>{props.contacto.nodisponible>'1' || futuro?'Turnos agotados':'Citas disponibles'}</div>
            </div>:'':''}
            <p style={{cursor:'pointer',display:props.contacto.votos?'block':'none'}}><i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'-2px 3px 0 0'}}></i><span onClick={webSite} style={{fontWeight:'bold', float:'left', color:'#03a9f4'}}><Link to = {"/feedback/"+props.contacto.userweb+'/'}>{props.contacto.alcance}</Link> </span>{'('+props.contacto.votos+' opiniones)'}</p>		
            {props.contacto.videollamada?<p style={{textAlign:'left'}}><i className="pi pi-video"  style={{float:'left',color:'#03a9f4',margin:'-1px 4px 0 0'}}></i>Consulta on-line</p>:''}
            <p style={{display:props.contacto.vertarifa?'display':'none'}}>{props.contacto.tarifa!=='0'?'Tarifa de consulta '+monedas.find(item => item.value === props.contacto.moneda)?.simbolo +props.contacto.tarifa:'Consulta gratuita'}</p>
          </div>
          <div className="p-col-12 movil500menos" style={{padding:'.5rem 0 0 0', fontSize:'12px', textAlign:'center', display:'flex'}}>{barraMenu}</div>
        </div>
        {props.fotoOpcion}
		{props.editar?
		<div className="movil p-fluid reg-content-wrapper" /*style={{top:'3em'}}*/>
			<div className="form-container">
				<fieldset>			
				{props.editPanel}
				</fieldset>
			</div>
		</div>:''}
    </Fragment> 
      );


}
import axios from "axios";

// Configuración base
const host = "https://servicontacto.com/";
const api = host + "supercontacto/portal.php";
const apiLogin = host + "supercontacto/login.php";
const config = { headers: { "content-type": "multipart/form-data" } };
const configT = { headers: { "content-type": "application/json" } };   //  este encabezado es util para el env¡io de texto
const configl = { withCredentials: true, headers: { "content-type": "application/json" } }; // Enviar cookies de sesión

// Helper para construir FormData
const buildFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};

// Manejo centralizado de errores
const handleError = (error, context) => {
  console.error(`Error en ${context}:`, error.message);
  throw error; // Propaga el error para manejarlo en niveles superiores
};


// Funciones relacionadas con sesion en php
const serverLogin = async () => {
  try {
    const formData = buildFormData({ operacion: 0 });
    const response = await axios.post(api, formData, configl );
    return response.data;
  } catch (error) {
    handleError(error, "serverLogin");
  }
};



// Funciones relacionadas con ubicaciones
const serverPaises = async () => {
  try {
    const formData = buildFormData({ operacion: 2 });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverPaises");
  }
};

const serverCiudades = async (pais) => {
  try {
    const formData = buildFormData({ operacion: 1, pais });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverCiudades");
  }
};

const serverProvincias = async (dpto) => {
  try {
    const formData = buildFormData({ operacion: 1.1, departamento: dpto });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverProvincias");
  }
};

const serverDistritos = async (prov, dpto) => {
  try {
    const formData = buildFormData({ operacion: 1.2, provincia: prov, departamento: dpto });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverDistritos");
  }
};

// Funciones relacionadas con usuarios
const UsuarioData = async (usu_ID) => {
  try {
    let user = { Usu_ID: usu_ID, Nombres: "", ApellidoPaterno: "", ApellidoMaterno: "" };
    let resource = await serverUsuario(user, "", "");
    let perfil = resource;
    let ubicacion = {
      Departamento: perfil.Departamento,
      Departamento_ID: perfil.Departamento_ID,
      Distrito: perfil.Distrito,
      Distrito_ID: perfil.Distrito_ID,
      Pais: perfil.Pais,
      Pais_ID: perfil.Pais_ID,
      Provincia: perfil.Provincia,
      Provincia_ID: perfil.Provincia_ID,
      label: perfil.Usu_ID ? perfil.Distrito + ", " + perfil.Provincia + " - " + perfil.Departamento + " - " + perfil.Pais : "",
      value: perfil.Distrito,
    };
    return { perfil, ubicacion };
  } catch (error) {
    handleError(error, "UsuarioData");
  }
};

const serverUsuario = async (user, caso, pwd) => {
  try {
    const formData = buildFormData({
      operacion: 9.1,
      Usu_ID: user.Usu_ID,
      tipo: caso,
      nombre: user.Nombres?.trim(),
      apellidoP: user.ApellidoPaterno?.trim(),
      apellidoM: user.ApellidoMaterno?.trim(),
      Email: user.Email?.trim() || "",
      Celular: user.Celular || "",
      usuario: user.Usuario?.trim() || "",
      password: pwd,
      distrito: user.Distrito_ID || "",
      genero: user.Genero || "",
      nombreDB: user.Nombres + " " + user.ApellidoPaterno + " " + user.ApellidoMaterno,
    });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverUsuario");
  }
};

// Funciones relacionadas con contactos
const serverContactosUser = async (Usu_ID) => {
  try {
    const formData = buildFormData({ operacion: 5.1, Usu_ID });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverContactosUser");
  }
};

const serverCtoData = async (userweb) => {
  try {
    const formData = buildFormData({ operacion: 5, userweb });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverCtoData");
  }
};

// Función principal: InfoData
export const InfoData = async (type, payload) => {
  try {
    switch (type) {
      case "session":	
		return await serverLogin();
	
      case "auth - login":
        const user = await UsuarioData(payload);
        return {
          user,
          paises: [{ value: user.perfil.Pais_ID, label: user.perfil.Pais }],
          ciudades: [{ value: user.perfil.Departamento_ID, label: user.perfil.Departamento }],
          provincias: [{ value: user.perfil.Provincia_ID, label: user.perfil.Provincia }],
          distritos: [{ value: user.perfil.Distrito_ID, label: user.perfil.Distrito }],
        };

      case "user":
//	  console.log(payload);
        return await serverUsuario(payload.user, payload.caso, payload.pwd);

      case "registrar":
        const formDataRegistrar = buildFormData({
          operacion: 9,
          nombre: payload.nombres.trim(),
          apellidoP: payload.apellidoP.trim(),
          apellidoM: payload.apellidoM.trim(),
          Email: payload.email.trim(),
          Celular: payload.celular,
          usuario: payload.usuario.trim(),
          password: payload.password,
          distrito: payload.distrito,
          departamento: payload.departamento || "0",
          genero: payload.genero || "0",
          nombreDB: payload.nombres + " " + payload.apellidoP + " " + payload.apellidoM,
        });
        return await axios.post(api, formDataRegistrar, config);
		
      case "chat":
	/*	const formDataMensaje = {
			operacion: 17,
			mensaje: payload.mensaje
		};
		return await axios.post(api, JSON.stringify(formDataMensaje), configT);*/
		
		const formDataMensaje = buildFormData({ operacion: 17, mensaje: payload.mensaje, Contacto_ID:payload.Contacto_ID });
        return await axios.post(api, formDataMensaje, configT);

      case "persona":
        const formDataPersona = buildFormData({ operacion: 5.8, nombre: payload.nombre, Usu_ID: payload.Usu_ID });
        return await axios.post(api, formDataPersona, config);

      case "paises":
        return { paises: await serverPaises() };

      case "ciudades":
        return { ciudades: await serverCiudades(payload) };

      case "provincias":
        return { provincias: await serverProvincias(payload) };

      case "distritos":
        return { distritos: await serverDistritos(payload.prov, payload.dpto) };

      case "ubicacion":
        const formDataUbicacion = buildFormData({ operacion: 8.2, nombre: payload.nombre, distrito: payload.distrito_ID });
        return await axios.post(api, formDataUbicacion, config);

      case "categoria":
        const formDataCategoria = buildFormData({ operacion: 10.2, nombre: payload.nombre, catID: payload.catID });
        return await axios.post(api, formDataCategoria, config);

      case "categorias":
        const formDataCategorias = buildFormData({ operacion: 3, accion: payload.nombre });
        return await axios.post(api, formDataCategorias, config);

      case "categoriaCto":
        const formDataCategoriaCto = buildFormData({ operacion: 10.1, nombre: payload.nombre, catID: payload.catID });
        return await axios.post(api, formDataCategoriaCto, config);

      case "categoriaSelect":
        const formDataCategoriaSelect = buildFormData({ operacion: 10, padre: payload });
        return await axios.post(api, formDataCategoriaSelect, config);

      case "socio":
        const formDataSocio = buildFormData({ operacion: 10.3, nombre: payload });
        return await axios.post(api, formDataSocio, config);

      case "insertaSocio":
        const formDataInsertaSocio = buildFormData({
          operacion: 10.4,
          Contacto_ID: payload.Contacto_ID,
          catID: payload.catID,
          id: payload.socio,
          accion: payload.modo,
        });
        return await axios.post(api, formDataInsertaSocio, config);

      case "alertas":
        const alertas = await obtenerEventos(payload);
        return { alertas };

      case "eventos":
        const formDataEventos = buildFormData({ operacion: 15.2, Contacto_ID: payload });
        return await axios.post(api, formDataEventos, config);

      case "editor":
        const formDataEditor = buildFormData({
          operacion: 5.7,
          Contacto_ID: payload.Contacto_ID,
          Usu_ID: payload.Usu_ID,
          accion: payload.accion,
        });
        return await axios.post(api, formDataEditor, config);

      case "contactosUser":
        return await serverContactosUser(payload);

      case "contactos":
        const formDataContactos = buildFormData({
          operacion: 4,
          skip: payload.skip,
          limit: payload.limit,
          catID: payload.Cat_ID,
          padre: payload.padre,
          abuelo: payload.abuelo,
          distrito: payload.distrito,
          provincia: payload.provincia,
          departamento: payload.departamento,
          pais: payload.pais,
        });
        return await axios.post(api, formDataContactos, config);

      case "contacto":
        return await serverCtoData(payload);

      case "contactoReg":
        const formDataContactoReg = buildFormData({
          operacion: 12,
          nombre: payload.contacto.Nombre_Comercial.trim(),
          distrito: payload.contacto.distrito,
          direccion: payload.contacto.direccion.trim(),
          Celular: payload.contacto.celular.trim(),
          telefono: payload.contacto.telefono.trim(),
          tipo: payload.contacto.tipo,
          Usu_ID: payload.usuario,
          color: payload.contacto.color,
          catID: payload.catID,
        });
        return await axios.post(api, formDataContactoReg, config);

      case "contactoSave":
        const formDataContactoSave = buildFormData({
          operacion: 14,
          Contacto_ID: payload.contacto.Contacto_ID,
          nombre: payload.contacto.Nombre_Comercial.trim(),
          distrito: payload.contacto.distrito,
          direccion: payload.contacto.direccion.trim(),
          Celular: payload.contacto.celular.trim(),
          telefono: payload.contacto.telefono.trim(),
          tipo: payload.contacto.tipo,
          Usu_ID: payload.usuario.Usu_ID,
          color: payload.contacto.color,
          web: payload.contacto.web.trim(),
          userweb: payload.contacto.userweb?.trim() || "",
          usoweb: payload.contacto.usoweb,
          Email: payload.contacto.email.trim(),
          gmaps: payload.contacto.Gmaps.trim(),
          presentacion: payload.textoWeb ? payload.textoWeb : payload.contacto.Presentacion,
          fb: payload.contacto.Facebook.trim(),
          Instagram: payload.contacto.Instagram,
          Youtube: payload.contacto.Youtube,
          Twitter: payload.contacto.Twitter,
          Tiktok: payload.contacto.Tiktok,
        });
        return await axios.post(api, formDataContactoSave, config);

      case "contactoDel":
        const formDataContactoDel = buildFormData({ operacion: 12.2, Contacto_ID: payload });
        return await axios.post(api, formDataContactoDel, config);

      case "especialidad":
        const formDataEspecialidad = buildFormData({
          operacion: 6,
          Contacto_ID: payload.Contacto_ID,
          presentacion: payload.texto || "",
          catID: payload.catID || "",
          accion: payload.borrar || "",
        });
        return await axios.post(api, formDataEspecialidad, config);

      case "especialista":
        const formDataEspecialista = buildFormData({ operacion: 6.1, Contacto_ID: payload.contacto, catID: payload.catID });
        return await axios.post(api, formDataEspecialista, config);

      case "disponibilidad":
        const formDataDisponibilidad = buildFormData({
          operacion: 7.1,
          usuario: payload.usuario,
          Celular: payload.celular,
          Email: payload.email,
        });
        return await axios.post(api, formDataDisponibilidad, config);

      case "disponibilidadUsr":
        const formDataDisponibilidadUsr = buildFormData({
          operacion: 7,
          password: payload.pwd || "",
          usuario: payload.usuario,
          Celular: payload.celular,
          Email: payload.email,
          Usu_ID: payload.Usu_ID || "",
          accion: payload.accion || "",
          tipo: payload.codigo || "",
        });
        return await axios.post(api, formDataDisponibilidadUsr, config);

      case "foto":
        const formDataFoto = buildFormData({
          operacion: 11,
          doc: payload.data,
          Usu_ID: payload.usu_ID || "",
          Contacto_ID: payload.contacto || "",
          tipo: payload.tipo,
        });
        return await axios.post(api, formDataFoto, config);

      case "leeComentarios":
        const formDataComentarios = buildFormData({ operacion: 16.1, Contacto_ID: payload });
        return await axios.post(api, formDataComentarios, config);

      case "zona":
        return { zona: payload };

      case "login":
        const formDataLogin = buildFormData({
          operacion: 1,
          inT1: payload.tipo.Usuario.trim(),
          inT1a: payload.tipo.Email.trim(),
          inT1b: payload.tipo.Celular.trim(),
          inT2: payload.password,
          equipo: payload.checked ? 1 : 0,
        });
        return await axios.post(apiLogin, formDataLogin, configl);
		
      case "loginGoogle":
        const formDataLoginG = buildFormData({
          operacion: 3,
          token: payload.token,
        });
        return await axios.post(apiLogin, formDataLoginG, configl);		

      case "auth - logout":
        const salidaLogout = await salir();
        return { salida: salidaLogout };

      case "visita":
        const formDataVisita = buildFormData({ operacion: 5.5, tipo: payload });
        return await axios.post(api, formDataVisita, config);

      case "visitaInfo":
        const formDataVisitaInfo = buildFormData({ operacion: 5.3, Contacto_ID: payload });
        return await axios.post(api, formDataVisitaInfo, config);

      case "visitaCto":
        const formDataVisitaCto = buildFormData({ operacion: 5.4, tipo: payload.tipo, Contacto_ID: payload.contacto });
        return await axios.post(api, formDataVisitaCto, config);

      case "visitaWeb":
        const formDataVisitaWeb = buildFormData({ operacion: 5.2, tipo: payload.tipo, Contacto_ID: payload.contacto });
        return await axios.post(api, formDataVisitaWeb, config);
		
	  case "gps":
        const formDataGPS = buildFormData({ operacion: 5.9, lat: payload.lat, lon: payload.lon });
        return await axios.post(api, formDataGPS, config);

      default:
        return "";
    }
  } catch (error) {
    handleError(error, "InfoData");
  }
};

// Función para cerrar sesión
const salir = async () => {
    try {
        const response = await salirServer();
        return response; // Devuelve { message: "Sesión cerrada correctamente" } o { error: ... }
    } catch (error) {
        handleError(error, "salir");
    }
};

// Función para cerrar sesión en el servidor
const salirServer = async () => {
  try {
    const formData = buildFormData({ operacion: 2});
    const response = await axios.post(apiLogin, formData, configl);
    return response.data;
  } catch (error) {
    handleError(error, "salirServer");
  }
};

// Función para obtener eventos de usuario
const obtenerEventos = async (Usu_ID) => {
  try {
    let resource = await serverEventosUsr(Usu_ID);
    let salida = resource.data.length === 0 ? "" : resource.data;
    return salida;
  } catch (error) {
    handleError(error, "obtenerEventos");
  }
};

// Función para obtener eventos de usuario desde el servidor
const serverEventosUsr = async (Usu_ID) => {
  try {
    const formData = buildFormData({ operacion: 15.1, Usu_ID });
    const response = await axios.post(api, formData, config);
    return response.data;
  } catch (error) {
    handleError(error, "serverEventosUsr");
  }
};
//import {TabView,TabPanel} from 'primereact/tabview';
import React, { useContext, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { StoreContext } from './store/StoreProvider';
import { Rating } from 'primereact/rating';
import { InfoData } from "./InfoData";

export const Feedback = (props) => {

const {store } = useContext(StoreContext);
const {contacto, ruta, img, score} = store;
const val = {v1:Math.round(contacto.alcance), v2:Math.round(contacto.comm), v3:Math.round(contacto.tiempo), v4:Math.round(contacto.costo)};
const [coments, setComents] = useState([]);
const fotoF = ruta+ img.fotoF;
const fotoM = ruta+ img.fotoM;
const logo = ruta+ img.logo;
const icoposi =  <i className='fa fa-plus-circle' style={{color:'#54a754'}}/>;
const iconeut =  <i className='fa fa-stop-circle' style={{color:'#333333'}}/>;
const iconega =  <i className='fa fa-minus-circle' style={{color:'#c0503a'}}/>;
//const comentario = [{valor:'1', comentario:'no issues, buy with confidence, good seller. however in general ebay customer service sucks', comprador:'', fecha:1648080000 }];
 
//console.log(contacto);


  useEffect(() => {
comentarios(contacto.Contacto_ID);
  }, []);   //eslint-disable-line react-hooks/exhaustive-deps

  
const comentarios = async (Contacto_ID) => {
    let resource = await InfoData('leeComentarios', Contacto_ID);		
	setComents(resource.data);
	console.log(resource.data);
   }

    
const panel = (dato) =>{
console.log(dato);
}

const icono = (rowData, column) =>{
let salida=rowData.score===3?icoposi:rowData.score===2?iconeut:rowData.score===1?iconega:''
return salida;
}

const fecha = (rowData, column) =>{
let cuando = (rowData.estampa*1000)>((new Date()).setMonth((new Date()).getMonth()-1))?'1 Mes':'1 año'
return cuando;
}

let headerGroup = <ColumnGroup>
  <Row>
    <Column header="COMENTARIOS" colSpan={3} />
    <Column header="DE"   />	
    <Column header="CUÁNDO"   />	
  </Row>
</ColumnGroup>;

return (
<div className="marco" style={{padding: '1em 0'}}>
	<div className="contactoN">
		<div className='panelContacto'>
			<h2>Perfil de comentarios</h2>						
			<div className='p-grid' style={{padding:'10px', /*height:'10em',*/ margin:'1em 0', border:'1px solid #c8c8c8', borderRadius:'0.5em', width:'100%'}}>
				<div style={{float:'left', width:contacto.tipo >= '3'?'15%':'25%', /*height:'9em',*/ }}>
				<img src={ contacto.tipo >= '3'?contacto.Foto?ruta + contacto.Foto:(contacto.tipo === '3' ? ruta + fotoM : ruta + fotoF):contacto.logo?ruta + contacto.logo:ruta + logo} alt="Foto de perfil" style={{width: '100%', height: 'auto', marginBottom:'1em', background:contacto.tipo === '2'?contacto.color:'none', padding:contacto.tipo === '2'?'10px':'0'}}/>
				</div>
				<div style={{float:'right', width:contacto.tipo >= '3'?'85%':'75%', padding: '0 0.7em'}}><h2 style={{lineHeight:'1.2'}}>{contacto.Nombre_Comercial}<span style={{marginLeft:'5px', fontSize:'15px', color:'#006aa7' }}>(<i className='fa fa-star' style={{color:'#fecc00', fontSize:'12px', position:'relative', top:'-2px'}}/> {contacto.alcance +' - '+contacto.votos+' opiniones' })</span></h2>
				<>{(Math.round(100*contacto.positivo/(contacto.positivo+contacto.negativo+contacto.neutral)*10)/10 || 0)+' % comentarios positivos'}</>
				<p style={{float:'right', width:'64%', marginTop: '3em'}}>{'Según nuestra base de datos, '+contacto.Nombre_Comercial+' es parte de Servicontacto desde '+new Date(contacto.fecha*1000).toLocaleDateString("es-PE", {month: "long", day: "numeric", year:'numeric'})}</p>
				</div>
			</div>

			<div style={{width:'100%', display:'flex', flexWrap:'wrap',position:'relative', textAlign:'center', marginBottom:'7em'}}>				
			

				<div className='ancho' style={{clear:'both', width:'60%', textAlign:'left', padding:'0.6em 0'}}><h2>Valoraciones detalladas sobre el vendedor</h2>
				<div  style={{clear:'both', padding:'10px' , height:window.innerWidth>'800'?'195px':'14.5em', border:'1px solid #c8c8c8', borderRadius:'0.5em' }}>	
					<div style={{borderBottom:'1px solid #c8c8c8', margin:'-0.3em -0.8em 1em', padding:'0 0 0.3em 2em', textAlign:'left'}}>Promedio de los últimos 12 meses</div>	
					<ul className="columnas1 oculto">
					<li className='lst'><Rating style={{float:'left', width:'5em'}} value={val.v1} cancel={false} /*onChange={(e) => setVal({...val , v1:e.value})}*/ /><span className='rank'>{contacto.alcance}</span>{score.alcance}</li>
					<li className='lst'><Rating style={{float:'left'}} value={val.v2} cancel={false} /*onChange={(e) => setVal({...val , v2:e.value})}*/ /><span className='rank'>{contacto.comm}</span><span>{score.comunica}</span></li>
					<li className='lst'><Rating style={{float:'left'}} value={val.v3} cancel={false} /*onChange={(e) => setVal({...val , v3:e.value})}*/ /><span className='rank'>{contacto.tiempo}</span><span>{score.tiempo}</span></li>
					<li className='lst'><Rating style={{float:'left'}} value={val.v4} cancel={false} /*onChange={(e) => setVal({...val , v4:e.value})}*/ /><span className='rank'>{contacto.costo}</span><span>{score.costo}</span></li>		
					</ul>
					<ul className="columnas1" style={{clear:'both', padding:'10px', display:'contents'}}>
					<li className='lst'><Rating style={{float:'left', minWidth:'120px'}} value={val.v1} cancel={false}/><span className='rank'>{contacto.alcance}</span><div style={{position:'relative', top:'9px', width:'calc(100% - 100px)', lineHeight:'1.1'}}>{score.alcance}</div></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'120px'}} value={val.v2} cancel={false}/><span className='rank'>{contacto.comm}</span><div style={{position:'relative', top:'9px', width:'calc(100% - 100px)', lineHeight:'1.1'}}>{score.comunica}</div></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'120px'}} value={val.v3} cancel={false}/><span className='rank'>{contacto.tiempo}</span><span>{score.tiempo}</span></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'120px'}} value={val.v4} cancel={false}/><span className='rank'>{contacto.costo}</span><span>{score.costo}</span></li>		
					</ul>					
				</div>	
				</div>	
					
				<div className='ancho margen' style={{clear:'both', width:'calc(40% - 1em)', textAlign:'left', marginLeft:'1em', padding:'0.6em 0'}}><h2 style={{marginLeft:'0.8em'}}>Valoraciones de comentarios</h2>			
				<div style={{ flexWrap:'wrap',position:'relative', height:'195px', textAlign:'center', border:'1px solid #c8c8c8', borderRadius:'0.5em'}}>
					<div style={{borderBottom:'1px solid #c8c8c8', padding:'0.12em', textAlign:'left'}}>
					<table style={{width:'100%'}}>
					 <tr ><td style={{width:'58%'}}> </td><td style={{visibility:'hidden'}}>1 mes</td><td>12 meses</td></tr> 
					</table>					
					     </div>	
					
					<table style={{width:'100%', height:'85%', padding:'1.5em' }}>
					 <tr><td>{icoposi} </td><td style={{textAlign:'left'}}>Positivas</td><td style={{visibility:'hidden'}}>124</td><td>{contacto.positivo}</td></tr> 
					 <tr><td>{iconeut} </td><td style={{textAlign:'left'}}>Neutrales</td><td style={{visibility:'hidden'}}>4</td><td>{contacto.neutral}</td></tr>
					 <tr><td>{iconega} </td><td style={{textAlign:'left'}}>Negativas</td><td style={{visibility:'hidden'}}>24</td><td>{contacto.negativo}</td></tr>
					</table>
				</div>		
				</div>
			</div>						
	

			<div className="barra" style={{borderBottom:'1px solid #e0e0e0', width:'100%', height:'1.8em'}}>
			<span style={{fontWeight:'bold', fontSize:'1.2em'}}>Todos los comentarios</span>
			   <ul className="menu" style={{margin:'0',top:'-0.7em', position:'relative', display:'none'}}>
					<li onClick={()=>panel(1)} style={{padding:'0.5em!important'}}>Todos los comentarios</li>
					<li onClick={()=>panel(2)} style={{padding:'0.5em!important'}}>Recibidos como comprador</li>
					<li onClick={()=>panel(3)} style={{padding:'0.5em!important'}}>Recibidos como vendedor</li>
				</ul>
			</div>

			<div  style={{minHeight:'15em',width:'100%', position:'relative'}}>
				<div  style={{position:'relative', float:'left', fontSize:'12px', top:'0.3' }}>
				{contacto.votos+' comentarios recibidos'}(1-25)
				</div>

				<DataTable id="cmt1" emptyMessage='' headerColumnGroup={headerGroup}>
					<Column/><Column/><Column/><Column/><Column/>	
				</DataTable> 
				
				<DataTable id="cmt2" value={coments} paginator={false} emptyMessage='No hay comentarios para mostrar' rows={10} rowsPerPageOptions={[5,10,20,50]}>
					<Column body={icono}  style={{width:'5%', textAlign:'center', fontSize:'1.2em'}} />
					<Column field="comentario"  style={{width:'55%'}} />
					<Column field="comprador"   style={{textAlign:'center'}} />
					<Column body={fecha}  style={{textAlign:'center'}}    />
				</DataTable> 
			</div>
		</div>
	</div>    
</div>    
);









}


 

/*

<Column body={this.nombreTemplate} header="Nombre completo" style={{width:'30%'}} />


<TabView renderActiveOnly={false}  style={{marginTop:'6em'}}>
  <TabPanel header="Ficha de Información" leftIcon="fas fa-user-check">   
	<div  style={{padding:'10px'}}>		
	<h2>Todos los comentarios</h2>
	</div>	
   </TabPanel>
  
   <TabPanel header="Ficha de Negocios" leftIcon="fas fa-business-time">   
	<div  style={{padding:'10px'}}>		
	<h2>Recibidos como comprador</h2>
	</div> 
   </TabPanel>
  
   <TabPanel header="Personal" leftIcon="fas fa-user-md">   
	<div  style={{padding:'10px'}}>		
	<h2>Recibidos como vendedor</h2>
	</div>
   </TabPanel>
  
</TabView>  

*/


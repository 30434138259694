import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './layout/subirFile.css';

export const SubirFile = (props) => {
    const fileUploadRef = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    // Función para formatear el tamaño manualmente
    const formatSize = (size) => {
        if (size === 0) return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const onTemplateSelect = (e) => {
        console.log("Archivos seleccionados:", e.files); // Depuración

        // Convertir FileList a un array
        const filesArray = Array.from(e.files);

        if (filesArray.length > 0) {
            let _totalSize = 0; // Reiniciamos el tamaño total
            filesArray.forEach(file => {
                _totalSize += file.size;
            });

            console.log("Tamaño total calculado:", _totalSize); // Depuración
            setTotalSize(_totalSize); // Actualizamos el estado con el nuevo tamaño
            setUploadedFiles(filesArray);

            // No llamamos a props.onUpload aquí
        } else {
            console.error("No se seleccionaron archivos válidos.");
        }
    };

    const onTemplateUpload = () => {
        console.log("Subiendo archivos manualmente"); // Depuración
        if (props.onUpload && uploadedFiles.length > 0) {
            props.onUpload(uploadedFiles); // Llamamos a props.onUpload con los archivos seleccionados
        }
    };

    const onTemplateClear = () => {
        console.log("Limpieza de archivos"); // Depuración
        setTotalSize(0);
        setUploadedFiles([]);
    };

    const onTemplateRemove = (file, callback) => {
        console.log("Eliminando archivo:", file.name); // Depuración
        setTotalSize(totalSize - file.size);
        callback(); // Llama a la función de PrimeReact para eliminar el archivo
        setUploadedFiles(uploadedFiles.filter(f => f.name !== file.name));
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, /*uploadButton,*/ cancelButton } = options;
        const maxFileSize = 1000000; // Tamaño máximo en bytes (1 MB)
        const value = (totalSize / maxFileSize) * 100; // Calcula el porcentaje de progreso
        const formatedValue = formatSize(totalSize); // Formatea el tamaño manualmente

        console.log("Valor de la barra de progreso:", value); // Depuración
        console.log("Tamaño formateado:", formatedValue); // Depuración

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                <Button
                    icon="pi pi-cloud-upload"
                    className="p-button-success p-button-rounded p-button-outlined"
                    onClick={onTemplateUpload}  // Manejar la subida manualmente
					style={{ backgroundColor: value>0?'#fecc00':'transparent'}}
                />
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{ width: '300px', height: '24px', marginLeft: 'auto', borderRadius: '7px', fontSize: '11px' }}></ProgressBar>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        console.log("Mostrando vista previa del archivo:", file.name); // Depuración
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        console.log("Mostrando plantilla vacía"); // Depuración
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Arrastra y suelta la imagen aquí
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <FileUpload
                ref={fileUploadRef}
                name="demo[]"
                auto={false}  // Desactiva la subida automática
                multiple
                accept="image/*"
                maxFileSize={1000000}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                cancelOptions={cancelOptions}
            />
        </div>
    );
};
import { createContext, useReducer, useContext, ReactNode } from 'react';
import storeReducer, { initialStore } from './StoreReducer';

// Crear el contexto
const StoreContext = createContext();

// Hook personalizado para acceder al contexto
export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore debe ser usado dentro de un StoreProvider');
  }
  return context;
};

// Proveedor del contexto
const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [store, dispatch] = useReducer(storeReducer, initialStore);

  return (
    <StoreContext.Provider value={{ store, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
export { StoreContext };